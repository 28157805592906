/*

SPC-Menubar 2023
16-dec-2024: Some changes for easyer themecolors per toplevel menuitem

FIXME: merge improvements from minvws/juistezorg (webdesigns/shared/components/spc-menubar)

*/
/** @short The active menu selection will look like a tab from which the dropdown extends.
 *  @long Use this mixin on the .spc-menubar container
 *  !! use this AFTER the @include menu-panel-pulldown.
 */
/** @short Dropdown appears slightly below the menubar item with an arrow pointing to the menu item it belongs to
 * (FIXME: test)
*/
/** @short Dropdown appears slightly below the menubar item with the top of the dropdown having kind of a half arrow pointing to the menu item the dropdown belongs to
 * (FIXME: test)
*/
ul.spc-menubar-branch--horizontal {
  display: flex;
}

.spc-menubar ul {
  -webkit-user-select: none;
  user-select: none;
  list-style: none;
  padding: 0;
  margin: 0;
}

.spc-menubar__item {
  display: flex;
  align-items: baseline;
}

/* either:
   - a menu item with link: a seperate button.spc-menubar__item__toggle.spc-menubar__item__togglebutton
   - a menu item without link: a button which includes both the title and symbol (button.spc-menubar__item__toggle.spc-menubar__item__title)
*/
.spc-menubar__item__toggle {
  /* reset <button> styling */
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  cursor: pointer;
  color: inherit;
}

.spc-menubar__item__togglebutton {
  width: var(--menubar-bar-toggler-width);
  padding: var(--menubar-bar-toggler-extrahitarea);
  margin: calc(-1 * var(--menubar-bar-toggler-extrahitarea));
}

ul.spc-menubar--toplevel > li > .spc-menubar__item {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: var(--menubar-bar-item-color);
  font: var(--menubar-level1-font);
  padding-left: var(--menubar-level1-padding-left);
  padding-right: var(--menubar-level1-padding-right);
}

ul.spc-menubar--toplevel > li > .spc-menubar__item > .spc-menubar__item__title {
  text-decoration: var(--menubar-bar-item-decoration);
  color: inherit;
  font: inherit;
  outline-offset: 4px;
  display: flex;
  align-items: center;
}

.spc-menubar__item__title .icon {
  margin-right: var(--menubar-bar-item-icon-margin, 10px);
}

.spc-menubar__item__title svg.icon {
  height: var(--menubar-bar-item-icon-size);
}

button.spc-menubar__item__title {
  text-align: inherit;
}

ul.spc-menubar--toplevel.spc-menubar--toplevel > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__toggle {
  margin-left: calc(-1 * var(--menubar-bar-toggler-extrahitarea) + var(--menubar-bar-toggler-margin));
}

ul.spc-menubar--toplevel.spc-menubar--toplevel > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__togglebutton::after {
  /* any type defining a block formatting context allows us
     to disable or define our own underline
    (as long as our parent doesn't use flex or grid)
  */
  display: inline-block;
  text-decoration: none;
  font: var(--menubar-bar-toggler-font);
  font-size: var(--menubar-bar-toggler-size);
  content: var(--menubar-bar-toggler-content);
  margin-top: var(--menubar-bar-toggler-shifty);
}

ul.spc-menubar--toplevel.spc-menubar--toplevel > li > a:focus {
  position: relative;
  z-index: 1; /* ensure the focus outline isn't overlapped by the next item */
}

html.site--geldlessen .spc-menubar-branch--horizontal.spc-menubar-branch--horizontal > ul > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__togglebutton,
html.site--wvhg .spc-menubar-branch--horizontal.spc-menubar-branch--horizontal > ul > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__togglebutton {
  margin-left: calc(var(--menubar-bar-toggler-margin) - var(--menubar-bar-toggler-extrahitarea));
}
html.site--geldlessen .spc-menubar-branch--horizontal.spc-menubar-branch--horizontal > ul > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__title.spc-menubar__item__toggle::after,
html.site--wvhg .spc-menubar-branch--horizontal.spc-menubar-branch--horizontal > ul > .spc-menubar__item--hassubitems > .spc-menubar__item > .spc-menubar__item__title.spc-menubar__item__toggle::after {
  margin-left: var(--menubar-bar-toggler-margin);
}
html.site--geldlessen ul.spc-menubar--toplevel > li:first-child,
html.site--wvhg ul.spc-menubar--toplevel > li:first-child {
  margin-left: calc(-1 * var(--menubar-level1-padding-left));
}
html.site--geldlessen .spc-menubar-branch--horizontal > li:last-child > .spc-menubar__item,
html.site--wvhg .spc-menubar-branch--horizontal > li:last-child > .spc-menubar__item {
  margin-right: calc(-1 * var(--menubar-level1-padding-right));
}
html.site--geldlessen .spc-menubar__spacer,
html.site--wvhg .spc-menubar__spacer {
  width: var(--menubar-level1-spacing);
  min-width: var(--menubar-level1-spacing-min);
}

ul.spc-menubar--toplevel > li.spc-menubar__currentpagebranch > .spc-menubar__item {
  background: var(--menubar-bar-item-selected-background);
}

ul.spc-menubar--toplevel > li.spc-menubar__currentpagebranch > .spc-menubar__item a {
  color: var(--menubar-bar-item-selected-color);
}

ul.spc-menubar--toplevel > li > .spc-menubar__item:hover {
  background: var(--menubar-bar-item-hover-background);
  color: var(--menubar-bar-item-hover-color);
}

ul.spc-menubar-branch--horizontal .spc-menubar__item--expand > .spc-menubar__item a,
ul.spc-menubar-branch--horizontal > li:not(.spc-menubar__item--hassubitems) > .spc-menubar__item a:hover,
.spc-menubar:not([role=menubar]) ul.spc-menubar-branch--horizontal > li.spc-menubar__item--hassubitems:hover > .spc-menubar__item a,
ul.spc-menubar--toplevel > li > .spc-menubar__item:hover > a {
  color: var(--menubar-bar-item-hover-color);
  text-decoration: var(--menubar-bar-item-hover-decoration);
}

ul.spc-menubar-branch--horizontal .spc-menubar__currentpagebranch.spc-menubar__item--expand > .spc-menubar__item a {
  color: var(--menubar-bar-item-selected-hover-color);
}

ul.spc-menubar-branch--horizontal .spc-menubar__item--expand > .spc-menubar__pulldown,
.spc-menubar:not([role=menubar]) ul.spc-menubar-branch--horizontal > li.spc-menubar__item--hassubitems:hover .spc-menubar__pulldown {
  display: block;
  animation: var(--menubar-pulldown-anim-open);
  animation-fill-mode: forwards;
}

html.site--geldlessen .spc-menubar-branch--horizontal,
html.site--wvhg .spc-menubar-branch--horizontal {
  height: 100%;
}
html.site--geldlessen .spc-menubar-branch--horizontal > li > .spc-menubar__item,
html.site--wvhg .spc-menubar-branch--horizontal > li > .spc-menubar__item {
  position: relative;
  margin-top: 10px;
  height: calc(100% - 20px);
}
html.site--geldlessen .spc-menubar-branch--horizontal > li > .spc-menubar__item:has(> .spc-menubar-custompaneltrigger),
html.site--wvhg .spc-menubar-branch--horizontal > li > .spc-menubar__item:has(> .spc-menubar-custompaneltrigger) {
  margin-top: 10px;
  padding-bottom: 10px;
  height: calc(100% - 10px);
  --menubar-pulldown-box-shadow: none;
}
html.site--geldlessen .spc-menubar-branch--horizontal > li > .spc-menubar__item:hover:has(> .spc-menubar-custompaneltrigger)::before,
html.site--geldlessen .spc-menubar-branch--horizontal > li.spc-menubar__currentpagebranch > .spc-menubar__item:has(> .spc-menubar-custompaneltrigger)::before,
html.site--wvhg .spc-menubar-branch--horizontal > li > .spc-menubar__item:hover:has(> .spc-menubar-custompaneltrigger)::before,
html.site--wvhg .spc-menubar-branch--horizontal > li.spc-menubar__currentpagebranch > .spc-menubar__item:has(> .spc-menubar-custompaneltrigger)::before {
  content: "";
  display: block;
  position: absolute;
  left: -5px;
  bottom: -5px;
  width: 5px;
  height: 5px;
  border-radius: 0 0 9px 0;
  border-right: 5px solid #FFFFFF;
  border-bottom: 5px solid #FFFFFF;
}
html.site--geldlessen .spc-menubar-branch--horizontal > li > .spc-menubar__item:hover:has(> .spc-menubar-custompaneltrigger)::after,
html.site--geldlessen .spc-menubar-branch--horizontal > li.spc-menubar__currentpagebranch > .spc-menubar__item:has(> .spc-menubar-custompaneltrigger)::after,
html.site--wvhg .spc-menubar-branch--horizontal > li > .spc-menubar__item:hover:has(> .spc-menubar-custompaneltrigger)::after,
html.site--wvhg .spc-menubar-branch--horizontal > li.spc-menubar__currentpagebranch > .spc-menubar__item:has(> .spc-menubar-custompaneltrigger)::after {
  content: "";
  display: block;
  position: absolute;
  right: -5px;
  bottom: -5px;
  width: 5px;
  height: 5px;
  border-radius: 0 0 0 9px;
  border-left: 5px solid #FFFFFF;
  border-bottom: 5px solid #FFFFFF;
}
html.site--geldlessen .spc-menubar__spacer,
html.site--wvhg .spc-menubar__spacer {
  flex: 1 1 65px;
}
html.site--geldlessen .spc-menubar-branch--horizontal::after,
html.site--wvhg .spc-menubar-branch--horizontal::after {
  content: "";
  flex: 1 1 65px;
}
@media (max-width: 767px) {
  html.site--geldlessen .spc-menubar > ul > li,
  html.site--wvhg .spc-menubar > ul > li {
    display: none;
  }
  html.site--geldlessen .spc-menubar > ul > li.siteheader__wigmenu__togglethemesbar,
  html.site--wvhg .spc-menubar > ul > li.siteheader__wigmenu__togglethemesbar {
    display: block;
  }
}

html.site--geldlessen .spc-menubar__levelpanel[data-menulevel="2"],
html.site--wvhg .spc-menubar__levelpanel[data-menulevel="2"],
html.site--ffw .spc-menubar__levelpanel[data-menulevel="2"] {
  position: absolute;
  animation: var(--menubar-pulldown-anim-close);
  animation-fill-mode: forwards;
  /*
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.15s;
  */
}
html.site--geldlessen .spc-menubar__levelpanel[data-menulevel="2"] > ul,
html.site--wvhg .spc-menubar__levelpanel[data-menulevel="2"] > ul,
html.site--ffw .spc-menubar__levelpanel[data-menulevel="2"] > ul {
  position: relative;
  width: max-content;
  min-width: var(--menubar-pulldown-minwidth);
  max-width: var(--menubar-pulldown-maxwidth);
  padding: var(--menubar-pulldown-padding);
  background-color: var(--menubar-pulldown-bgcolor);
  border: var(--menubar-pulldown-border);
  border-radius: var(--menubar-pulldown-border-radius);
  box-shadow: var(--menubar-pulldown-box-shadow);
}
html.site--geldlessen .spc-menubar__levelpanel[data-menulevel="2"] > ul::before,
html.site--wvhg .spc-menubar__levelpanel[data-menulevel="2"] > ul::before,
html.site--ffw .spc-menubar__levelpanel[data-menulevel="2"] > ul::before {
  border-left-color: var(--menubar-pulldown-bgcolor);
}
html.site--geldlessen .spc-menubar .spc-menubar__pulldown > ul,
html.site--wvhg .spc-menubar .spc-menubar__pulldown > ul,
html.site--ffw .spc-menubar .spc-menubar__pulldown > ul {
  border-radius: 0 var(--menubar-pulldown-border-radius) var(--menubar-pulldown-border-radius) var(--menubar-pulldown-border-radius);
}
html.site--geldlessen .spc-menubar > ul > li > .spc-menubar__item,
html.site--wvhg .spc-menubar > ul > li > .spc-menubar__item,
html.site--ffw .spc-menubar > ul > li > .spc-menubar__item {
  border-radius: var(--menubar-bar-item-border-radius);
}
html.site--geldlessen .spc-menubar > ul > li.spc-menubar__item--hassubitems > .spc-menubar__item,
html.site--wvhg .spc-menubar > ul > li.spc-menubar__item--hassubitems > .spc-menubar__item,
html.site--ffw .spc-menubar > ul > li.spc-menubar__item--hassubitems > .spc-menubar__item {
  border-radius: 5px;
}
html.site--geldlessen .spc-menubar > ul > li > .spc-menubar__item:focus-within, html.site--geldlessen .spc-menubar > ul > li > .spc-menubar__item:hover,
html.site--wvhg .spc-menubar > ul > li > .spc-menubar__item:focus-within,
html.site--wvhg .spc-menubar > ul > li > .spc-menubar__item:hover,
html.site--ffw .spc-menubar > ul > li > .spc-menubar__item:focus-within,
html.site--ffw .spc-menubar > ul > li > .spc-menubar__item:hover {
  background: var(--menubar-bar-item-hover-background);
}
html.site--geldlessen .spc-menubar > ul > li.spc-menubar__item--expand > .spc-menubar__item,
html.site--wvhg .spc-menubar > ul > li.spc-menubar__item--expand > .spc-menubar__item,
html.site--ffw .spc-menubar > ul > li.spc-menubar__item--expand > .spc-menubar__item {
  color: var(--menubar-level2-color);
  background: var(--menubar-pulldown-bgcolor);
  border-radius: 5px 5px 0 0;
  --menubar-bar-item-hover-color: var(--menubar-level2-color);
}
html.site--geldlessen .spc-menubar > ul > li.spc-menubar__item--hassubitems > .spc-menubar__item:hover,
html.site--wvhg .spc-menubar > ul > li.spc-menubar__item--hassubitems > .spc-menubar__item:hover,
html.site--ffw .spc-menubar > ul > li.spc-menubar__item--hassubitems > .spc-menubar__item:hover {
  box-shadow: var(--menubar-pulldown-box-shadow);
}