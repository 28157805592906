.wh-requireconsent {
  position: relative;
  z-index: 0;
}

.wh-requireconsent__overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  transition: opacity 300ms;
}

.wh-requireconsent__overlay[hidden] {
  visibility: hidden;
}

.wh-requireconsent__overlay:hover {
  opacity: 1;
}

.wh-requireconsent__overlay:not([hidden]),
.widget--slideshare .consentblocker,
.widget--image .consentblocker,
.widget--video .consentblocker,
.widget--video-inline__tv__screen .consentblocker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.8);
  color: #FFFFFF;
  padding: 15px;
  display: flex;
  align-items: center;
  text-align: center;
  transition: opacity var(--button-transition-duration);
}

.widget--image .consentblocker,
.widget--video .consentblocker,
.widget--video-inline__tv__screen .consentblocker {
  opacity: 0;
}

.consentblocker {
  width: 100%;
  text-align: center;
}

.widget--image .consentblocker:hover,
.widget--slideshare .consentblocker:hover,
.widget--video .consentblocker:hover,
.widget--video-inline__tv__screen .consentblocker:hover {
  opacity: 1;
}

.widget--image__image {
  z-index: 0;
}