html
{
  /*--formcontrols-focus-boxshadow:       0 0 0 3px #540A5130;*/
  --formcontrols-focus-boxshadow:       0 0 0 6px rgb(40 52 119 / 15%);
  --formcontrols-focus-outline:         2px solid var(--color-green-bright);
  --formcontrols-focus-outline-offset:  0px;
  --formcontrols-border-color-focus:    var(--color-green-bright);
}

body
{
  /************************************************************
   *
   *  Form layout
   */

  --formlayout-label-width: 200px;
  /*--formlayout-label-font:  17px var(--rtd-text-fontfamily); */ /* 21px for 4TU */
  --formlayout-label-spacing: 25px;

  --formlayout-fieldgroup-bgcolor-error: #FDE6E8;

  --formlayout-fieldgroup-padding: 11px 16px;
  --formlayout-fieldgroup-spacing: 0px;

  --formlayout-fieldgroup-error-textcolor: #D40819;

  --formlayout-label-font:            var(--rtd-text-font);
  --formlayout-fieldgroup-error-font: 15px/22px var(--rtd-text-fontfamily);
  --formlayout-fieldgroup-info-font:  15px/22px var(--rtd-text-fontfamily);

  --formlayout-buttongroup-margin:    20px 0 0 0;



  /************************************************************
   *
   *  Form controls
   */


  /* Normal height for single-line controls */
  --formcontrols-height:             44px;
  --formcontrols-radius:              3px;


  --formcontrols-border-width:          1px;
  --formcontrols-border-width-focus:    1px;

  --formcontrols-border-color:          #333333;
  --formcontrols-border-color-disabled: #BFBFBF;
  --formcontrols-border-color-error:    var(--color-salmon-formcontrols-error-border);

  --formcontrols-textcolor-error:       #121212; /* we make the area around it red and keep the text black */

  --formcontrols-backgroundcolor-error: #FFFFFF;



  --formcontrol-checkradio-label-color: var(--rtd-text-color);


  /* NOTE: if the font-size is below 16px iOS will zoom into the control when it recieves focus */
  --textcontrols-font:                 16px var(--rtd-text-fontfamily);
  --textcontrols_placeholdercolor:     var(--color-grey-text-small);
  --textcontrols-bgcolor:              #FFFFFF;
  --textcontrols_textcolor:            #121212;

  --textcontrols_disabled_bgcolor:     #F5F5F5;
 /*--textcontrols_disabled_bordercolor: #989898;*/
  --textcontrols_disabled_placeholder: #767676;
  --textcontrols_disabled_textcolor:   #767676;

  --textcontrols_padleft:         15px;
  --textcontrols_padright:        15px;
  --textcontrols_padleft-mobile:  10px;
  --textcontrols_padright-mobile: 10px;



/* FIXME:  these are still partly in sass */;
  --controls_textcolor_error:        #EE0000; /* lightest red which passes accessibility tests (4.53:1 contrast on white) */


  --controls-icon-color: var(--color-theme);

}

@media (max-width: 480px)
{
  --textcontrols_padleft:  10px;
  --textcontrols_padright: 10px;

  /* NOTE: if the font-size is below 16px iOS will zoom into the control when it recieves focus */
  --textcontrols_font: 16px var(--rtd-heading-fontfamily);
}
