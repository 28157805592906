.consentdialog {
  border-radius: 10px;
  border: 0;
  padding: 0;
  width: 100%;
  max-width: min(825px, 100% - 30px);
  max-height: calc(100dvh - 40px);
}
@media print {
  .consentdialog {
    display: none;
  }
}

.consentdialog ul.unordered,
.consentdialog ol.ordered {
  padding-left: 20px;
}

.consentdialog__content {
  display: flex;
  flex-direction: column;
}

.consentdialog__tabstrip {
  flex: none;
}

.consentbar__content_tabpanels {
  display: grid;
  /* How this works:
     - Each row (and therefore panel) use minimum of 0px - so it won't use implicit min-content
     - Each row has a maximum of 1fr allowing the row to gow
     - The default (implicit) setting is stretch - so each tab will at least use the full row-height
     - A max-height is set on the grid
       - because the minmax(0, 1fr) there is no minimum height
         for grid items - so our items (tab panels) cannot grow beyond this.
        - for browsers which support the "dvh" unit we use this.
          This means that the dialog will dynamically change it's size based on whether
          iOS has it's URL bar active or not. (the normal "dh" unit uses the full height regardless of part of the page being obscured/unused)
        - for browsers without "dvh"-unit support we use the "vh" unit (first - so a browsers without dvh will ignore the rule it doesn't understand and fall back to the line setting "vh")
     - Because of the .consentdialog__tab being a flex+column we can distrubute the
       available space between:
       - consentdialog__tab__button - The exact size it needs (flex: none)
       - consentdialog__tab__body - Any space left over (and as long as the __body + __button doesn't exceed
         the max height of .consentdialog__tab (which it gets from our grid row) it can stretch
         to fit all of it's content.
  */
  grid-template-rows: minmax(0, 1fr);
  max-height: calc(100vh - var(--component-tabstrip-height) - 40px);
  max-height: calc(100dvh - var(--component-tabstrip-height) - 40px);
  align-items: stretch; /* actually the default in browsers */
}

.consentdialog__tab {
  grid-column: 1;
  grid-row: 1;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.consentbar__content_tabpanels > *[hidden] {
  display: block !important;
  visibility: hidden; /* ensure screenreader and tabnavigation don't pick up content */
}

.consentdialog__tab__body {
  flex: 1 1 auto;
  overflow: auto;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) calc(100% - 18px), rgba(0, 0, 0, 0.14) 100%);
}

.consentdialog__tab__body.rtdcontent {
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: var(--dialog-pad-h);
  padding-right: var(--dialog-pad-h);
}

.consentdialog__buttonbar {
  flex: none;
}

.consentdialog {
  --checkbox-switch-track-width: 41px; /* 51px */
  --checkbox-switch-track-height: 22px; /* 30px */
  --widget-accordion-icon-color: #333333;
  --widget-accordion-items-title-color: #333333;
}
.consentdialog input.spc-toggle + label::after {
  display: none !important;
}
.consentdialog details.widget-accordion__item { /* Firefox */
  /* Chrome */
}
.consentdialog details.widget-accordion__item summary {
  list-style-type: none;
}
.consentdialog details.widget-accordion__item summary::-webkit-details-marker {
  display: none;
}
.consentdialog details.widget-accordion__item summary::marker {
  display: none;
}
.consentdialog .widget-accordion__item summary:focus {
  background-color: #F5F5F5;
}
.consentdialog .widget-accordion__item__header {
  padding-left: var(--dialog-pad-h);
  padding-right: var(--dialog-pad-h);
  justify-content: start;
}
.consentdialog .widget-accordion__item__header::before {
  color: var(--widget-accordion-icon-color);
  font-size: 16px;
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  margin-right: 25px;
  transition: transform 0.1s;
}
.consentdialog .widget-accordion__item[open] > summary .widget-accordion__item__header::before {
  transform: rotateZ(90deg);
}
.consentdialog .widget-accordion__item__header::after {
  display: none;
}
.consentdialog .widget-accordion__item__header-title {
  flex: 1 0 0;
}
.consentdialog .widget-accordion__item__body {
  padding-left: var(--dialog-pad-h);
  padding-right: var(--dialog-pad-h);
}

/*
Button styling overrides
*/
.consentdialogbutton {
  outline-offset: 4px;
}

.consentdialog__acceptselectionbutton {
  outline-offset: 4px;
}

.consentdialog__denyconsentbutton,
.consentdialog__specifyconsentbutton {
  color: #000000;
}

.consentdialog :first-child + .tabstrip__tab {
  padding-left: var(--dialog-pad-h);
}

@media (max-width: 767px) {
  .consentdialog {
    --dialog-pad-h: 15px;
    --component-tabstrip-height: 44px;
    --widget-accordion-items-title-font: var(--rtd-h4-font);
    --rtd-text-font: 13px/20px var(--rtd-text-fontfamily);
    --component-tabstrip-font: bold 16px var(--rtd-heading-fontfamily);
    --component-tabstrip-item-selected-font: bold 16px var(--rtd-heading-fontfamily);
  }
  .consentdialogbutton {
    font: bold 16px "TypoPRO Dosis Buttons", "Dosis", Arial;
  }
  .consentdialog__tab__body.rtdcontent {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .consentdialog__buttonbar {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .consentdialog__buttonbar {
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: var(--dialog-pad-h);
    padding-right: var(--dialog-pad-h);
  }
  .consentdialog__buttonbar button {
    justify-content: center;
  }
  .consentdialog__denyconsentbutton {
    order: 3;
  }
  .consentdialog__specifyconsentbutton {
    order: 2;
  }
  .consentdialog__consenttoallbutton {
    order: 1;
  }
}
@media (min-width: 768px) {
  .consentdialog {
    --dialog-pad-h: 15px;
    --component-tabstrip-height: 62px;
  }
  .spc-tabstrip--tabs label {
    flex: 1 0 0;
    text-align: center;
  }
  .consentdialog__tabs .tabstrip__tab {
    justify-content: center;
  }
  .consentdialog__buttonbar {
    display: flex;
    column-gap: 15px;
  }
  .consentdialog__buttonbar {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: var(--dialog-pad-h);
    padding-right: var(--dialog-pad-h);
  }
  .consentdialog__buttonbar > * {
    flex: 1 0 auto;
    justify-content: center;
  }
}
#consentbar::backdrop {
  animation: hidepagecontent 1s;
  animation-direction: reverse;
}

#consentbar[open]::backdrop {
  animation: hidepagecontent 1s;
  animation-fill-mode: forwards;
}

@keyframes hidepagecontent {
  0% {
    background-color: rgba(0, 0, 0, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(2px) contrast(50%);
    -webkit-backdrop-filter: blur(2px) contrast(50%);
  }
}