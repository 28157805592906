/* Based on ACOI
   (but ACOI is only single-column)
*/
/* Copied from ACOI */
.wh-form__error {
  --rtd-text-font: 15px/22px var(--rtd-text-fontfamily);
  display: none;
  flex: none;
  width: 100%; /* force error text below item */
  color: var(--formlayout-fieldgroup-error-textcolor);
  font: var(--formlayout-fieldgroup-error-font);
  padding: 8px var(--textcontrols_padleft) 0px var(--textcontrols_padleft);
}

.wh-form__error > * {
  grid-column: 2;
}

.wh-form__error::before {
  grid-column: 1;
  grid-row: 1;
  font: var(--fontawesome-regular);
  content: var(--fa-exclamation-triangle);
  font-size: 17px;
  margin-right: 15px;
}

.wh-form__fieldgroup--error .wh-form__error {
  display: grid;
  grid-template-columns: min-content 1fr;
  align-items: baseline;
}

.form__uploadfieldselect {
  margin-left: 16px;
}

.wh-form__dateinputgroup input,
.wh-form__timeinputgroup input {
  text-transform: uppercase;
}

.wh-form__buttongroup {
  margin-top: 30px;
  border-top: 1px solid #C5C5C5;
  padding-top: 25px;
}

.wh-form__arrayinput {
  width: 0;
}

.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.wh-form__richtext .centercontent {
  padding-right: 0;
  padding-left: 0;
}

.wh-form {
  /* FIXME: this is under the assumption that the first fieldgroup is visible. */
  /*
  2 column layout
  */
  /*
  Single column (stacked) layout
  */
  /*
  // Mark: fix om te voorkomen dat lange select options de layout oprekken
  select:not(.wh-rtd__toolbarstyle)
  {
    flex: 1 1 auto;
    width: 0;
  }
  */
  /* date/time */
  /*
  FILL WHOLE AREA

    .wh-form__buttongroup.wh-form__navbuttons > *
    {
      justify-content: center;
      flex: 1 0 auto;
    }
  */
}
.wh-form .wh-form__label {
  font: var(--formlayout-label-font);
}
.wh-form .wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  margin-left: -17px;
  margin-right: -17px;
  padding: var(--formlayout-fieldgroup-padding);
  margin-bottom: var(--formlayout-fieldgroup-spacing);
}
.wh-form .wh-form__fieldgroup.wh-form__fieldgroup:first-child {
  margin-top: 0;
}
.wh-form .wh-form__fieldgroup--richtext.wh-form__fieldgroup--richtext {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
.wh-form .wh-form__fieldgroup.wh-form__fieldgroup--nomargin:not(.wh-form__fieldgroup--hidden) {
  margin-bottom: 0;
}
.wh-form .wh-form__fieldgroup--required > .wh-form__label::after,
.wh-form .wh-form__fieldgroup--required .wh-form__label:empty + .wh-form__fields .wh-form__optionlabel::after {
  display: inline-block;
  content: "*";
}
.wh-form .wh-form__prefix {
  padding-right: 10px;
  align-self: center;
}
.wh-form .wh-form__suffix {
  padding-left: 10px;
  align-self: center;
}
.wh-form .wh-form__optionlabel, .wh-form .wh-form__subfield > label {
  padding-right: 15px;
}
.wh-form .wh-form__label:empty::after {
  display: none;
}
@media (min-width: 800px) {
  .wh-form .wh-form__label {
    padding-right: var(--formlayout-label-spacing);
  }
  .wh-form .wh-form__fieldgroup > .wh-form__label {
    flex: 0 0 var(--formlayout-label-width);
    min-width: var(--formlayout-label-width);
    max-width: var(--formlayout-label-width);
    word-break: break-word; /* if there's no other option allow breaking inside a word */
  }
  .wh-form .wh-form__fieldgroup > .wh-form__fields {
    max-width: calc(100% - var(--formlayout-label-width));
  }
  .wh-form .wh-form__fieldgroup--richtext > .wh-form__fields {
    max-width: 100%;
  }
}
@media (max-width: 800px) {
  .wh-form .wh-form__fieldgroup {
    -webkit-flex-direction: column;
    flex-direction: column;
    align-items: flex-start;
    /*
          & > .wh-form__label
        , & > .wh-form__fields
        {
          flex: none;
          max-width: calc(100vw - 40px);
          min-width: 0;
        }
    */
  }
  .wh-form .wh-form__fieldgroup > .wh-form__label.wh-form__label {
    flex: 0 0 auto;
    padding-bottom: 3px;
  }
  .wh-form .wh-form__fieldgroup .wh-form__subfield {
    flex-wrap: wrap;
  }
  .wh-form .wh-form__fields {
    width: 100%;
  }
  .wh-form .wh-form__label {
    font-weight: bold;
    margin-bottom: 8px;
  }
}
.wh-form select + .wh-form__subfield > label,
.wh-form .wh-form__pulldown-styled + .wh-form__subfield > label {
  padding-left: 10px;
}
.wh-form input, .wh-form select:not(.wh-rtd__toolbarstyle),
.wh-form .wh-form__dateinputgroup,
.wh-form .wh-form__timeinputgroup {
  flex: 1;
}
.wh-form .wh-form__imgedit {
  max-width: 100%;
}
.wh-form textarea {
  padding-top: 11px;
  padding-bottom: 11px;
}
.wh-form textarea {
  min-height: 140px;
}
.wh-form .wh-form__fieldgroup--error input, .wh-form .wh-form__fieldgroup--error select,
.wh-form .wh-form__fieldgroup--error textarea,
.wh-form .wh-form__fieldgroup--error .wh-form__imgedit,
.wh-form .wh-form__fieldgroup--error .wh-form__dateinputgroup,
.wh-form .wh-form__fieldgroup--error .wh-form__timeinputgroup {
  background-color: var(--formcontrols-backgroundcolor-error);
  border-color: var(--formcontrols-border-color-error);
  color: var(--formcontrols-textcolor-error);
}
.wh-form .wh-form__fieldgroup--error {
  background-color: var(--formlayout-fieldgroup-bgcolor-error);
  border-top: 3px solid #FFFFFF;
}
.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline {
  align-items: baseline;
}
.wh-form .wh-form__dateinputgroup, .wh-form .wh-form__timeinputgroup {
  flex: none;
  padding: 0;
}
.wh-form .wh-form__dateinputgroup input, .wh-form .wh-form__timeinputgroup input {
  box-shadow: none;
}
.wh-form .wh-form__dateinputgroup__line::after, .wh-form .wh-form__timeinputgroup__line::after {
  top: 12px;
}
.wh-form .wh-form__fieldgroup--radiogroup .wh-form__fieldline + .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--checkboxgroup .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 10px;
}
.wh-form .wh-form__fieldgroup--radiogroup.wh-form__fieldgroup--horizontal .wh-form__fieldline + .wh-form__fieldline,
.wh-form .wh-form__fieldgroup--checkboxgroup.wh-form__fieldgroup--horizontal .wh-form__fieldline + .wh-form__fieldline {
  margin-top: 0px;
}
.wh-form .wh-form__fieldgroup--horizontal .wh-form__fields {
  row-gap: 10px;
}
.wh-form .wh-form__optiondata > * {
  padding-left: 15px;
}
.wh-form .wh-form__optiondata--horizontal {
  margin-right: 30px;
  width: 100%;
}
.wh-form .wh-form__optiondata--horizontal:last-child {
  margin-right: 0;
}
.wh-form .wh-form__buttongroup {
  display: flex;
  margin: var(--formlayout-buttongroup-margin);
}
.wh-form .wh-form__buttongroup .wh-form__button--next, .wh-form .wh-form__buttongroup .wh-form__button--submit {
  margin-left: auto;
}

.wh-form__navbuttons {
  align-items: center;
}

.wh-rtd__editor {
  width: 100%;
  min-height: 200px;
}
.wh-rtd__editor .wh-rtd__html {
  margin: 0;
  padding: 5px;
}
.wh-rtd__editor .wh-rtd__body {
  padding: 5px 8px;
}
.wh-rtd__editor .wh-rtd-toolbar select {
  height: 23px;
  border: 1px solid #AAAAAA;
  border-radius: 1px;
  font-size: 15px;
  padding: 0 10px;
  min-height: 22px;
}