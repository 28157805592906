@import "@mod-minfin_wig/webdesigns/shared/web/assets/fontawesome5/mixins.scss";


html
{
  --widget-accordion-spacing: 15px;
}

/*
NOTES:

IE and Edge-legacy require a polyfill for summary+details to work
(https://caniuse.com/details)

The <summary> element cannot be display: flex; in Safari.
Also see:
- https://bugs.webkit.org/show_bug.cgi?id=190065
- https://github.com/philipwalton/flexbugs#flexbug-9

About accordion's and useability:
https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html

*/

.widget-accordion__description
{
  margin-bottom: var(--widget-accordion-spacing);
}

.widget-accordion__item:last-child
{
  border-bottom: 1px solid var(--widget-accordion-line-color);
}

/* Hide default arrow browsers show on <summary> */
.widget-accordion details summary { list-style-type: none; } /* Firefox */
.widget-accordion details summary::-webkit-details-marker { display: none; } /* Chrome */
.widget-accordion details summary::marker { display: none; } /* FF68+ (jul 2019), Chrome 86 (oct 2020), SF11.1 (mar 2018), iOS 11.3 (mar 2018) */

.widget-accordion__item__header
{
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-top: 1px solid var(--widget-accordion-line-color);

  color: #000000;
  color: var(--widget-accordion-items-title-color, #000000);

  font: var(--widget-accordion-items-title-font);

  min-height: 49px;
  /* padding: #{6px + $font-heading-offset} 0 #{6px - $font-heading-offset} 0; */
  padding: 6px 0 6px 0;

  -moz-user-select: none; // FF<69 (before 3 sep 2019)
  -webkit-user-select: none; // Chrome <54, all SF & iOS
  user-select: none;

  cursor: pointer;
}

/* All minfin sites (WIG,WVHG,GL,FFW) have a green/purple scheme.
   The sites have slight variations on the colors.
*/
.widget-accordion--green
{
  --widget-accordion-items-title-color: var(--color-green);
  --widget-accordion-icon-color:        var(--color-green);
}

.widget-accordion--purple
{
  --widget-accordion-items-title-color: var(--color-purple);
  --widget-accordion-icon-color:        var(--color-purple);
}


.widget-accordion__item__header::after
{
  color: var(--widget-accordion-icon-color);
  font-size: 16px;

  font: var(--fontawesome-light);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  content: var(--fa-plus);

  margin-left: 20px;
  margin-right: 20px;
}

.widget-accordion__item__header:hover
{
  background-color: #FCFCFC;
}

.widget-accordion__item__header:hover::after
{
  font-weight: bold;
  transform: translate(1px, -1px);
}

.widget-accordion__item[open] .widget-accordion__item__header::after
{
  content: var(--fa-minus);
}

.widget-accordion__item__body__content
{
  padding-top: 5px;
  padding-bottom: calc(var(--widget-accordion-spacing) * 1.5);
}
