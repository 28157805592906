.widget-downloadsandlinks
{
  background: #FFFFFF;
  border-radius: var(--widget-border-radius);
}

.widget-downloadsandlinks .minfinListItems
{
  container-name: minfinLargeListing minfinLargeListingLine;
  container-type: inline-size;
}



/********************************************************************

Widget used in sidebar version

********************************************************************/
/*
.widget.widget-downloadsandlinks
{
  padding-bottom: calc(var(--widget-padding-v) - var(--widget-sidebar-download-item-padding-v));
}

.widget.widget-downloadsandlinks .widgetdl__item
{
  padding: var(--widget-sidebar-download-item-padding-v) var(--widget-padding-h);
}

.widget.widget-downloadsandlinks .widgetdl__item__icon
{
  flex: none;
  font-size: 20px;

  width: var(--widget-sidebar-icon-space, 20px);
}

.widget.widget-downloadsandlinks .widgetdl__item__icon.fa-chevron-right
{
  font-size: 18px;
  font-weight: 600;
}

.widget.widget-downloadsandlinks .widgetdl__item__icon.fa-external-link-alt
{
  font-size: 15px;
  font-weight: 600;
}

.widget.widget-downloadsandlinks .widgetdl__item__title
{
  font: var(--widget-sidebar-download-title-font);
}

.widget.widget-downloadsandlinks .widgetdl__item__filesize
{
  display: none;
}
*/
