html {
  --pulldown-padright: 44px;
  --pulldown-symbol-from-edge: 17px;
}

.wh-styledinput select,
.style-native-select select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: var(--pulldown-padright);
  /* The SVG image needs preserveAspectRatio="xMaxYMid" to get positioned at the right in IE10/11 (and might also need the viewBox) */
  /*
  Up & Down arrow
  background: #FFFFFF url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHByZXNlcnZlQXNwZWN0UmF0aW89InhNYXhZTWlkIiB2aWV3Qm94PSIwIDAgNC45NSAxMCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fS5jbHMtMntmaWxsOiM0NDQ7fTwvc3R5bGU+PC9kZWZzPjxyZWN0IGNsYXNzPSJjbHMtMSIgd2lkdGg9IjQuOTUiIGhlaWdodD0iMTAiLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMS40MSA0LjY3IDIuNDggMy4xOCAzLjU0IDQuNjcgMS40MSA0LjY3Ii8+PHBvbHlnb24gY2xhc3M9ImNscy0yIiBwb2ludHM9IjMuNTQgNS4zMyAyLjQ4IDYuODIgMS40MSA1LjMzIDMuNTQgNS4zMyIvPjwvc3ZnPg==) no-repeat calc(100% - 10px) 50%;
  */
  /*
  FontAwesome angle down (SOLID?):
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z'/%3E%3C/svg%3E");
  background-size: 23px 28px;
  */
  /*
    background: #FFFFFF url("data:image/svg+xml,%3Csvg height='192' viewBox='0 0 322 192' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2301689b' d='m152.5 187.8-148-146.8c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0l119.7 118.5 119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'/%3E%3C/svg%3E") no-repeat calc(100% - var(--pulldown-symbol-from-edge)) calc(50% + 2px);

    background: linear-gradient(to right, #FFFFFF 0%, #FFFFFF calc(100% - 39px), #F1F9F0 calc(100% - 39px), #F1F9F0 100%)
              , transparent url("data:image/svg+xml,%3Csvg height='192' viewBox='0 0 322 192' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2301689b' d='m152.5 187.8-148-146.8c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0l119.7 118.5 119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'/%3E%3C/svg%3E") no-repeat calc(100% - var(--pulldown-symbol-from-edge)) calc(50% + 2px)
  */
  --arrow: url("data:image/svg+xml,%3Csvg height='192' viewBox='0 0 322 192' width='18' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%2301689b' d='m152.5 187.8-148-146.8c-4.7-4.7-4.7-12.3 0-17l19.8-19.8c4.7-4.7 12.3-4.7 17 0l119.7 118.5 119.7-118.5c4.7-4.7 12.3-4.7 17 0l19.8 19.8c4.7 4.7 4.7 12.3 0 17l-148 146.8c-4.7 4.7-12.3 4.7-17 0z'/%3E%3C/svg%3E");
  --square: linear-gradient(to right, #FFFFFF 0%, #FFFFFF calc(100% - 39px), #F1F9F0 calc(100% - 39px), #F1F9F0 100%);
  background: #FFFFFF var(--arrow) calc(100% - var(--pulldown-symbol-from-edge)) calc(50% + 2px) no-repeat;
  /*

    background: transparent
          var(--arrow)
          calc(100% - var(--pulldown-symbol-from-edge)) calc(50% + 2px) no-repeat
          , var(--square);
  */
}

@-moz-document url-prefix() {
  select, select:-moz-focusring, select::-moz-focus-inner {
    color: transparent !important;
    text-shadow: 0 0 0 #000 !important;
    /*border:0;*/
  }
}