/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
.searchpage__feedback {
  font-size: 21px;
  /*
  display: block;
  text-decoration: underline 1px;
  text-underline-offset: 10px;
  text-decoration-color: #DCDCDC;
  */
}

.page--search .searchpage__searchcontrol {
  display: none;
}
.page--search .searchpage__searchcontrol .searchcontrol {
  display: flex;
}
.page--search .searchpage__searchcontrol .searchcontrol__input {
  flex: 1 0 0px;
}
.page--search .searchpage__searchcontrol .searchcontrol__input {
  font-size: 16px; /* just a little bigger, so iOS won't zoom in to the input */
}
@media (max-width: 999px) {
  .page--search .searchpage__searchcontrol {
    display: block;
  }
}
.page--search .searchresults {
  margin-top: 30px;
}
.page--search .searchresult {
  max-width: 100%;
}
.page--search .searchresult + .searchresult {
  margin-top: 30px;
}
.page--search .searchresult a {
  display: block;
  text-decoration: none;
  outline-offset: 7px;
}
.page--search .searchresult a:hover .searchresult__title {
  text-decoration: underline;
}
.page--search .searchresult__title {
  color: var(--color-purple);
  font: 600 18px "TypoPRO Dosis", "Dosis";
  margin: 0;
}
.page--search .searchresult__url {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 15px;
  color: var(--color-grey);
  margin-top: -2px;
  margin-bottom: 8px;
}
.page--search .searchresult__summary {
  font-size: 15px;
}