.mpForm {
  border: 1px solid #218729;
  box-shadow: 0 0.5px 4px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  background-color: #FFFFFF;
  padding: 15px;
  margin-left: -15px;
  margin-right: -15px;
}

.mpForm ul {
  list-style-type: none;
  margin: 8px 0 0;
  padding: 0;
}

.mpForm table {
  width: 100%;
}

.mpForm li {
  margin-bottom: 3px;
}

.mpForm td {
  vertical-align: top;
}

.mpLabelRow {
  margin-bottom: 5px;
}

.mpForm input[type=text],
.mpForm input[type=email] {
  width: 100%;
}

.mpFormLabel {
  padding-top: 8px;
  width: 180px;
}

.mpForm .submitCell {
  padding-top: 15px;
  text-align: right;
}

.mpForm .submitButton {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  max-width: 100%;
  border: 0;
  padding: 0;
  -webkit-appearance: none;
  text-decoration: none;
  position: relative;
  font: normal 14px/100% Arial;
  text-align: left;
  min-height: 31px;
  padding: 8px 10px;
  border-radius: 5px;
  color: #FFFFFF;
  background-color: var(--color-green);
  cursor: pointer;
}

.mpForm .submitButton:hover {
  background-color: #21b833;
}

.mpForm.wh-styledinput input[type=radio] + label,
.mpForm.wh-styledinput input[type=checkbox] + label {
  position: relative;
  padding-left: 27px;
}

.mpForm.wh-styledinput input[type=radio] + label:before,
.mpForm.wh-styledinput input[type=checkbox] + label:before {
  position: absolute;
  left: 0;
  top: -2px;
}

@media (max-width: 500px) {
  .mpForm {
    margin: 0;
  }
  .mpForm td {
    display: block;
  }
}