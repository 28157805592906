/*@import "../site/config.scss";*/
.wh-poll__page {
  display: none;
}

.wh-poll__page--poll {
  display: block;
}

.wh-poll--voted .wh-poll__page--poll {
  display: none;
}

.wh-poll--justvoted .wh-poll__page--poll,
.wh-poll--voted .wh-poll__page--poll,
.wh-poll--showresults .wh-poll__page--poll {
  display: none;
}

.wh-poll--justvoted .wh-poll__page--aftervoting {
  display: block;
}

.wh-poll--voted .wh-poll__page--results,
.wh-poll--showresults .wh-poll__page--results {
  display: block;
}

.wh-poll--voted .wh-poll__hideresultsbutton {
  display: none;
}

.wh-poll__option {
  /* FIXME: use flex or inline-flex? in forms we require deep nesting (and therefore inline-flex for FF base alignment), but do we need that for polls? */
  display: -ms-flexbox; /* IE10 */
  display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
  display: inline-flex; /* FF won't merge baselines in our parent flex if we use flex here */
  width: 100%; /* take 100% width like normal 'flex' */
  /* merge the baseline of our children with our previousSibling (label) */
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.wh-poll__optiondata {
  display: -ms-flexbox; /* IE10 */
  display: -webkit-flex; /* needed for iOS < 9.2 and SF < 9 */
  display: inline-flex; /* FF won't merge baselines in our parent flex if we use flex here */
  width: 100%; /* take 100% width like normal 'flex' */
}

/* part of the optiondata on which clicking triggers the checkbox/radiobutton */
.wh-poll__optionlabel {
  display: inline-block; /* IE10 doesn't treat inline elements as part of the flex flow */
}

/* Workaround for a Webkit bug or feature where checkboxes and radiobuttons
   don't get a specific size and shrink to fit their container.
   We only prevent this from happening in case the default form HTML
   doesn't get a custom styling. (which would hide this input and style the input + span > label::before instead)
*/
.wh-poll__option > input[type=checkbox],
.wh-poll__option > input[type=radio] {
  -ms-flex-shrink: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}

.wh-poll__poll__buttons {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.wh-poll__hideresultsbutton, .wh-poll__showresultsbutton {
  margin-left: auto;
  text-decoration: underline;
  cursor: pointer;
}

.wh-poll__poll__results > .wh-poll__option {
  display: table-row;
}

.wh-poll__poll__results .wh-poll__option__votes {
  display: table-cell;
  text-align: right;
  vertical-align: top;
  /* vertical-align: -webkit-baseline-middle; */
}

.wh-poll__poll__results .wh-poll__optiondata {
  display: table-cell;
  vertical-align: top;
  /* vertical-align: -webkit-baseline-middle; */
}

.wh-poll__poll__results .wh-poll__option__votes::before {
  content: attr(data-percentage) "%";
}

.wh-poll__option__votes {
  font: bold 16px "TypoPRO Dosis", "Dosis", Arial;
  color: var(--color-green);
}

/*
This file can only be used for mobile/tablet styling,
because the RTE cannot handle SASS stylesheet.
(and only desktop styling is relevant for the RTE)
*/
/** Widget 'Poll' *********************************************************************/
.widget--poll {
  /* the titles are too easy to overflow due the large font-size */
  overflow: hidden;
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
}

.wh-poll__question {
  margin-bottom: 10px;
}

.wh-poll__option + .wh-poll__option {
  margin-top: 6px;
}

.wh-poll__optiondata {
  margin-left: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.widget--poll .wh-poll__votebutton {
  min-width: 95px;
}

.wh-poll__showresultsbutton {
  font-size: 80%;
}

.wh-poll__votecount {
  text-align: right;
  margin-top: 15px;
}

.wh-poll__votecount__amount {
  font-weight: bold;
}

.wh-poll__poll__buttons {
  margin-top: 15px;
}

.wh-poll__poll__results .wh-poll__optiondata {
  padding-left: 15px;
}