/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
.sitefooter-2021 {
  --footer-bottomlinks-font: 13px var(--rtd-text-fontfamily);
  --footer-heading-font: bold 20px/26px "TypoPRO Dosis Menu", "Dosis", Arial;
  --footer-heading-margin: 20px;
  --footer-linkslist-padding-v: 3px;
  --footer-linkslist-icon-margin: 12px;
  --footer-linkslist-icon-font-size: 16px;
  --footer-socialbutton-size: 38px;
  --footer-socialbutton-icon-size: 20px;
  /*
  .sitefooter__social__linkswrapper
  {

  }

  .sitefooter__social__links
  {
    display: flex;
  }
  */
}
.sitefooter-2021 .sitefooter__identity__logo {
  display: block;
  padding-bottom: 15px;
}
.sitefooter-2021 .sitefooter__identity__content a {
  color: #FFFFFF;
}
@media (max-width: 669px) {
  .sitefooter-2021 .sitefooter__content {
    background: var(--footer-panel-background);
    padding: 0 var(--sidepadding) 30px var(--sidepadding);
    font: 13px / 23px var(--rtd-text-fontfamily);
  }
  .sitefooter-2021 .sitefooter__content > * + * {
    margin-top: 25px;
  }
  .sitefooter-2021 .sitefooter__content .sitefooter__background + .sitefooter__identity {
    margin-top: 0;
  }
  .sitefooter-2021 .sitefooter__identity__logo {
    width: 100px;
  }
  .sitefooter-2021 .sitefooter__identity__content, .sitefooter-2021 .sitefooter__identity__content .rtdcontent > * {
    font: 13px/20px var(--rtd-text-fontfamily);
  }
  .sitefooter-2021 .sitefooter__header {
    margin-bottom: 15px;
  }
  .sitefooter-2021 .sitefooter__bottombar {
    height: 37px;
    font: 0.5652173913 var(--rtd-text-fontfamily);
  }
  .sitefooter-2021 .sitefooter__bottombar__copyright {
    display: none;
  }
}
@media (min-width: 670px) {
  .sitefooter-2021 .sitefooter__content {
    font: 14px / 23px var(--rtd-text-fontfamily);
    background: var(--footer-panel-background);
  }
  .sitefooter-2021 .sitefooter__identity__logo {
    display: block;
    width: 188px;
  }
  .sitefooter-2021 .sitefooter__identity__content, .sitefooter-2021 .sitefooter__identity__content .rtdcontent > * {
    font: 13px/20px var(--rtd-text-fontfamily);
  }
  .sitefooter-2021 .sitefooter__header {
    margin-bottom: 20px;
  }
  .sitefooter-2021 .sitefooter__content__copyright {
    display: none;
  }
  .sitefooter-2021 .sitefooter__bottombar {
    height: 48px;
    font: 14px / 23px var(--rtd-text-fontfamily);
  }
}
@media (min-width: 670px) and (max-width: 974px) {
  .sitefooter-2021 .sitefooter__content {
    display: grid;
    grid-template-columns: calc((100% - 1180px) / 2) 15px 1fr 20px 1fr 15px calc((100% - 1180px) / 2);
    grid-row-gap: 25px;
    row-gap: 25px;
  }
  .sitefooter-2021 .sitefooter__identity {
    grid-column: 3;
    grid-row: 1;
  }
  .sitefooter-2021 .sitefooter__quicklinks {
    grid-column: 5;
    grid-row: 1;
    padding-top: 30px;
  }
  .sitefooter-2021 .sitefooter__social {
    grid-column: 3;
    grid-row: 3;
  }
  .sitefooter-2021 .sitefooter__newsletterlink {
    grid-column: 5;
    grid-row: 3;
    align-self: end;
  }
  .sitefooter-2021 .sitefooter__social, .sitefooter-2021 .sitefooter__newsletterlink {
    padding-bottom: 30px;
  }
}
@media (min-width: 975px) {
  .sitefooter-2021 .sitefooter__content {
    display: grid;
    grid-template-columns: calc((100% - 1180px) / 2) 15px 1fr 50px 1fr 50px 1fr 15px calc((100% - 1180px) / 2);
    grid-template-rows: min-content min-content;
  }
  .sitefooter-2021 .sitefooter__content > * {
    padding-top: 40px;
    padding-bottom: 30px;
  }
  .sitefooter-2021 .sitefooter__identity {
    padding-top: 0;
  }
  .sitefooter-2021 .sitefooter__identity {
    grid-column: 3;
    grid-row: 1/span 2;
  }
  .sitefooter-2021 .sitefooter__quicklinks {
    grid-column: 5;
    grid-row: 1/span 2;
  }
  .sitefooter-2021 .sitefooter__social {
    grid-column: 7;
    grid-row: 1;
  }
  .sitefooter-2021 .sitefooter__newsletterlink {
    grid-column: 7;
    grid-row: 2;
    align-self: end;
  }
}
.sitefooter-2021 .sitefooter__social {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.sitefooter-2021 a.sitefooter__newsletterlink {
  display: flex;
  align-items: center;
  text-decoration: none;
  font: 14px/23px var(--rtd-text-fontfamily);
  color: #FFFFFF;
}
.sitefooter-2021 .sitefooter__newsletterlink__icon {
  margin-right: 13px;
}
.sitefooter-2021 .sitefooter__newsletterlink__icon .far {
  font-size: 20px;
}
.sitefooter-2021 .sitefooter__socialitem:hover .fab,
.sitefooter-2021 a.sitefooter__newsletterlink:hover .sitefooter__newsletterlink__icon {
  color: var(--color-green);
}

.sitefooter__content {
  color: #FFFFFF;
  text-underline-offset: 2px;
}

.sitefooter__header {
  font: var(--footer-heading-font);
  margin-top: 0;
  margin-bottom: var(--footer-heading-margin);
  color: inherit;
}

.sitefooter__quicklinks .sitefooter__header + a {
  margin-top: -8px;
}

.sitefooter-linkslist a {
  display: flex;
  align-items: baseline;
  color: #FFFFFF;
  text-decoration: none;
  font: var(--footer-linkslist-font);
  padding-top: var(--footer-linkslist-padding-v);
  padding-bottom: var(--footer-linkslist-padding-v);
}

.sitefooter-linkslist a:hover > * {
  text-decoration: underline;
}

.sitefooter-linkslist a::before {
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  content: var(--fa-chevron-right)/"";
  font-size: var(--footer-linkslist-icon-font-size);
  margin-right: var(--footer-linkslist-icon-margin);
  text-decoration: none;
}

.sitefooter__social__links.socialbuttons-horizontal {
  display: flex;
}
.sitefooter__social__links.socialbuttons-horizontal .sitefooter__socialitem + .sitefooter__socialitem {
  margin-left: 13px;
}
.sitefooter__social__links.socialbuttons-horizontal .sitefooter__socialitem__title {
  display: none;
}

.socialbuttons-list {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.socialbuttons-list a.sitefooter__socialitem {
  align-self: start;
  padding-top: var(--sociallinks-list-padding-v);
  padding-bottom: var(--sociallinks-list-padding-v);
}
.socialbuttons-list a.sitefooter__socialitem .icon {
  margin-right: var(--footer-linkslist-icon-margin);
}
.socialbuttons-list .sitefooter__socialitem__title {
  color: #FFFFFF;
  font: var(--footer-linkslist-font);
}
.socialbuttons-list a.sitefooter__socialitem:hover .sitefooter__socialitem__title {
  text-decoration: underline;
}

a.sitefooter__socialitem {
  display: flex;
  text-decoration: none;
}

.sitefooter__socialitem .icon,
.sitefooter__newsletterlink__icon {
  display: block;
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--footer-socialbutton-size);
  height: var(--footer-socialbutton-size);
  border-radius: 50%;
  cursor: pointer;
}

.socialbuttons-outline .sitefooter__socialitem .icon {
  border: 1px solid #FFFFFF;
}

.socialbuttons-subtle .sitefooter__socialitem .icon {
  background: #4D9E54;
}

.sitefooter__socialitem .icon {
  color: #FFFFFF;
  font-size: var(--footer-socialbutton-icon-size);
}

.sitefooter__newsletter__icon .far {
  color: #FFFFFF;
  font-size: 18px;
}

.sitefooter__socialitem:hover .icon,
a.sitefooter__newsletterlink:hover .sitefooter__newsletterlink__icon {
  background-color: #FFFFFF;
  color: var(--color-green);
}

.sitefooter__bottombar {
  color: #333333;
  height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1180px;
  padding-left: 15px;
  padding-right: 15px;
  padding-left: var(--sidepadding);
  padding-right: var(--sidepadding);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: 0; /* so the "|" char can be made invisible when spanning two lines */
}

.sitefooter__bottombar > span {
  white-space: nowrap;
}

.sitefooter__bottombar > .metaitem {
  white-space: nowrap;
  display: inline-block;
  vertical-align: bottom;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.sitefooter__bottombar__copyright,
.sitefooter__bottomlinks__items,
.sitefooter__bottombar:first-line {
  font: var(--footer-bottomlinks-font);
}

.sitefooter__bottomlinks__items a {
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
}

.sitefooter__panel--bottombar {
  margin-left: -8px;
}

.sitefooter__bottombar__copyright {
  color: #333333;
  margin-right: 6px;
}

/*
.sitefooter__bottomlinks
{
  display: flex;
}

// No design available for when the items don't fit on mobile...
// But at least let them wrap instead of stretching the page.
.sitefooter__bottomlinks__items
{
  display: flex;
  flex-wrap: wrap;
}
*/
.sitefooter__bottombar__copyright {
  padding-left: 8px;
  padding-right: 8px;
}

.sitefooter__bottomlinks a {
  color: inherit;
  text-decoration: none;
  padding-left: 8px;
  padding-right: 8px;
}

.sitefooter__bottomlinks a:hover {
  text-decoration: underline;
}