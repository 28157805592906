/* Copied from 4TU, slightly modified */
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldfilename {
  padding-right: 36px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete {
  position: absolute;
  top: 0;
  right: 0;
  background: black;
  color: white;
  padding: 3px 6px 3px 3px;
  font-size: 70%;
  background-color: transparent;
  height: 100%;
  align-items: center;
  cursor: pointer;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfielddelete::before {
  width: 20px;
  height: 20px;
  color: #4A4A4A;
  border-radius: 50%;
  font: var(--fontawesome-solid);
  content: "\f057";
  font-size: 20px;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfield--hasfile .wh-form__uploadfielddelete {
  display: flex;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder {
  display: block;
  flex-grow: 100;
  position: relative;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldinputholder input {
  width: 100%;
}
.wh-form.wh-styledinput .wh-form__fieldgroup--upload .wh-form__uploadfieldselect {
  margin-left: 15px;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__uploadfieldfilename {
  background-image: linear-gradient(135deg, rgba(26, 125, 215, 0.6) 16.67%, transparent 16.67%, transparent 50%, rgba(26, 125, 215, 0.6) 50%, rgba(26, 125, 215, 0.6) 66.67%, transparent 66.67%);
  background-size: 84.85px 84.85px;
  animation: movebackground 2s linear infinite;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgeditholder::after {
  display: inline-block;
}
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgedit::after,
.wh-form.wh-styledinput .wh-form--uploading .wh-form__imgedit .wh-form__imgeditholder::before {
  display: none;
}

@keyframes movebackground {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 84.85px 84.85px;
  }
}