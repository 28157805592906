/*
Standaard opzet voor lijsten in widgets

minfinLargeListing
- ruime lijst (soms met symbolen bij elk item)
  en volle hover

minfinLargeListingLine
- toevoeging voor stippellijnen tussen items

*/
/* NOTE: reapply on widget with theme's so var(--themecolor)
         can be resolved/recalculated here to the new themecolor;
*/
html,
[class^=widgettheme--],
[class*=widgettheme--] {
  --widget-itemslist-item-icon-color: var(--themecolor);
  --widget-itemslist-item-title-color: var(--themecolor);
  /* Subtitle is used in the links and "Links (paneel)" widgets */
  --widget-itemslist-subtitle-font: 13px/20px var(--rtd-heading-fontfamily); /* Dosis Light */
  --widget-itemslist-subtitle-color: #858585;
  --widget-itemslist-item-metadata-color: var(--themecolor);
  --widget-itemslist-item-metadata-font: 13px Arial;
  --widget-itemslist-item-metadata-icon-size: 16px;
  --widget-itemslist-item-metadata-icon-margin: 9px;
  --widget-itemslist-item-description-color: var(--color-aagrey);
  --widget-itemslist-item-description-font: 13px/20px Arial;
  --widget-itemslist-large-firstitem-image-aspectratio: 16 / 9;
  --widget-itemslist-large-padding-v: 13px;
  --widget-itemslist-large-padding-h: 20px;
  --widget-icon-when: var(--fa-newspaper);
}

html:where(.site--ffw) {
  --widget-itemslist-item-metadata-font: 14px var(--rtd-heading-fontfamily);
  --widget-itemslist-item-title-font: 500 16px/20px var(--rtd-heading-fontfamily);
  --widget-itemslist-item-first-title-font: 500 20px/20px var(--rtd-heading-fontfamily);
}

html:where(.site--geldlessen, .site--wvhg) {
  --widget-itemslist-item-title-font: 500 16px/20px var(--rtd-heading-fontfamily);
  --widget-itemslist-item-first-title-font: var(--rtd-h2-font);
}

/* Some widgets use bold instead of semi-bold for titles */
.widget--news,
.widget-events {
  --widget-itemslist-item-title-font: bold 16px/20px var(--rtd-heading-fontfamily);
}

.site--wig2024 .contentwithin_wide > .widget-downloadsandlinks,
.site--wig2024 .tallcontent > .widget-downloadsandlinks {
  --widget-padding-h: 0;
  --widget-itemslist-item-title-font: 500 19px/23px var(--fontfamily-heading);
  --widget-itemslist-item-metadata-icon-size: 21px;
  --widget-itemslist-large-padding-v: 3px;
  --widget-itemslist-large-padding-h: 8px;
}

.site--wig2024 .contentwithin_wide > .widget-downloadsandlinks .widget__header,
.site--wig2024 .tallcontent > .widget-downloadsandlinks .widget__header {
  border-bottom: 0;
}

.site--wig2024 .contentwithin_wide > .widget-downloadsandlinks .minfinListItem,
.site--wig2024 .tallcontent > .widget-downloadsandlinks .minfinListItem {
  margin-left: calc(-1 * var(--widget-itemslist-large-padding-h));
  margin-right: calc(-1 * var(--widget-itemslist-large-padding-h));
}

@media (max-width: 500px) {
  html {
    --widget-itemslist-item-first-title-font: var(--rtd-h4-font);
  }
}
.widget-events {
  --widget-icon-when: var(--fa-calendar-day);
}

/* Titel van items binnen een widget */
.widget-itemslist__item__title,
.activity__title,
.personwidget__jobtitle {
  font: var(--widget-itemslist-title-font);
}

.minfinItems__item__title {
  color: var(--widget-itemslist-item-title-color);
  font: var(--widget-itemslist-item-title-font);
  margin: 0;
}

.minfinItems__item__subtitle {
  color: var(--widget-itemslist-item-subtitle-color);
  font: var(--widget-itemslist-item-subtitle-font);
}

.minfinItems__item__filemetadata {
  color: var(--widget-itemslist-item-title-color);
}

.widget-itemslist__item__subtitle {
  font: var(--widget-itemslist-subtitle-font);
  color: var(--widget-itemslist-subtitle-color);
}

/* Screenreader friendly specsheet */
.minfinItems__item__specsheet,
.minfinMetaSheet--vertical {
  order: -1;
  display: grid;
  grid-template-columns: min-content minmax(0, 1fr);
}

.minfinMetaSheet--horizontal > dl {
  display: flex;
}

.minfinItems__item__specsheet,
.minfinItems__item__when,
.minfinItems__item__location {
  color: var(--widget-itemslist-item-metadata-color);
  font: var(--widget-itemslist-item-metadata-font);
}

.minfinItems__item__title + .minfinItems__item__specsheet {
  margin-bottom: 9px;
}

.minfinItems__item__specsheet dl,
.minfinMetaSheet dl {
  margin: 0;
  padding: 0;
}

.minfinItems__item__specsheet dl,
.minfinMetaSheet--vertical dl {
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1/-1;
}

.minfinItems__item__specsheet dd,
.minfinItems__item__specsheet dt,
.minfinMetaSheet dd,
.minfinMetaSheet dt {
  margin: 0;
  padding: 3px 0;
}

/* .minfinListItem .icon */
.minfinItems__item__specsheet dt,
.minfinMetaSheet--vertical dt {
  justify-self: center;
}

.minfinItems__item__when .icon,
.minfinItems__item__specsheet .icon,
.minfinItems__item__icon,
.minfinMetaSheet .icon {
  margin-right: var(--widget-itemslist-item-metadata-icon-margin);
  flex-shrink: 0;
  color: var(--widget-itemslist-item-icon-color);
  font-size: var(--widget-itemslist-item-metadata-icon-size);
}

.minfinItems__item__when .icon::before {
  content: var(--widget-icon-when);
  content: var(--widget-icon-when)/"";
}

.minfinItems__item__location .icon::before {
  content: var(--fa-map-marker-alt);
  content: var(--fa-map-marker-alt)/"";
}

.minfinItems__item__specsheet .icon::before,
.minfinItems__item__when .icon::before,
.minfinItems__item__location .icon::before,
.widget--links .minfinListItem::before {
  font: var(--fontawesome-regular);
  font-size: var(--widget-itemslist-item-metadata-icon-size);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.minfinItems__item__description {
  display: block;
  margin-top: 4px;
  color: var(--widget-itemslist-item-description-color);
  font: var(--widget-itemslist-item-description-font);
  --rtd-text-color: var(--widget-itemslist-item-description-color);
  --rtd-text-font: var(--widget-itemslist-item-description-font);
}

.minfinItems__item__when {
  order: -2; /* in DOM it'll be after the heading (for screenreaders) - visually we want to show it before */
  margin-bottom: 2px;
}

.minfinItems__item__location {
  order: -1;
}

/*
  .site--wvhg .minfinLargeListing-container
, .site--geldlessen .minfinLargeListing-container
{
  container-name: minfinLargeListing;
  container-type: inline-size;
}
*/
@container minfinLargeListing (width > 0) {
  .minfinListItem {
    display: flex;
    align-items: baseline;
    position: relative;
    text-decoration: none;
    text-underline-offset: 3px;
    padding: var(--widget-itemslist-large-padding-v) var(--widget-itemslist-large-padding-h);
  }
  /* FIXME: check */
  a.minfinListItem--isexternal .minfinItems__item__title::after {
    content: var(--fa-external-link-alt);
    font: var(--fontawesome-regular);
  }
  a.minfinListItem:not(.minfinListItem--isexternal):hover {
    background: var(--themecolor);
    --widget-itemslist-item-icon-color: #FFFFFF;
    --widget-itemslist-item-when-color: #FFFFFF;
    --widget-itemslist-item-title-color: #FFFFFF;
    --widget-itemslist-item-subtitle-color: #FFFFFF;
    --widget-itemslist-item-description-color: #FFFFFF;
    --widget-itemslist-item-metadata-color: #FFFFFF;
  }
  /*
  Items which can have a richtext with links in them can
  use a .minfinListItem__anchor to have the full item area
  clickable while still allowing for links in the richtext to work.
  */
  .minfinListItems.minfinListItem-hasanchors > * {
    pointer-events: none;
  }
  .minfinListItems.minfinListItem-hasanchors .minfinListItem__anchor {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: auto;
  }
  .minfinListItems.minfinListItem-hasanchors .minfinListItem:has(.minfinListItem__anchor:hover) {
    background: var(--themecolor);
    --widget-itemslist-item-icon-color: #FFFFFF;
    --widget-itemslist-item-when-color: #FFFFFF;
    --widget-itemslist-item-title-color: #FFFFFF;
    --widget-itemslist-item-subtitle-color: #FFFFFF;
    --widget-itemslist-item-description-color: #FFFFFF;
    --widget-itemslist-item-metadata-color: #FFFFFF;
  }
  /* __meta is used in "Downloads & Links" to group text next to the icon */
  .minfinItems__item__when {
    margin-bottom: 2px;
  }
  .minfinItems__item__text, .minfinItems__item__title {
    flex: 1 0 0px;
  }
  /*
  For widgets with several different icons we want
  to reserve enough space so the text beside it stays aligned. */
  .widget-downloadsandlinks .minfinItems__item__icon {
    width: 16px;
    --widget-itemslist-item-metadata-icon-margin: 8px;
  }
  .minfinItems__item__icon.fa-external-link-alt {
    font-size: 15px;
  }
  /* FIXME: move to links widget? */
  .widget--links .minfinListItem::before {
    color: var(--widget-itemslist-item-icon-color);
    content: var(--fa-chevron-right);
    font: var(--fontawesome-regular);
    margin-right: var(--widget-itemslist-item-metadata-icon-margin);
  }
  .minfinItems__item__description {
    display: block;
    margin-top: 4px;
  }
}
@container minfinLargeListingLine (width > 0) {
  html:where(.site--geldlessen, .site--wvhg, .site--ffw) {
    /* Leave out the bottom divider line on the last item */
  }
  html:where(.site--geldlessen, .site--wvhg, .site--ffw) .minfinListItem::after {
    content: "";
    border-top: 2px dotted #d5d5d5;
    position: absolute;
    bottom: -2px;
    left: var(--widget-padding-h);
    right: var(--widget-padding-h);
  }
  html:where(.site--geldlessen, .site--wvhg, .site--ffw) .minfinListItem:not(.minfinListItem--forceline):last-child::after {
    display: none;
  }
}