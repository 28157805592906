/*
html
{
  --component-navpath-color:          #656565;
  --component-navpath-link-color:     var(--rtd-link-color);
  --component-navpath-font:           15px var(--rtd-text-fonfamily);
  --component-navpath-gap-v:          4px;
  --component-navpath-prefix-margin:  10px; / * extra space between prefix and items * /

  --component-navpath-item-padding-h: 5px;
  --component-navpath-item-padding-v: 5px;
}
*/

.page__navigationpath
{
  display: flex;
  align-items: baseline;
  flex-wrap: wrap; /* make the navigation path items move to below the prefix ("You're here") when they don't fit together on a single line */

  column-gap: var(--component-navpath-prefix-margin); /* used when the prefix + items fit next to each other */
  row-gap: var(--component-navpath-gap-v);

  max-width: 100%; /* prevent growing beyond the viewport width */

  color: var(--component-navpath-color);
  font:  var(--component-navpath-font);
}

.page__navigationpath__prefix.page__navigationpath__prefix
{
  padding-left: 0;
}

.page__navigationpath__items
{
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  white-space: nowrap;

  /* Visually compensate the position of items.
     The is needed because the text in the items wouldn't be visually aligned to
     the page content without this due their padding-left.
     (we need the padding to enlarge their hitareas)
  */
  margin: 0 calc(-1 * var(--component-navpath-item-padding-h));
}

  .page__navigationpath a
, .page__navigationpath span
{
  color: inherit;
  padding: var(--component-navpath-item-padding-h) var(--component-navpath-item-padding-h);
}

.page__navigationpath a
{
  color: var(--component-navpath-link-color, var(--component-navpath-color));
  text-decoration: underline;
  text-underline-offset: 2px;
}

.page__navigationpath a:hover
{
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.page__navigationpath__seperator
{

}

.page__navigationpath__seperator::before
{
  color: var(--component-navpath-seperator-color);
  content: var(--component-navpath-seperator-content);
}

