.wh-form__imgedit:not(wh-form-imgedit) {
  width: 150px;
  height: 200px;
  padding: 0;
  flex: none;
  border: 1px solid var(--formcontrols-border-color);
}
.wh-form__imgedit:not(wh-form-imgedit)::after {
  display: flex;
  font: var(--fontawesome-solid);
  content: "\f382"; /* cloud */
  font-weight: 700;
  font-size: 33px;
  align-items: center;
  justify-content: center;
  background-image: none;
  padding-bottom: 40px;
  color: #4A4A4A;
}
.wh-form__imgedit .wh-form__imgeditdelete {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 5px;
  right: 10px;
  background-color: transparent;
  cursor: pointer;
}
.wh-form__imgedit .wh-form__imgeditdelete::before {
  width: 20px;
  height: 20px;
  color: #4A4A4A;
  border-radius: 50%;
  background-color: #fff;
  font-family: var(--fontawesome-solid);
  content: "\f057";
  font-size: 20px;
}
.wh-form__imgedit .wh-form__imgeditholder::before {
  display: block;
  position: absolute;
  text-align: center;
  font-size: 15px;
  color: var(--rtd-text-color);
  bottom: 0;
  left: 0;
  padding: 20px;
  content: "Drop or upload an image";
}
.wh-form__imgedit--hasimage::after,
.wh-form__imgedit--hasimage .wh-form__imgeditholder::before {
  display: none;
}
.wh-form__imgedit[data-wh-form-disabled]::after {
  opacity: 0.2;
}
.wh-form__imgedit[data-wh-form-disabled] .wh-form__imgeditholder {
  display: none;
}

.wh-form__imgeditholder::after {
  opacity: 1;
  z-index: 1;
  display: inline-block;
  position: absolute;
  display: none;
  width: 80px;
  top: 50%;
  left: 50%;
  height: 80px;
  font-size: 80px;
  line-height: 80px;
  font: var(--fontawesome-solid);
  font-size: 20px;
  content: "\f1ce"; /* cloud */
  color: #4A4A4A;
  margin-top: -40px;
  margin-left: -40px;
  opacity: 0.8;
  animation: fa-spin 2s infinite linear;
}