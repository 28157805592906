/*
Standaard opzet voor widget headers

NOTE: Don't uses SASS or CSS nesting because we use this file in the RTD editor.

.widget
  .widget__header
    <h2>
    .widget__showmorelink
      span.text
      span.icon role="presentation"
    .widget__description
  ....
*/

html
{
  --widget-header-morelink-font: 15px var(--rtd-heading-fontfamily);
  --widget-header-morelink-icon-size: 16px; /* was 12px */
}

  .widget--news
, .widget-events
{
  --widget-header-morelink-font: 500 16px var(--rtd-heading-fontfamily); /* "Sidebar" styling in design */
}


.widget__header
{
  display: grid;

/*  grid-template-rows: min-content min-content;*/
  grid-template-rows: minmax(40px, min-content) min-content;
  grid-template-columns: minmax(0, 1fr) max-content;
  column-gap: 20px;

  align-items: baseline;

  border-bottom: 1px solid #dedede;
/*  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);*/
  padding: 9px var(--widget-padding-h) 10px var(--widget-padding-h);
}

.widget__header .widget__title
{
  grid-column: 1;
  grid-row: 1;

  min-height: 40px;
  padding-top: 8px;
  padding-bottom: 8px;
/*align-self: center;*/

  display: flex;
  align-items: center;

  text-wrap: pretty;
/*  margin-top: -5px;*/
}

.widget__title + .widget__description
{
  margin-top: 5px;
}

/* When used inline have the header without line */
:where(.richdoc > .widget--news)
{
  .widget__header
  {
    border: 0;
    padding-top: 0;
    padding-bottom: 0;
    grid-template-rows: min-content min-content;
  }

  .widget__header .widget__title
  {
    min-height: 0;
  }
}

/* Title outside the special .widget__header needs some margin */
.widget--default .widget__title + .widget__description
{
  margin-top: 10px;
}


/*
:where(.richdoc > .widget--news)
{
  / *   FIXME: have some way to specify whether a widget has a header of min-height with a line or not* /
  .widget__header
  {
    border: 0;
    padding: 0;
    grid-template-rows: min-content min-content;
  }
  .widget__header .widget__title
  {
    min-height: auto;
  }
}
*/

.widget__header .widget__showmorelink
{
  grid-column: 2;
  grid-row: 1;

  align-self: baseline; /* fallback */
/*  align-self: last baseline;*/

  display: flex;
  text-decoration: none;
  text-underline-offset: 3px;

  color: var(--widget-title-color);
  font: var(--widget-header-morelink-font);

  padding: 7px 12px;
  margin-right: -12px;
}

.widget__header .widget__showmorelink::after
{
  align-self: center;

/*  @include fontawesome-regular;*/
  color: var(--themecolor);
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-circle-right);
  font-size: var(--widget-header-morelink-icon-size);

  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  transform: translateX(0) scale(1);
  transform-origin: center center;
  transition: transform var(--button-transition-duration);

  margin-left: 10px;
}

.widget__header .widget__showmorelink:hover
{
  background: rgba(0,100,0,0.08);
  border-radius: 8px;

}

.widget__header .widget__showmorelink:hover .text
{
  text-decoration: underline;
  text-underline-offset: 3px;
}

.widget__header .widget__showmorelink:hover::after
{
  transform: translateX(0px) scale(1.2);
}

.widget__header .widget__description
{
  grid-column: 1 / span 2;
  grid-row: 2;
}



.widget__header:has(.widget__description)
{
  padding-top: 0px;
  padding-bottom: 0px;
}



  .widget__title
, .wh-poll__title
{
  color: var(--widget-title-color);
  margin: 0;
}

.widget__header .widget__title
{
  margin-bottom: -5px; /* consensate for font */
}

.widget__header > .widget__description
{
/*  padding: 0 var(--widget-padding-h);*/
/*  padding-bottom: var(--widget-padding-bottom);*/
  padding-bottom: 18px;
/*  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);*/
}
/*
}
*/
