/*
In case of tabs with a transparent background (but which gets a background on hover)
we want to align the text to the left of the content area.
This way we have visual alignment in the default (non-hover/focus) state.
*/
.spc-tabstrip--tabs .pagetabs__content {
  margin-left: calc(-1 * var(--component-tabstrip-item-padleft));
}

/*
- The <a> and <span>'s are used by RenderPageTabs
- The <input> + <label> DOM are used by RenderRadioTabs
*/
/*
.spc-tabstrip
{
  margin-top: calc(-1 * var(--contentpanels-radius-outsidegutter)); / * stick to header * /

  grid-column: var(--pagegrid-col-fullwidth);
  display: grid;
  grid-template-columns: var(--pagegrid-template-columns);
}



.spc-tabstrip__background
{
  z-index: -1;

  grid-column: var(--pagegrid-col-verywide-outsidegutter);
  grid-row: 1;

  background-color: var(--component-tabstrip-background);
  border-radius: 0 0 var(--contentpanels-radius-outsidegutter) var(--contentpanels-radius-outsidegutter);
}
*/
.spc-tabstrip__content {
  /* REPEAT the same background color so accessibility
     checkers don't need to find our background element.
  */
  background-color: var(--component-tabstrip-background);
  margin-top: var(--contentpanels-radius-outsidegutter);
  grid-column: var(--pagegrid-col-verywide);
  grid-row: 1;
  display: flex;
  column-gap: var(--component-tabstrip-spacing);
  min-height: var(--component-tabstrip-height);
}

.spc-tabstrip.eventlocation .pagetabs__content {
  display: block;
  grid-column: var(--pagegrid-col-default);
  padding-top: 15px;
  padding-bottom: 15px;
}

.spc-tabstrip--tabs a,
.spc-tabstrip--tabs span,
.spc-tabstrip--tabs label {
  display: flex;
  align-items: center;
  text-decoration: none;
  font: var(--component-tabstrip-font);
  background: var(--component-tabstrip-item-background);
  border: var(--component-tabstrip-item-border);
  color: var(--component-tabstrip-item-color);
  padding-left: var(--component-tabstrip-item-padleft);
  padding-right: var(--component-tabstrip-item-padright);
  position: relative; /* for positioning the underline */
}

.spc-tabstrip--tabs a:hover,
.spc-tabstrip--tabs label:hover {
  background: var(--component-tabstrip-item-hover-background);
  border: var(--component-tabstrip-item-hover-border);
  color: var(--component-tabstrip-item-hover-color);
}

.spc-tabstrip--tabs [aria-current],
.spc-tabstrip--tabs input:checked + label {
  background: var(--component-tabstrip-item-selected-background);
  border: var(--component-tabstrip-item-selected-border);
  color: var(--component-tabstrip-item-selected-color);
  font: var(--component-tabstrip-item-selected-font);
}

.spc-tabstrip--tabs [aria-current]::after,
.spc-tabstrip--tabs input:checked + label::after {
  content: "";
  position: absolute;
  left: var(--component-tabstrip-item-padleft);
  right: 0;
  bottom: 0;
  border-bottom: var(--component-tabstrip-item-selected-underlining-height) solid var(--component-tabstrip-item-selected-underlining-color);
}

.spc-tabstrip--tabs label {
  cursor: pointer;
}

.spc-tabstrip--tabs input[type=radio] {
  /* display: none or visibility: hidden; will prevent focus!! */
  position: absolute; /* to not mess with flex containers */
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
  padding: 0;
}