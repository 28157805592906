.wh-form__fieldgroup--custom-info.wh-form__fieldgroup--custom-info.wh-form__fieldgroup--custom-info {
  grid-column: var(--pagegrid-col-default);
}

.wh-form__fieldgroup--custom-info {
  background-color: var(--color-green-light-background);
  padding: 18px 18px !important;
  border-left: 4px solid var(--color-green);
  margin-top: 20px;
  margin-bottom: 40px !important;
}

h1.wh-form__grouptitle.wh-form__grouptitle {
  margin-top: 0;
}

.wh-form__infotextwrapper {
  display: flex;
  align-items: baseline;
  background-color: var(--color-sublegrey);
  padding: 5px var(--textcontrols_padleft);
  border-radius: 0 0 4px 4px;
  padding-top: 8px;
  z-index: -1;
}

.wh-form__fieldgroup--checkboxgroup > .wh-form__fields > .wh-form__infotextwrapper,
.wh-form__fieldgroup--radiogroup > .wh-form__fields > .wh-form__infotextwrapper {
  margin-top: 10px;
  border-radius: 4px;
}

.wh-form__infotextwrapper::before {
  font: var(--fontawesome-regular);
  font-size: 20px;
  content: var(--fa-info-circle);
  color: #999999;
  margin-right: 15px;
  position: relative;
  top: 2px;
}

.wh-form__infotext {
  --rtd-text-font: var(--formlayout-fieldgroup-info-font);
}

.wh-form__infotext > :first-child {
  margin-top: 0;
}

.wh-form__infotext > :last-child {
  margin-bottom: 0;
}

.wh-form__infotext ul.unordered.unordered,
.wh-form__infotext ol.ordered.ordered {
  padding-left: 16px;
}

.wh-form__fieldgroup:focus-within .wh-form__infotextwrapper {
  background-color: #F1F1F1;
}

.wh-form__fieldgroup:focus-within .wh-form__infotextwrapper::before {
  color: var(--color-green-small-on-light-background);
}

.wh-form__fieldgroup--error .wh-form__infotextwrapper.wh-form__infotextwrapper {
  background: transparent;
  z-index: 0; /* -1 would cause us to be behind the red background of the fieldgroup */
}