/** Widget 'Video' *********************************************************************/

.widget--video
{
  position: relative;

  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);

/*outline: 1px solid #A00;*/
}

.site--geldlessen .widget--video .widget__title .icon
{
  display: none;
}

.widget--video .widget__title
{
  margin-bottom: 10px;
}

.widget--video__image
{
  position: relative;
  background-size: cover;
  cursor: pointer;

  margin: 0;
  padding: 0;
  border: 0;
  width: 100%;
  display:  block;

  outline-offset: var(--actionbutton-outline-offset);
}

.widget--video__image--placeholder
{
  background-color: #E0E0E0;
}


  .widget--video__playbutton
, .wh-video__playbutton
, .widget--largecta__playbutton
, .mf_playbutton
{
  position: absolute;
  left: 50%;
  top: 50%;

  /* reset <button> styling */
  background: none;
  padding: 0;
  border:  0;

  cursor: pointer;
}

/* !! Ook in gebruik door Financieel Fitte Werknemers */
/*html:not(.styling2025)*/
  html.site--ffw
, html.site--wig
, html.site--p3d
{
    .widget--video__playbutton
  , .wh-video__playbutton
  , .widget--largecta__playbutton
  , .mf_playbutton
  {
    --scale: 1;

    font-size: 53px;

    /*
    color: #FFFFFF;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.34);
    opacity: 0.75;
    transition: opacity 0.25s;
    transform: translate(-50%, -50%);
    */

    transform: translate(-50%, -50%) scale(var(--scale));

    -webkit-text-stroke: 2px rgba(255,255,255,0.7);
    color: var(--color-green-transparent);
    color: var(--themecolor-transparent);
/*    text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.54);*/
    transition: color var(--button-transition-duration), transform var(--button-transition-duration);
  }

    .widget--video [data-video]:hover .widget--video__playbutton
  , .widget--video [data-video]:hover .wh-video__playbutton
  , .widget--image[data-video]:hover .widget--video__playbutton /* WVHG */
  , .widget--largecta [data-popup-video]:hover .widget--largecta__playbutton /* WVHG home - will be a normal widget in the future */
  /*, .widget--largecta__playbutton*/
  /*, .mf_playbutton*/
  , .wh-video .wh-video__playbutton:hover
  {
    --scale: 1.1;
    color: var(--color-green);
    color: var(--themecolor);
  }
}



/*html.styling2025*/
  html.site--wvhg
, html.site--geldlessen
{
    .widget--video__playbutton
  , .wh-video__playbutton
  , .widget--largecta__playbutton
  , .mf_playbutton
  {
    --scale: 1;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;

/*    background-color: var(--themecolor);*/
    background: var(--widget-themecolor-bg);
    color: #FFFFFF;
    font-size: 20px;

    padding-left: 4px;

    transform: translate(-50%, -50%) scale(var(--scale));
    transition: color 0.25s, transform 0.25s;
  }


    .widget--video [data-video]:hover .widget--video__playbutton
  , .widget--video [data-video]:hover .wh-video__playbutton
  , .widget--image[data-video]:hover .widget--video__playbutton /* WVHG */
  , .widget--largecta [data-popup-video]:hover .widget--largecta__playbutton /* WVHG home - will be a normal widget in the future */
  /*, .widget--largecta__playbutton*/
  /*, .mf_playbutton*/
  , .wh-video .wh-video__playbutton:hover
  {
    --scale: 1.1;
/*    color: var(--color-green);*/
/*    color: var(--themecolor);*/
  }
}

.widget--video__duration
{
  position: absolute;
  right: 6px;
  bottom: 5px;

  padding: 3px 6px;
  font-size: 14.5px;
  line-height: 100%;

  color: #FFFFFF;
  background-color: rgba(0,0,0,0.55);
}

.widget--video__buttons
{
  margin-top: 15px;

  display: flex;
  align-items: center;
}

.widget--video__button
{
  display: flex;
  align-items: center;

  color: var(--themecolor);
}

.widget--video__button + .widget--video__button
{
  margin-left: auto;
}

.widget--video__button .fa
{
  font-size: 125%;
  margin-right: 10px;
}

a.widget--video__button
{
  text-decoration: none;
}

a.widget--video__button:hover .text
{
  text-decoration: underline;
  text-underline-offset: 3px;
}
