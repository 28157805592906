.spc-menubar__pulldown .spc-menubar__item {
  /* have toggle button and title side-by-side */
  display: flex;
  align-items: center;
}

.spc-menubar__pulldown .spc-menubar__item__title {
  flex: 1 1 0px;
}

.spc-menubar__pulldown .spc-menubar__item__togglebutton {
  z-index: 100; /* FIXME: why do we need 99 or higher? */
}

.spc-menubar__level3,
.spc-menubar__level4 {
  height: 0;
  overflow: hidden;
}

.spc-menubar__item--expand > .spc-menubar__levelpanel > ul {
  height: auto;
  overflow: visible; /* needs to be visible so :focus-visible outline is visible */
}

ul.spc-menubar__level2 > li > .spc-menubar__item {
  padding-left: var(--menubar-level2-padding-left);
  padding-right: var(--menubar-level2-padding-right);
}

ul.spc-menubar__level2 > li > .spc-menubar__item .spc-menubar__item__title {
  color: var(--menubar-level2-color);
  font: var(--menubar-level2-font);
  text-decoration: none;
  /*
    padding-left:   var(--menubar-level2-padding-left);
    padding-right:  var(--menubar-level2-padding-right);
  */
  padding-top: var(--menubar-level2-padding-top);
  padding-bottom: var(--menubar-level2-padding-bottom);
}

ul.spc-menubar__level2 > li > .spc-menubar__item .spc-menubar__item__togglebutton {
  margin-left: var(--menubar-bar-toggler-margin);
  color: var(--menubar-level2-color);
}

ul.spc-menubar__level3 > li > .spc-menubar__item .spc-menubar__item__title {
  color: var(--menubar-level3-color);
  font: var(--menubar-level3-font);
  text-decoration: none;
  padding-left: var(--menubar-level3-padding-left);
  padding-right: var(--menubar-level3-padding-right);
  padding-top: var(--menubar-level3-padding-top);
  padding-bottom: var(--menubar-level3-padding-bottom);
}

ul.spc-menubar__level3 > li > .spc-menubar__item .spc-menubar__item__togglebutton {
  color: var(--menubar-level2-color);
}

ul.spc-menubar__level4 > li > .spc-menubar__item .spc-menubar__item__title {
  color: var(--menubar-level4-color);
  font: var(--menubar-level4-font);
  text-decoration: none;
  padding-left: var(--menubar-level4-padding-left);
  padding-right: var(--menubar-level4-padding-right);
  padding-top: var(--menubar-level4-padding-top);
  padding-bottom: var(--menubar-level4-padding-bottom);
}

ul.spc-menubar__level4 > li > .spc-menubar__item .spc-menubar__item__togglebutton {
  color: var(--menubar-level4-color);
}

ul.spc-menubar__level2 > li > .spc-menubar__item:hover,
ul.spc-menubar__level3 > li > .spc-menubar__item:hover,
ul.spc-menubar__level4 > li > .spc-menubar__item:hover {
  text-decoration: none;
  background-color: var(--menubar-pulldown-itemhover-bgcolor);
  color: var(--menubar-pulldown-itemhover-textcolor);
  text-decoration: var(--menubar-pulldown-itemhover-textdecoration);
}

ul.spc-menubar__level2 > li .spc-menubar__item__togglebutton::before {
  flex: none;
  font: var(--menubar-level2-toggle-font);
  content: var(--menubar-level2-expandicon);
  display: block;
}
ul.spc-menubar__level2 > li.spc-menubar__item--expand > a .spc-menubar__item__toggle::before {
  content: var(--menubar-level2-collapseicon);
  padding-left: 0;
}

ul.spc-menubar__level3 > li .spc-menubar__item__togglebutton::before {
  flex: none;
  font: var(--menubar-level3-toggle-font);
  content: var(--menubar-level3-expandicon);
}
ul.spc-menubar__level3 > li.spc-menubar__item--expand > a .spc-menubar__item__toggle::before {
  content: var(--menubar-level3-collapseicon);
  padding-left: 0;
}