/*
Keep this PLAIN CSS !!
This file is used by the richtext editor, which doesn't support SASS
*/

html.wh-widgetpreview body
{
  display: inline-block;
/*  width: 380px;*/

  padding: 20px;
  background: #E6E6E6;
}

 /* .widget--widgetsgroup) */

html.wh-widgetpreview:has(.page__widgetsgrid) body
{
  background: #FFFFFF;
}

html.wh-widgetpreview .page__widgetsgrid
{
  margin: 0;
}

  html.wh-widgetpreview .widget
, html.wh-widgetpreview .widget--default
, html.wh-widgetpreview .widget--default-tip
, html.wh-widgetpreview .widget--downloads
, html.wh-widgetpreview .widget--highlight
, html.wh-widgetpreview .widget--image
, html.wh-widgetpreview .widget--image_with_quote
, html.wh-widgetpreview .widget--default
, html.wh-widgetpreview .widget--links
, html.wh-widgetpreview .widget--newsletter
, html.wh-widgetpreview .widget--news
, html.wh-widgetpreview .widget--poll
, html.wh-widgetpreview .widget--question_answer
, html.wh-widgetpreview .widget--quote_bubble
, html.wh-widgetpreview .widget--search
, html.wh-widgetpreview .widget--slideshare
, html.wh-widgetpreview .widget--video
{
  width: 340px;
}

/* inline widgets should use the small or wide content width */
  html.wh-widgetpreview .widget--accordion
, html.wh-widgetpreview .widget--button
, html.wh-widgetpreview .widget--checklist
, html.wh-widgetpreview .widget--embedwidgetsgroup
, html.wh-widgetpreview .widget--extrainfo
, html.wh-widgetpreview .widget--quote
, html.wh-widgetpreview .widget--tips
, html.wh-widgetpreview .widget--widgetsgroup
{
  width: 940px;
}

/*html.wh-widgetpreview .widgetsgrid*/
html.wh-widgetpreview .widget--widgetsgroup
{
  width: 1000px;
}



.widget
{
  font: 14px/24px Arial;
}

.widget--title
{
  margin-bottom: 20px;
}

  .widget--downloads .widget__title .fa
, .widget--links     .widget__title .fa
, .widget--video     .widget__title .fa
{
  margin-right: 10px;
}

  .widget--default   .widget__description
, .widget--downloads .widget__descriptionwrapper
{
  padding-bottom: 15px;
}

.widget--video .widget__description
{
  margin-bottom: 10px;
}


  .widget--default
, .widget--downloads
, .widget--image
, .widget--links
, .widget--poll
, .widget--question_answer
, .widget--slideshare
, .widget--video
{
  background-color: #FFFFFF;
}

.content__widgetscolumn .widget-downloadsandlinks
{
  background-color: #FFFFFF;
/*  padding-left: var(--widget-padding-h);*/
/*  padding-right: var(--widget-padding-h);*/
/*  padding-top: var(--widget-padding-v);*/
/*  padding-bottom: var(--widget-padding-v);*/
}

.content__widgetscolumn .widget-downloadsandlinks .widget__title
{
  margin-top: 0;
}


.widget--opaquebg-green
{
  background-color: var(--color-green);
  color: #FFFFFF;
}
.widget--opaquebg-purple
{
  background-color: #641c76;
  color: #FFFFFF;
}


  .widget--default .ctabutton
, .widget--newsletter .ctabutton
{
  margin-top: 15px;
}





/* placeholder for popup content */
.site__popupcontent
{
  display: none;
}


.page__widgetsgrid .widget
{
  -webkit-column-break-inside:avoid;
  -moz-column-break-inside:avoid;
  column-break-inside:avoid;

  page-break-inside: avoid; /* Firefox 20+ ? */
  break-inside: avoid-column;
}







.content__widgetscolumn
{
  position: relative;
}
.content__widgetscolumn .widget + .widget
{
  margin-top: 20px;
}





.widget--anchor
{
  display: block;

  /* offset to make sure anchor's within the page
     scroll a little further so the indended startposition
     doesn't start under the fixed header
  */
  position: relative;
  top: -45px;

  height: 0;
  margin: 0;
  padding: 0;

  /* border: 1px solid rgba(255, 0, 0, 0.25); */
}

.widget--anchor--preview
{
  font-size: 20px;
  color: #000000;
  padding: 0 20px;
  text-align: center;
}




/* Make the default widget stretch in a equalheight widgetsgrid */
.widget--default .widget__body
{
  min-height: 100%;

  display: flex;
  flex-direction: column;

  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
}

.widget--default .widget__description
{
  margin-bottom: auto;
}
/* ------------------------------------------------------------ */



.widget--default img
{
  display: block;
  width: 100%;
  height: auto;
  vertical-align: bottom; /* to prevent whitespace below */
}

.widget--default .ctabutton
{
  width: 100%;
}



/* tip/checklist */
.widget--default__title__label
{
  display: inline-block;
  padding: 0 5px;
  color: #FFFFFF;
  font-size: 26px;
  margin-right: 12px;
}
.widget--default__title__symbol
{
  font-size: 26px;
  margin-right: 12px;
}



.widget--downloads .widget__descriptionwrapper
{
  position: relative;
}
.widget__description__image
{
  position: absolute;
  right: 0;
  bottom: 0;
}

.widget--downloads .ctalink:first-child
{
  border-top: 1px solid #e6e6e6;
}
.widget--downloads .ctalink
{
  padding: 6px 0;
  border-bottom: 1px solid #e6e6e6;
}



.widget--links
{
/*  outline: 2px solid #F00;*/
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
}

.widget--links .ctalink
{
  padding: 6px 0;
  border-bottom: 1px solid #e6e6e6;
}



/* 56 x 80 in example widget */
.widget--highlight
{
  overflow: hidden; /* grow to wrap floating icon */
}
a.widget--highlight
{
  display: block;
  text-decoration: none;
}

.widget--highlight.widget--colorgreen
{
  color: #FFFFFF;
}
.widget--highlight.widget--colorgray
{
}
.widget--highlight.widget--colorpurple
{
  color: #FFFFFF;
}



.widget--highlight__body
{
  color: #FFFFFF;
}

.widget--highlight p.normal
{
  font: inherit;
  font-weight: 200;
}
.widget--highlight b
{
  font-weight: 500;
}

.widget--highlight__icon
{
  float: right;
  width: 25%;
}



.widget--iframe iframe
{
  width:  100%; /* IE & Edge */
  width:  -webkit-fill-available; /* Chrome 22+ & SF6.1+ */
  width:  -moz-available; /* FF3+ */

  height: 1000px;
  border: 0;
}



.widget--image
{
  position: relative;
  text-decoration: none;
  background-size: cover;

  outline-offset: var(--actionbutton-outline-offset);
}
.widget--image > div
{
  background-size: cover;
}
a.widget--image
{
  display: block;
}
.widget--image__content
{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
}
.widget--image .widget__title
{
  color: #FFFFFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.6);
}
.widget--image .ctabutton
{
  width: 100%;
  margin-top: auto;
}



.widget--image_with_quote .widget__title
{
  margin-bottom: 0;
}
.widget--image_with_quote img
{
  display: block;
  width: 100%;
  height: auto;
  vertical-align: bottom; /* to prevent whitespace below */
}



/* Make the widget stretch within .widget--widgetsgroup--equalheight */
.widget--image_with_quote
{
  display: flex;
  flex-direction: column;

  text-decoration: none; /* in case it's an <a> */

  outline-offset: var(--actionbutton-outline-offset);
}

.widget--image_with_quote img
{
  flex: none;
}

.widget--image_with_quote .widget__title
{
  flex: 1 0 auto;
}

/* in twocolumn */
@media (max-width: 950px)
{
  .content--twocolumn .widgetsgrid
  {
    --widget-padding-h: 20px;
    --widget-padding-v: 16px;
  }
  .content--twocolumn .widget--image_with_quote .widget__title
  {
    font:  500 24px/30px "TypoPRO Dosis", "Dosis", Arial;
  }
}


.widget--newsletter__header
{
  display: flex;
  align-items: center;

  font-size: 21px;
  line-height: 28px;

  margin-bottom: 20px;
}

.widget--newsletter .fa
{
  font-size: 50px;
  margin-right: 15px;
}

.widget--newsletter__email
{
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  width: 100% !important;
}

.widget--newsletter .ctabutton
{
  width: 100%;
}





.widget--question_answer
{
}
.widget--question_answer__question
{
  position: relative;
  color: #FFFFFF;
}
.widget--question_answer__question::after
{
  display: block;
  content: "";
  background-size: cover;

  width: 30px;
  height: 18px;

  position: absolute;
  right: 106px;
  top: 100%;
}


.widget--question_answer__question
{
  margin-bottom: 35px;
}
.widget--question_answer__cta
{
  padding-top: 0 !important;
  text-align: right;
}


.widget--question_answer > .question_answer_popup
{
  display: none;
}
.question_answer_popup
{
  width: 380px;
}
.question_answer_popup .links
{
  margin-top: 10px;
  border-top: 1px solid #dddddd;
  padding-top: 10px;
}
.question_answer_popup .links a
{
  display: block;
  position: relative;
  padding-left: 35px;
  color: #0cb24c;
  text-decoration: none;
  font: 600 19px/24px "TypoPRO Dosis", "Dosis", Arial;
}
.question_answer_popup .links a > .fa
{
  width: 30px;
  height: 30px;
  color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 21px;
  text-align: center;
  padding-top: 3px;
  background-color: #0cb24c;
}
.question_answer_popup .links a > .note
{
  color: #767676;
  display: inline-block;
  position: relative;
  top: -3px;
  font: 14px/20px Arial, Sans-Serif;
}



.widget--quote
{

}

.widget--quote__quote
{
  margin: 0 20px 0 0;
  padding: 15px;

  border: 0;
  border-left: 6px solid #e0e0e0;
  background-color: var(--color-sublegrey);

  font: 600 32px "TypoPRO Dosis", "Dosis", Arial;
}

/* use either the behaviour of 'flex' or 'table'
   to stretch to use the available width
   instead of using the full width but have the flow be around the floating image
*/
.widget--quote__body
{
  display: flex;

  margin-top: 25px;
  /* FIXME: in column or make stretch to be beside floating personal stuff? */
}

/* [IEFIX] IE10 defaults to 0 1 auto; making <p> children
           of a flex container never shrink, but grow to fit
*/
.widget--quote__body p
{
  flex: 0 1 auto;
}

.widget--quote__columns
{
}

.widget--quote__profile
{
  float: right;
  margin-top: -30px;
  margin-left: 35px;
}

.widget--quote__personimage
{
  display: block;
  width: 170px;
  height: 170px;

  margin-bottom: 6px;

  border-top: 5px solid #FFFFFF;
  border-left: 5px solid #FFFFFF;
}

.widget--quote__personname
{
  font-size: 14px;
}

.widget--quote__companyname
{
  color: #636363;
  font-size: 14px;
  font-style: italic;
}




.widget--extrainfo
{
  border: 1px solid #641c76;
  border-top: 0;
}
.widget--extrainfo__title
{
  background-color: #641c76;
  color: #FFFFFF;

  font: normal 18px "TypoPRO Dosis", "Dosis", Arial;

  height: 38px;
  line-height: 38px;

  padding: 0 15px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.widget--extrainfo__body
{
  padding: 20px 14px;
}



.widget--search
{

}

.widget--search form
{
  margin: 0;
}

.widget--search__header
{
  display: flex;
  align-items: center;

  margin-top:     5px;
  margin-bottom: 15px;
}

.widget--search__header .fa
{
  font-size: 69px;
  margin-right: 15px;
}

.widget--search__title
{
  font: 600 26px "TypoPRO Dosis", "Dosis", Arial;
  color: #FFFFFF;
}

.widget--search .searchcontrol
{
  display: flex;
}

.widget--search .searchcontrol__input
{
  flex: 1 0 0px;
}




/** Widget 'Quote' *********************************************************************/

.widget--quote_bubble
{
  background-color: #FFFFFF;
}

.widget--quote_bubble__quote
{
  position: relative;
  border-bottom: 1px solid #666666;
  padding-bottom: 15px;
}

.widget--quote_bubble__quote::after
{
  display: block;
  content: "";
  background-size: cover;

  width: 31px;
  height: 20px;

  position: absolute;
  right: 72px;
  top: 100%;
}

.widget--quote_bubble__profile
{
  margin-top: 32px;
  text-align: right;
}

.widget--quote_bubble__person
{
  font-style: italic;
}

.widget--quote_bubble__company
{
  font-weight: bold;
}

.widget--quote_bubble__cta
{
  margin-top: 30px;
  text-align: right;
}



/** Widget 'Slideshare' *********************************************************************/

.widget--slideshare .widget__body
{
  padding: var(--widget-padding-top) var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
}

.widget--slideshare__embed
{
  position: relative;
  width: 100%;
}
/* force aspect ratio */
.widget--slideshare__embed:after
{
  display: block;
  content: '';
  padding-top: 83.5%;
}
.widget--slideshare__embed > div
{
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.widget--slideshare__embed iframe
{
  display: block;
  width: 100%;
  height: 100%;
}
.widget--slideshare__downloads
{
  display: flex;

  margin-top: 15px;
}
.widget--slideshare__downloads a + a
{
  margin-left: auto;
}
.widget--slideshare__downloads a
{
  display: inline-block; /* [IEFIX] */
  text-decoration: none;
  color: var(--color-green-small);
  background: transparent none 0px 1px no-repeat;
}
.widget--slideshare__downloads a:hover
{
  text-decoration: underline;
}
.widget--slideshare__downloads .fa
{
  font-size: 125%;
  margin-right: 5px;
}






.widget--wiglibrary .widget__title
{
  background-image: url("../web/img/stocksy-txp4e395c67lab300-medium-3509672.crop.jpg")
                  , linear-gradient(#218729, #218729);
  background-blend-mode: multiply, darken;
  background-size: cover;
}
