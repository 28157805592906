.widget--checklist
{

}

.checklist_items
{
  list-style: none;
  margin: 0;
  padding: 0;
}

.checklist__item
{
  /* keep title margin-top within */
  /*
  display: table;
  display: flow-root;
  */
  display: flex;

  position: relative;
}

.checklist__item + .checklist__item
{
  margin-top: 20px;
}

.checklist__checkbox
{
  margin-right: 15px;
}

.checklist__checkbox::before
{
  color: var(--widget-checklist-number-color);

  /* @include fontawesome-regular; */
  font: var(--fontawesome-regular);
  content: "\f14a";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  content: $fa-check-square;
  font-size: 30px;
}

.checklist__number
{
  margin-right: 15px;
  margin-left: 1px; /* look a little bit more aligned with checkboxes */
  flex: none;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 33px;
  height: 33px;
  border: 3px solid var(--widget-checklist-number-color);
  border-radius: 50%;

  color: var(--widget-checklist-number-color);
  font: var(--widget-checklist-number-font);
}

.checklist__item__content
{

}

.checklist__item__title
{
  color: var(--widget-checklist-item-color);
  font: var(--widget-checklist-item-font);
  margin-bottom: 3px;
}

.widget--checklist-unordered .checklist__item__title
{
  margin-top: -2px;
}

.widget--checklist-ordered .checklist__item__title
{
  margin-top: -4px;
}

.checklist__item__body > *
{
  /*
  // FIXME: of heeft WIG of FFW dit nog nodig?
  // color: #333333;
  // font: 15px/26px Arial;
  */
}
