html {
  --menubar-level1-openbyhover: true;
  --menubar-level1-font: 600 22px "TypoPRO Dosis Buttons",Dosis,Arial;
  --menubar-level1-padding-left: 12px;
  --menubar-level1-padding-right: 12px;
  --menubar-level1-spacing: 0px;
  --menubar-level1-spacing-min: 0px;
  --menubar-bar-item-color: var(--color-purple);
  --menubar-bar-item-decoration: none;
  --menubar-bar-item-hover-color: var(--color-green);
  --menubar-bar-item-hover-decoration: none;
  --menubar-bar-item-selected-color: var(--color-green);
  --menubar-bar-toggler-width: auto;
  --menubar-bar-toggler-margin: 6px;
  --menubar-bar-toggler-extrahitarea: 9px;
  --menubar-bar-toggler-shifty: 6px;
  --menubar-bar-toggler-font: var(--fontawesome-solid);
  --menubar-bar-toggler-size: 12px;
  --menubar-bar-toggler-content: var(--fa-chevron-down);
  --menubar-pulldown-zindex: 10;
  --menubar-pulldown-minwidth: 200px;
  --menubar-pulldown-maxwidth: 350px;
  --menubar-pulldown-border: none;
  --menubar-pulldown-border-radius: 0;
  --menubar-pulldown-padding: 15px 0 0;
  --menubar-pulldown-bgcolor: #FFFFFF;
  --menubar-pulldown-box-shadow: 0 9px 10px rgb(0 0 0 / 25%);
  --menubar-pulldown-itemhover-bgcolor: #F1F1F1;
  --menubar-pulldown-itemhover-textcolor: var(--color-purple);
  --menubar-pulldown-itemhover-textdecoration: none;
  --menubar-level2-color: var(--color-purple);
  --menubar-level2-font: normal 14px/20px Arial;
  --menubar-level2-padding-left: 15px;
  --menubar-level2-padding-right: 15px;
  --menubar-level2-padding-top: 10px;
  --menubar-level2-padding-bottom: 10px;
  --menubar-level2-expandicon: var(--fa-chevron-right);
  --menubar-level2-collapseicon: var(--fa-chevron-down);
  --menubar-level2-toggle-font: 900 13px "Font Awesome 5 Pro"; /* solid */
  --menubar-level3-color: var(--color-purple);
  --menubar-level3-font: normal 14px/20px Arial;
  --menubar-level3-padding-left: 30px;
  --menubar-level3-padding-right: 15px;
  --menubar-level3-padding-top: 10px;
  --menubar-level3-padding-bottom: 10px;
  --menubar-level3-expandicon: var(--fa-plus);
  --menubar-level3-collapseicon: var(--fa-minus);
  --menubar-level3-toggle-font: 400 13px "Font Awesome 5 Pro"; /* regular */
  --menubar-level4-color: var(--color-purple);
  --menubar-level4-font: normal 14px/20px Arial;
  --menubar-level4-padding-left: 35px;
  --menubar-level4-padding-right: 15px;
  --menubar-level4-padding-top: 10px;
  --menubar-level4-padding-bottom: 10px;
}

@media (max-width: 1200px) {
  html {
    --menubar-level1-font: 600 clamp(19px, 1.8vw, 22px) "TypoPRO Dosis Buttons",Dosis,Arial;
  }
}
.spc-menubar {
  z-index: 100;
}

.spc-menubar-branch--horizontal {
  height: 100%;
}

.spc-menubar-branch--horizontal > li > .spc-menubar__item {
  margin-top: 10px;
  height: calc(100% - 20px);
}

.spc-menubar__pulldown li + li {
  border-top: 1px solid #EEEEEE;
}

.spc-menubar > ul > li.spc-menubar__currentpagebranch > .spc-menubar__item > .spc-menubar__item__title {
  border-bottom: 2px solid #C0E5C0;
}