::backdrop {
  background-color: rgba(0, 0, 0, 0.3);
}

.spc-dialog__holder {
  display: none;
}

.spc-dialog__holder[open] {
  display: block;
}

@keyframes dialog-open {
  0% {
    transform: scale(0, 0);
  }
  100% {
    transform: scale(1, 1);
  }
}
/* Dialog positioning */
.spc-dialog__holder {
  /* for browsers without native <dialog> support we need to use pos:fixed; + z-index */
  position: fixed;
  z-index: 100; /* if we aren't a dialog we aren't on the top-layer so use z-index to get on top */
  left: 50%;
  transform: translateX(-50%);
  top: 30px;
  max-width: min(100% - 30px, 90vw, 970px);
  max-width: min(100% - 30px, 90vw, var(--pagegrid-contentarea-width));
  overflow: visible; /* so the closebutton can stick out of the popup */
  /*
    height: 100%;
    height: 100dvh; / * FIXME: otherwise the dialog gets centered causing part of the top to go out of view * /
    width: 100%;
    width: 100dvw;
  */
}

/* <dialog> comes with a ::backdrop, so check that to see if we support <dialog>.
   If so the margins can be used for positioning the dialog within the top-layer.

   <dialog> + ::backdrop
   - iOS     15.4 (mar 2022)
   - Chrome  37   (aug 2014)
   - Safari  15.4 (mar 2022)
   - Firefox 98   (mar 2022)

   @supports selector()
   - iOS     14.5 (apr 2021)
   - Chrome  83   (may 2020)
   - Safari  14.1 (apr 2022)
   - Firefox 69   (sep 2019)
*/
@supports selector(::backdrop) {
  .spc-dialog__holder {
    /* <dialog> defaults */
    margin: auto;
    left: 0;
    top: 0;
    transform: none;
  }
  .spc-dialog__holder {
    transform: scale(0, 0);
  }
  .spc-dialog__holder[open] {
    animation: dialog-open 0.25s cubic-bezier(0.22, 1, 0.36, 1) forwards;
  }
}
.spc-dialog__holder.spc-dialog--maxwidth {
  width: 100%;
}

/* 16:9 video - iOS10.3+ */
.spc-dialog__holder.spc-dialog--video {
  max-height: calc(100% - 60px);
  margin-top: 5px; /* compensate vertical alignment for close button sticking out */
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@supports selector(::backdrop) {
  .spc-dialog__holder.spc-dialog--video {
    margin: auto;
    left: 0;
    top: 0;
    transform: none;
  }
}

/*
16:9 video - iOS15+

.spc-dialog--video iframe
{
  aspect-ratio: 16 / 9; / * iOS 15+ * /
}
*/
/*
FIXME: we want to have the aspect ratio but be limited to both width and height to be in the viewport.
       Options
       - have some multiplyer logic which uses width or height (using min() ?)
       - media query for 16:9 which knows at which breakpoint to size based on viewport height instead of width
       - use of object-fit (is the browser smart enough to have the video dialog use it's aspect ratio and grow the dialog to the max we allow while keeping the aspect ratio?)
*/
.spc-dialog--video .spc-dialog {
  display: grid;
}

.spc-dialog--video .spc-dialog::before {
  content: "";
  padding-top: 56.25%;
}

.spc-dialog--video .spc-dialog::before,
.spc-dialog--video .spc-dialog iframe {
  grid-column: 1;
  grid-row: 1;
}

/*
16:9 video - legacy

.dialog--16x9 .dialog__body::before
{
  display: block;
  content: "";
  padding-top: 56.25%;
}


.dialog--16x9 .dialog__content > iframe
{
  display: block;
  width: 100%;
  height: 100%;
}
*/
/* Dialog theme */
.spc-dialog__holder {
  padding: var(--dialog-padding);
  background-color: var(--dialog-background);
  border: var(--dialog-border);
  border-radius: var(--dialog-border-radius);
  box-shadow: var(--dialog-box-shadow);
}

.spc-dialog__closebutton {
  position: absolute;
  z-index: 1;
  width: var(--dialog-closebutton-size, 31px);
  height: var(--dialog-closebutton-size, 31px);
  top: -12px;
  right: -12px;
  background-color: var(--dialog-closebutton-background);
  color: var(--dialog-closebutton-border);
  border: var(--dialog-closebutton-border);
  border-radius: 50%;
  transform: scale(1);
  transition: transform 0.3s cubic-bezier(0.22, 1, 0.36, 1), box-shadow 0.3s cubic-bezier(0.22, 1, 0.36, 1), background-color 0.3s cubic-bezier(0.22, 1, 0.36, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline-offset: 3px; /* for the focus outline */
}

.spc-dialog__closebutton:focus-visible {
  box-shadow: 0 0 3px 3px var(--color-purple);
  outline: 0;
  color: var(--color-purple);
}

.spc-dialog__closebutton::after {
  /*
  display: block;
  width: 11px;
  height: 11px;
  content: ""; /* url("../../web/img/close-black.svg");* /
  background: transparent url("../../web/img/close-black.svg") no-repeat scroll top left;
  background-size: cover;
  */
  content: var(--fa-times);
  font: var(--fontawesome-light);
  font-size: 20px;
}

.spc-dialog__closebutton:hover {
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0);
  background-color: var(--dialog-closebutton-hover-background);
  border: var(--dialog-closebutton-hover-border);
  color: var(--dialog-closebutton-hover-color);
  transform: scale(1.15);
  /*
    transition: transform  0.30s cubic-bezier(0.22, 1, 0.36, 1)
              , box-shadow 0.30s cubic-bezier(0.22, 1, 0.36, 1)
              , background-color 0.30s cubic-bezier(0.22, 1, 0.36, 1)
              ;*/ /* easeOutQuint: https://easings.net/#easeOutQuint */
}