
  .fa-twitter::before
, .sharecomponent__buttonbar .wh-share[data-network=twitter]::before
, .fa-twitter-square::before
{
  content: "𝕏" !important;
  font-size: 110%;
  font-weight: 700;
  font-family: Arial-black;
}


/* Colors */
html
{
  /* Blue */
  --vo-blue-dark:      #26568C;
  --vo-blue-bright:    #9DD7EA;
  --vo-blue-tint:      #E2F3F9; /* Used as background for highlights */
  --vo-blue-tint-border: #9CD6E9;
  --vo-blue-dark-transparent: #26568C70;

  --vo-blue-button-color: var(--vo-blue-dark);
  --vo-blue-button-hover-color: #1b3c62; /* erbij bedacht, share 30% */


  /* Brown */
  --po-brown:          #C1441A;
  --po-brown-transparent: #C1441A70;
  --po-brown-dark:     #9C4123;
  --po-yellow-bright:  #FEDB7C;
  --po-yellow-tint:    #FFF5DE;
  --po-yellow-tint-border: #D6AD93; /* Border around components which use the tint color as background */
  --po-yellow-homepanel: #FEDB7C;

  --po-brown-button-color: var(--po-brown);
  --po-brown-button-hover-color: var(--po-brown-dark);



  /* Purple */
  --mbo-purple-dark:   #57256D; /* wig */
  --mbo-purple-dark-transparent: #57256D70;
  --mbo-purple-bright: #CCB3CD;
  --mbo-purple-tint:   #F8F0FA; /* Used as background for highlights - wig */
  --mbo-purple-tint-border: #CBB3CC; /* Border around components which use the tint color as background */
  --mbo-purple-homepanel: #D4BED4;

  --mbo-purple-button-color: var(--mbo-purple-dark);
  --mbo-purple-button-hover-color: #3d1a4c;



  /*
  Old green colors
  --color-green-large:      #1E8527; // OLD (4.7:1 contrast to white)
  --color-green-small:      #218729; // 4.6:1 - WIG/GL/WVHG - For use with small fonts on white or a dark background with white text
  */

  /* Green */
  --wig-green-normal:  #13A538;
  --wig-green-dark:    #088638;
  --wig-green-dark-transparent: #08863870;
  --wig-green-bright:  #A1DBAF;
  --wig-green-tint:    #EEF9F1;
  --wig-green-tint-border: var(--wig-green-bright); /* FIXME: UNDEFINED in design */
  --wig-green-normal-transparent: #13A538B0;

  --wig-green-button-color: var(--wig-green-dark);
  --wig-green-button-hover-color: #0B923E;



  /* Legacy color set */
  --color-purple:  #641C76;
  --color-magenta: #C50D5A;
  --color-magenta-hover: #E00F66;

  /*
  Legacy (2017) colors
  --legacy-purple: #671e77; // WVHG used this purple in the past
  --legacy-yellow: #fec62e;
  */


  --text-black:        #333333;

  --grey-darker-border: #C4C4C4;




  /* Shared colors */
  --color-aagrey: #757575;


  /* Older colors

  old green? - #0db24c
  */
  --color-green-bright:  #25A22F; /* use as background ONLY in combination with white text with font-size 19px or larger */
  --color-green:  #1E8527;
  --color-green-transparent: rgba(30, 133, 39, 0.7);
  --color-green-heading: var(--color-green-bright);

  /* Green text on white background */
  --color-green-large:      #1E8527; /* 4.7:1 */
  --color-green-small:      #218729; /* 4.6:1 - WIG/GL/WVHG - For use with small fonts on white or a dark background with white text  */

  --color-green-background: #13A538; /* WIG menubar */
  --color-green-background-highcontrast: var(--wig-green-dark); /* #088638;  for footer (FFW & WIG - better contrast with small white text) */


  /* GL/WVHG & Filteredoverview teaserbar (WIG) */
  --color-theme-background: #13A538; /* 3.2:1 - or menubar, header quicklink items, button */
  --color-theme-background-highcontrast: #088638; /* for footer (better contrast with small white text) */

  --color-background-green-highcontrast:   var(--color-green-small); /* green background with high contrast (usually for non-large text or CTA uses) */

  --color-green-light-background:          #F1F9F1; /* lightgreen used as "Highlight" background */
  --color-green-large-on-light-background: var(--color-green);
  --color-green-small-on-light-background: #208328; /* 4.5:1 contrast on the lightgreen background */


  /* used for:
     - FFW submenu
     - FFW mainmenu pulldown item hover
     - FFW quote widget background
     - (generic) form infotext background
  */
  --color-sublegrey:           #F9F9F9;
  --color-green-small-on-subtlegrey: #208328; /* 4.6:1 als subtlegrey als achtergrondkleur */



  --color-error:           #EE0000; /* lightest accessible red, 4.53:1 contrast on white */
  --color-grey-border:     #929292; /* WCAG 3:1 contrastratio to white for UI elements (NOT text) */
  --color-grey-text-small: #767676; /* WCAG 4.53:1 contrastratio */
  --color-input-placeholder: var(--color-grey-text-small);
  --color-input-disabled-placeholder: var(--color-grey-text-small);
}


/* Components */
html
{
  --formcontrols-themecolor: var(--themecolor);

  --button-disabled-backgroundcolor: #929292;

  --actionbutton-outline-offset: 3px;

  --dialog-closebutton-size:              31px;
  --dialog-closebutton-background:        #FFFFFF;
  --dialog-closebutton-border:            2px solid #000000;
  --dialog-closebutton-hover-background:  var(--color-green);
  --dialog-closebutton-hover-border:      var(--color-green);
  --dialog-closebutton-hover-color:       #FFFFFF;

  --dialog-background:       #FFFFFF;
  --dialog-border:           1px solid var(--color-grey-border);
  --dialog-border-radius:    8px;
  --dialog-box-shadow:       0 3px 10px rgba(0, 0, 0, 0.35);
  --dialog-padding:          25px;
}


/* Widget defaults */
html
{
  --widget-quote-name-color: var(--color-green);
  --widget-quote-photo-size: 104px;

  --widget-highlight-background: var(--color-green-light-background);
  --widget-highlight-border-color: #28A532;
  --widget-highlight-textcolor: #333333;
  --widget-highlight-title-color: var(--color-green-large-on-light-background);
  --widget-highlight-pad-h: 20px;
  --widget-highlight-pad-v: 22px;

  --fsslideshow-text-color: #FFFFFF;
  --fsslideshow-icon-color: #FFFFFF;

  --widget-tableofcontents-background: #F1F9F1;
}

.widgettheme--green
{
  --widget-title-color: var(--wig-green-dark);
  --themecolor: var(--wig-green-dark);
}

.widgettheme--purple
{
  --widget-title-color: var(--mbo-purple-dark);
  --themecolor: var(--mbo-purple-dark);
}
