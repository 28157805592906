/* FIXME.. rename to --formcontrol-checkradio-iconfont */
:root {
  --font-awesome: "Font Awesome 5 Pro";
  --font-awesome-weight: 600;
  --font-awesome-size: 16px;
  --font-awesome-offsety: 1px;
  --formcontrol-checkradio-size: 25px;
  --formcontrol-checkradio-bordercolor: #929292;
  --formcontrol-checkradio-borderwidth: 1px;
  --formcontrol-checkradio-borderradius: 4px;
  --formcontrol-radio-innersize: 19px;
  --formcontrol-check-shift: 3px;
  --formcontrol-radio-shift: 0px;
  --formcontrol-checkradio-checked-color: #FFFFFF;
  --formcontrol-checkradio-checked-bgcolor: var(--formcontrols-themecolor);
  --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
  --formcontrol-checkradio-disabled-bgcolor: #F2F2F2;
}

.wbdvmtool,
.effecttool__root {
  --formcontrol-checkradio-size: 24px;
  --formcontrol-checkradio-bordercolor: var(--formcontrols-themecolor);
  --formcontrol-checkradio-borderwidth: 2px;
  --formcontrol-checkradio-borderradius: 2px;
  --formcontrol-radio-innersize: 16px;
}