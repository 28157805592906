.widget--tips ol
{
  margin: 0;
  padding: 0;
}

.widget--tips__item
{
  position: relative;
  border-top: 1px solid #641c76;
  padding-bottom: 20px; /* 50 for checklist, 20 for tips */

  list-style: none;
}

.widget--tips__number
{
  display: inline-block;

  background-color: #641c76;
  color: #FFFFFF;

  width: 52px;
  height: 28px;
  text-align: center;

  font: 16px/28px "TypoPRO Dosis", "Dosis", Arial;

  margin-bottom: 10px;
}

.widget--tips__title
{
  color: #641c76;
  margin-bottom: 15px;
}

.widget--checklist__body
{
}