html
{
  /************************************************************
   *
   *  Page components / Navigation path (crumpath)
   */

  --component-navpath-color:           #656565;
  --component-navpath-link-color:      #656565;
  --component-navpath-seperator-color: #656565;
  --component-navpath-font:            15px Arial;
  --component-navpath-gap-v:           4px;
  --component-navpath-prefix-margin:   10px; /* extra space between prefix and items */

  --component-navpath-item-padding-h: 5px;
  --component-navpath-item-padding-v: 5px;

  --component-navpath-seperator-content: "/";
}
