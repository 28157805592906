@charset "UTF-8";
/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
@import "./shared/rtd/rtd-shared.css";
@import "../shared/widgets/accordion/accordion.css";
@import "../shared/widgets/checklist/checklist.css";
@import "../shared/widgets/downloadsandlinks/downloadsandlinks.css";
@media (max-width: 767px) {
  html {
    /* part of content flow so the padding must be the same as normal content padding */
    --widget-padding-h: 15px;
    --widget-padding-v: 15px;
    --widget-padding-top: 15px;
    --widget-padding-bottom: 15px;
    --widgetsgrid-padding-h: 10px;
    --widgetsgrid-padding-v: 20px;
    --widgetsgrid-gutter-x: 15px;
    --widgetsgrid-gutter-y: 20px;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  html {
    --widget-padding-h: 15px;
    --widget-padding-v: 15px;
    --widget-padding-top: 15px;
    --widget-padding-bottom: 15px;
    --widgetsgrid-padding-h: 25px;
    --widgetsgrid-padding-v: 20px;
    --widgetsgrid-gutter-x: 15px;
    --widgetsgrid-gutter-y: 20px;
  }
}

.widget--tableofcontents .widget__title {
  font-size: 22px;
}

/************

Above the fold

************/
* {
  box-sizing: border-box;
}

main {
  display: block; /* [IEFIX] IE10/11 don't treat it as a block, making padding's disappear */
  outline: 0; /* disable Safari focus outline */
}

html {
  font: 14px/20px Arial, Sans-Serif;
  background-color: #ffffff;
  color: #000000;
}

html.wh-modal-popup-active {
  overflow: hidden;
}

body {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  text-size-adjust: none;
}

/**********************************************************
Layout page so that the footer is always pushed to
either the bottom of the viewport or the bottom of the page
**********************************************************/
html {
  overflow-y: scroll;
  padding: 0;
  margin: 0;
}

/*
Method to push footer to bottom on modern browsers.
(this doesn't work in IE10 but in IE11 and modern browsers)

body
{
  display: flex;
  flex-direction: column;

  padding: 0;
  margin: 0;
  min-height: 100vh;
}
*/
/* Method to push the footer to the bottom in
   modern browsers + IE10 and IE11
*/
html {
  height: 100%;
}

body {
  display: flex;
  height: 100%; /* We must use height instead of min-height for IE10 */
  padding: 0;
  margin: 0;
}

.body__content {
  display: flex;
  flex-direction: column;
  width: 100%; /* adjustment */
}

/* We must use height on <body> instead of min-height for IE10,
   however this will activate flex children shrinking to fit in this 100%.
   To counteract the shrinking we will set flex-shrink to 0, forcing <body> to grow to fit (and make the height act as min-height).
   (now content will act like normal <div>'s)
*/
.body__content > * {
  flex-shrink: 0;
}

/* take remaining space to push footer down the buttom.
   (otherwise we have to test for each type of (possible custom) pages to see if the footer is pushed down correctly)
*/
.site__footerspacer {
  flex: 1;
}

body {
  /* be a positioning container so #slidemenu-container
     can stretch to the full page size.
     (and it can act as clipping container to prevent the menu from
     stretching the <body> when it's animated outside the page)
  */
  position: relative;
}

.site__header {
  display: block;
}

.site__mobileheader {
  display: none;
}

#slidemenu-container {
  display: none;
} /* prevent screenreaders / keyboard users have both the desktop and mobile menu */
@media (max-width: 999px) {
  .site__header {
    display: none;
  }
  .site__mobileheader {
    display: block;
  }
  #slidemenu-container {
    display: block;
  }
}
/***********

Other

************/
.page__navigationpath {
  margin-top: 10px;
  margin-bottom: 30px;
  padding-left: var(--page-sidepadding);
  padding-right: var(--page-sidepadding);
}

main > *:first-child {
  margin-top: 30px;
}

main > .fgw__readspeaker:first-child {
  margin-top: 15px;
  margin-bottom: 25px;
}
@media print {
  main > .fgw__readspeaker:first-child {
    display: none;
  }
}

.page__widgetsgrid {
  margin-top: 48px;
}

.sharecomponent.sharecomponent {
  margin-top: 78px;
  padding-top: 0;
}

@media (max-width: 767px) {
  .page__widgetsgrid {
    margin-top: 30px;
  }
  .sharecomponent.sharecomponent {
    margin-top: 50px;
    padding-top: 0;
  }
}
.sharecomponent {
  padding: 0 var(--widget-padding-h);
}

.site__gobacklink-container {
  margin-top: 50px;
}

.sitefooter {
  margin-top: 90px;
}

.dialog__body {
  max-width: 960px !important; /* 1000px of desktop layout - 20px padding at both sides */
}

.fsslideshow__button > svg,
.fsslideshow__button > svg {
  fill: var(--color-green) !important;
}

.fsslideshow__button:hover > svg {
  fill: #FFFFFF !important;
}

.fsslideshow__title {
  color: var(--color-green) !important;
}

@media (max-width: 768px) {
  .content--centered {
    width: auto;
  }
}
@media (min-width: 1000px) {
  .content--centered {
    width: 100%;
    max-width: 1152px;
    margin-left: auto;
    margin-right: auto;
  }
}

.content--padh {
  padding-left: var(--page-sidepadding);
  padding-right: var(--page-sidepadding);
}

.content--pad {
  padding-left: var(--widget-padding-h);
  padding-right: var(--widget-padding-h);
  padding-top: var(--widget-padding-v);
  padding-bottom: var(--widget-padding-v);
}

@media (max-width: 768px) {
  .content__widgetscolumn__content {
    padding-top: var(--widgetsgrid-padding-v);
    padding-bottom: var(--widgetsgrid-padding-v);
  }
}
@media (min-width: 768px) {
  .content__widgetscolumn__content.content--padh {
    padding-top: 10px;
  }
  .content--wide,
  .content--twocolumn {
    flex-shrink: 0;
  }
  .content--twocolumn {
    display: flex;
  }
  .content__contentcolumn {
    /* consume available space */
    width: 0; /* [IEFIX] makes IE shrink down to what room is available instead of what it would need to not have to wrap text */
    flex-grow: 1;
  }
  .content__widgetscolumn {
    /* fixed 38% width */
    width: 384px;
    max-width: 38%;
    flex-shrink: 0;
  }
}
/* 'FGM'-site specific *************************************/
.content__widgetscolumn__ontop {
  position: relative;
  z-index: 1;
}

.content__widgetscolumn--opaquebg {
  background-color: #e6e6e6;
}

@media (min-width: 768px) {
  .content__contentcolumn .page__widgetsgrid {
    margin-right: 20px;
  }
  .content__widgetscolumn__content {
    position: relative;
    padding: 0 20px 20px 20px;
  }
  html.site--pageheader-default .content__widgetscolumn__content,
  html.site--pageheader-default .content__widgetscolumn__contentshift {
    position: relative;
    margin-top: -115px;
  }
  html.site--pageheader-small .content__widgetscolumn__content,
  html.site--pageheader-small .content__widgetscolumn__contentshift {
    position: relative;
    margin-top: -65px;
  }
}
/***********************************************************/
.site__gobacklink-container {
  padding-top: 10px;
  border-top: 1px solid #dcdcdc;
}

.site__gobacklink {
  display: inline-block;
  text-decoration: none;
}

a.site__gobacklink > * {
  vertical-align: middle;
}

.site__gobacklink__label {
  text-decoration: underline;
}

.site__gobacklink::before {
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  font: normal normal normal 16px/1 "Font Awesome 5 Pro";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  margin-right: 5px;
  content: "\f104";
  content: "\f104"/"";
}

@media print {
  .site__gobacklink-container {
    display: none;
  }
}
@media print {
  @page {
    size: A4;
    margin: 0;
  }
  html {
    width: 210mm;
    height: 297mm;
    margin: 2em;
  }
  .content__widgetscolumn {
    display: none;
  }
}
/* give anything that popups up over the webpage z-index 100
   DOM order will take care of the rest
*/
.wh-modalitylayer,
.wh-popup-container {
  z-index: 100;
}

/* Modality layer */
@media (max-width: 767px) {
  html.site--modality--mobilemenu::after {
    display: block;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    border: 0;
    margin: 0;
    width: 100%;
    height: 100%;
  }
}
.linkedin-icon {
  display: inline-block;
  background-color: #0084bf;
  color: #FFFFFF;
  text-align: center;
  line-height: 34px;
  font-size: 20px;
  font-weight: bold;
  text-decoration: none;
  width: 34px;
}

.widget--submenu br.rs_pause {
  display: none;
}

/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
.site__header {
  position: relative;
  background-color: #FFFFFF;
  border-bottom: 1px solid #d3d3d3;
}
@media print {
  .site__header {
    display: none;
  }
}

.site__header__content {
  /* horizontal layout */
  display: flex;
  padding-top: 15px;
  padding-bottom: 15px;
}

.site__logo {
  display: block;
  /* reserve space */
  position: relative;
  margin-top: auto;
  /* or align-self: bottom; */
  flex-shrink: 0;
}

.site__logo img {
  width: 290px;
  border: 0; /* [IEFIX] ie10 places borders around images in an achor */
}

.site__header__navigation {
  /* vertical layout */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.site__header__searchcontrol {
  width: auto;
  margin-left: auto;
  margin-bottom: 25px;
  height: 35px;
  padding-left: 50px;
  align-self: flex-end;
  width: 100%;
  display: flex;
}

.site__header__searchcontrol > .searchcontrol__input {
  width: 225px;
  width: 0;
  flex: 1 0 auto;
  height: 34px;
  font-size: 14px;
  padding-left: 8px;
  padding-right: 8px;
  border: 1px solid var(--color-grey-border);
  border-right-width: 0;
  border-radius: 0;
}

.searchcontrol__submit {
  width: 34px;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  background: var(--color-green);
  color: #FFFFFF;
}

.searchcontrol__submit .fa-search {
  font-size: 16px;
}

.site__header__linkedin {
  margin-left: 11px;
}

.site__header__linkedin:hover {
  background-color: #0073b1;
}

/* Mainmenu 1st level (menubar) */
.site__header__menu {
  z-index: 100;
  margin-top: auto;
  display: inline-block;
  margin-left: auto;
  color: #641c76;
}

.site__header__menuitems {
  margin: 0;
  margin-right: -15px; /* allow padding of last menuitem outside header content area */
  padding: 0;
  list-style: none;
}

.site__header__menuitems > li {
  position: relative;
  display: inline-block;
}

.site__header__menuitems .selected > a {
  color: var(--color-green);
}

.site__header__menuitems > li > a {
  text-decoration: none;
  color: inherit;
  font: 500 24px "TypoPRO Dosis", "Dosis", Arial;
  padding-left: 15px;
  padding-right: 15px;
}

.site__header__menuitems > li > a:hover {
  color: var(--color-green);
}

.site__header__menuitems > li > ul li a {
  text-decoration: none;
  color: inherit;
  font: normal 14px/20px Arial;
}

/* Mainmenu 2nd level (dropdown) */
.site__header__menuitems > li > ul {
  display: none;
  position: absolute;
  z-index: 2;
  left: 0;
  margin: 0;
  padding: 0;
  list-style: none;
  padding-top: 15px;
  /*
  }
  .site__header__menuitems > li > ul > .visiblecontainer
  {
  */
  background-color: #FFFFFF;
  box-shadow: 0px 9px 10px rgba(0, 0, 0, 0.24);
  min-width: 200px;
  width: 350px; /* IE */
  width: -webkit-max-content; /* allow abs.container to stretch to fit content, without disabling wrapping */
  width: -moz-max-content;
  max-width: 350px;
  border-radius: 0;
}

.site__header__menuitems > li:hover > ul {
  display: block;
}

.site__header__menuitems > li > ul li {
  font-weight: normal;
  margin: 0;
  padding: 0;
}

.site__header__menuitems ul.depth2 > li a {
  display: block;
  padding: 10px 15px 10px 15px;
}

/*
.site__header__menuitems ul.depth3 > li a
{
  display: block;
  padding: 7px 15px 7px 15px;
}
*/
.site__header__menuitems > li > ul li a:hover {
  background-color: var(--color-sublegrey);
}

.site__header__menuitems > li > ul > li + li {
  border-top: 1px solid #EEEEEE;
}

/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
@media (max-width: 767px) {
  .content__widgetscolumn {
    margin-top: 40px;
  }
  .content__widgetscolumn__content .widget--submenu {
    display: none;
  }
  .content__widgetscolumn__content .widget--submenu + * {
    margin-top: 0 !important;
  }
}
.wigsubmenu {
  display: none;
}

.wigsubmenu.wh-toggledelement--active {
  display: block;
}

/*
Fix the .rs_pause items creating unwanted whitespace
*/
.wigmenu--level1 {
  display: flex;
  flex-wrap: wrap;
}

.wigmenu--level1 > .wigmenu__item {
  flex: 1 0 100%;
}

.wigmenu--level1 > .rs_pause {
  position: absolute;
}

.wigmenu--level2 {
  padding: 5px 0 16px 0;
}

.site__submenu__menuitems {
  margin: 0;
  padding: 0;
}

.site__submenu__header {
  color: #FFFFFF !important;
}

.site__submenu__menuitems .wigmenu__item {
  text-decoration: none;
  color: #641c76;
}

.site__submenu__header,
.wigmenu--level1 {
  font: normal 21px "TypoPRO Dosis Menu", "Dosis", Arial;
}

.wigmenu--level1 {
  background-color: #FFFFFF;
}

.wigmenu--level2,
.wigmenu--level3,
.wigmenu--level4,
.wigmenu--level5 {
  background-color: var(--color-sublegrey);
  font: normal 14px Arial;
}

.wigmenu__item.selected {
  color: var(--color-green-small-on-subtlegrey);
}

.wigmenu--level2 > a.wigmenu__item:hover {
  background-color: #F0F0F0;
}

.wigmenu--level2 > a.wigmenu__item:hover > span {
  text-decoration: underline;
}

.wigmenu--level3 > .wigmenu__item.selected > span {
  text-decoration: underline;
}

.site__submenu__menuitems,
.site__submenu__menuitems ul {
  list-style: none;
}

/*.site__submenu__menuitems > li*/
.site__submenu__header,
.site__submenu__menuitems .wigmenu__item {
  /* vertical layout */
  display: flex;
  align-items: center;
}

.wigmenu__item {
  cursor: pointer;
}

.site__submenu__menuitems .wigmenu__item > span,
.site__submenu__header > span {
  display: inline-block; /* [IEFIX] IE10 ignored flex-grow on inline elements */
  flex-grow: 1;
}

.wigmenu--level1 .wigmenu__item span,
.site__submenu__header {
  font: normal 21px "TypoPRO Dosis", "Dosis", Arial;
  /*height: 43px;*/
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  font-size: 21px;
}

.site__submenu__menuitems .wigmenu--level1 .wigmenu__item:last-child span {
  padding-bottom: 11px;
}

.site__submenu__menuitems .wigmenu--level1 .wigmenu__item {
  min-height: 48px;
  border-bottom: 1px solid #eeeeee;
}

.site__submenu__menuitems .wigmenu--level1 + .wigmenu--level2 span {
  padding-top: 7px;
  padding-bottom: 7px;
}

.site__submenu__menuitems .wigmenu--level2 + .wigmenu--level1 {
  border-top: 1px solid #eeeeee;
}

.site__submenu__menuitems .wigmenu--level3 {
  /*  margin-top: -8px;*/
}

.site__submenu__menuitems .wigmenu--level2 .wigmenu__item span {
  padding-top: 7px;
  padding-bottom: 7px;
}

.site__submenu__menuitems .wigmenu--level3 .wigmenu__item span,
.site__submenu__menuitems .wigmenu--level4 .wigmenu__item span,
.site__submenu__menuitems .wigmenu--level5 .wigmenu__item span {
  padding-top: 6px;
  padding-bottom: 6px;
}

.site__submenu__menuitems .wigmenu--level2 .wigmenu__item,
.site__submenu__menuitems .wigmenu--level3 .wigmenu__item,
.site__submenu__menuitems .wigmenu--level4 .wigmenu__item,
.site__submenu__menuitems .wigmenu--level5 .wigmenu__item {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
}

.site__submenu__menuitems .wigmenu--level2 .wigmenu__item--hassubitems,
.site__submenu__menuitems .wigmenu--level3 .wigmenu__item--hassubitems,
.site__submenu__menuitems .wigmenu--level4 .wigmenu__item--hassubitems,
.site__submenu__menuitems .wigmenu--level5 .wigmenu__item--hassubitems {
  padding-right: 0;
}

.site__submenu__menuitems .wigmenu--level3 .wigmenu__item {
  margin-left: 15px;
}

.site__submenu__menuitems .wigmenu--level4 .wigmenu__item {
  margin-left: 30px;
}

.site__submenu__menuitems .wigmenu--level5 .wigmenu__item {
  margin-left: 45px;
}

.site__submenu__header {
  background-color: var(--color-green);
  color: #FFFFFF;
  height: 48px;
  margin: 0; /* cancel h2 margins */
}

.site__submenu__menuitems .wh-toggler-button {
  flex: none;
}

.site__submenu__menuitems .wh-toggler-button::after,
.site__submenu__menuitems .wigmenu--level2 > .wigmenu__item::before,
.site__submenu__menuitems .wigmenu--level3 > .wigmenu__item::before,
.site__submenu__menuitems .wigmenu--level4 > .wigmenu__item::before,
.site__submenu__menuitems .wigmenu--level5 > .wigmenu__item::before {
  display: inline-block;
  font: normal normal normal 14px/1 "Font Awesome 5 Pro";
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
  flex-shrink: 0;
}

.wh-toggler-button {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  cursor: pointer;
}

/* the 'hassubitems' arrow at the top level */
.site__submenu__menuitems .wh-toggler-button::after {
  color: #641c76;
  font-size: 17px;
  margin-top: 1px;
  content: "\f078"; /* .fa-chevron-down */
  content: "\f078"/"";
}

.wigmenu__item.wigmenu__item--hassubitems.expanded > .wh-toggler-button::after {
  margin-top: 3px;
  content: "\f077"; /* .fa-chevron-down */
  content: "\f077"/"";
}

.site__submenu__menuitems .wigmenu--level2 > .wigmenu__item::before {
  font-size: 75%;
  content: "\f054";
  content: "\f054"/"";
}

/* level3 and level4 get a bullet */
.site__submenu__menuitems .wigmenu--level3 > .wigmenu__item::before,
.site__submenu__menuitems .wigmenu--level4 > .wigmenu__item::before,
.site__submenu__menuitems .wigmenu--level5 > .wigmenu__item::before {
  font-size: 50%;
  content: "\f111";
  content: "\f111"/"";
  /* content: "\f055"; plus-circle */
}

.site__submenu__menuitems .wigmenu--level2 > .wigmenu__item::before,
.site__submenu__menuitems .wigmenu--level3 > .wigmenu__item::before,
.site__submenu__menuitems .wigmenu--level4 > .wigmenu__item::before,
.site__submenu__menuitems .wigmenu--level5 > .wigmenu__item::before {
  margin-top: 1px;
  margin-right: 5px;
}

/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
@media print {
  .site__mobileheader {
    display: none;
  }
}

button#mobileheader__togglemenu {
  background: none;
  padding: 0;
  border: 0;
}

.site__mobileheader__content {
  display: flex;
  align-items: flex-start;
  position: relative;
  height: 63px;
  padding-top: 10px;
  padding-bottom: 13px;
  background-color: #FFFFFF; /* plain white, whe'll scroll it away with the page */
}

.mobileheader__logo {
  margin: 0 auto;
}

.mobileheader__logo > img {
  width: 160px;
}

#mobileheader__togglemenu,
#mobileheader__togglesearch {
  cursor: pointer;
}

#mobileheader__togglemenu > .fa,
#mobileheader__togglesearch > .fa {
  font-size: 30px;
  color: var(--color-green);
}

.slidemenu__linkedinbar {
  display: flex;
  align-items: center;
  font-size: 21px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 54px;
  text-decoration: none;
  font: normal 21px "TypoPRO Dosis", "Dosis", Arial;
  /*height: 43px;*/
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  font-size: 21px;
  color: #641c76;
}

.slidemenu__linkedinbar__icon {
  margin-right: 11px;
}

/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
/* content size must be max(content-height, (body)height, 100vh)
   Force a second min-height using a before
*/
/*
  #slidemenu-container::before
, #slidemenu::before
{
  content: "";
  display: block;
  height: 100%;
  float: left;
  width: 0;
}
*/
#slidemenu-container {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  /*  overflow-x: hidden;*/
  height: 100vh;
}

/*
Menu (which can be toggled) for mobile & tablet (portrait orientation)
*/
#slidemenu {
  position: fixed;
  width: 80%;
  max-width: 300px;
  height: 100vh;
  background-color: #FFFFFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  /* Don't take space when inactive
     If we use display: none; we cannot use transitions.
     (or we need more js code and might get a flicker on mobile devices)
  height: 0;
  overflow: hidden;
  */
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  /* 100% + box-shadow size */
  /*  transform-origin: top right;*/
  transition: transform 0.4s;
}

.slidemenu--fromtheleft {
  left: 0;
  transform: translateX(calc(-100% - 5px));
}

.slidemenu--fromtheright {
  right: 0;
  transform: translateX(calc(100% + 5px));
}

/* IE10 fallback */
html.no-pointerevents #site-search-panel {
  z-index: -1;
}

#slidemenu:focus {
  outline: none;
}

#slidemenu.active {
  /*  height: auto; */
  overflow: auto;
  pointer-events: auto;
  z-index: 1;
  transform: translateX(0%) !important;
}

#slidemenu-header {
  display: flex;
  align-items: center;
  /*
  height: 77px;
  line-height: 77px;
  */
  text-align: left;
  border-bottom: 1px solid #464646;
  padding: 6px 20px 11px 20px;
  margin: 10px 0 0 0;
}

#slidemenu-close {
  border: 0;
  background: none;
  margin-left: 20px;
  margin-right: 15px;
  width: 18px;
  height: 18px;
  background-image: url("@mod-minfin_wig/webdesigns/mijnwerknemerengeldzaken/web/img/close-white.svg");
  background-size: cover;
  cursor: pointer;
}

/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
.page__header {
  position: relative;
  z-index: 1; /* so we can place the right column bg below and menu/widgets above the page__header */
  background-size: cover;
  display: flex;
  flex-direction: column;
  /* MUST grow to fit large titles */
  flex-shrink: 0;
  background-color: #e6e6e6;
  padding-bottom: 20px;
  padding-top: 0;
}
@media (max-width: 767px) {
  .page__header {
    padding-bottom: 9px;
  }
}
@media (max-width: 768px) {
  .page__header {
    padding-bottom: 20px;
  }
}
@media (min-width: 1000px) {
  .page__header {
    padding-bottom: 20px;
  }
}
@media print {
  .page__header {
    display: none;
  }
}

.page__header--withdate {
  padding-bottom: 10px;
}
@media (max-width: 767px) {
  .page__header--withdate {
    padding-bottom: 8px;
  }
}
@media (max-width: 768px) {
  .page__header--withdate {
    padding-bottom: 10px;
  }
}
@media (min-width: 1000px) {
  .page__header--withdate {
    padding-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .page__header--small {
    min-height: 192px;
  }
  .page__header--default {
    min-height: 192px;
  }
}
@media (min-width: 768px) {
  .page__header--small {
    min-height: 124px;
  }
  .page__header--default {
    min-height: 324px;
  }
}
.page__header .content__widgetscolumn {
  background-color: transparent;
}

.page__header__descriptioncontainer {
  margin-top: auto; /* push to bottom of flex container */
}

.page__header__description {
  color: #FFFFFF;
  font: 600 48px "TypoPRO Dosis", "Dosis";
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.77);
  margin: 0; /* because it's used as <h1> */
}

.page__header__when {
  margin-top: 14px;
  display: flex;
  align-items: center;
  font: 500 16px var(--rtd-heading-fontfamily);
  color: #FFFFFF;
}

.page__header__when::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  font-size: 16px;
  content: "\f017";
  content: "\f017"/"";
  margin-right: 10px;
}

@media (max-width: 767px) {
  .page__header__description {
    font-size: 36px;
  }
  .page--home .page__header {
    justify-content: center;
  }
  .page--home .page__header__descriptioncontainer {
    margin: 0 auto 15px auto;
  }
}
/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
.site__printheader {
  display: none;
}

.site__printheader__logo {
  display: block;
  margin: 0 auto;
  height: 83px;
}

.site__printheader__title {
  padding-left: var(--page-sidepadding);
  padding-right: var(--page-sidepadding);
}

@media print {
  @page {
    margin: 15mm;
  }
  .site__printheader {
    display: block;
  }
  .widget--extrainfo, .widget--checklist__item {
    page-break-inside: avoid;
  }
}
/* FIXME.. rename to --formcontrol-checkradio-iconfont */
:root {
  --font-awesome: "Font Awesome 5 Pro";
  --font-awesome-weight: 600;
  --font-awesome-size: 16px;
  --font-awesome-offsety: 1px;
  --formcontrol-checkradio-size: 22px;
  --formcontrol-checkradio-bordercolor: #929292;
  --formcontrol-checkradio-borderwidth: 1px;
  --formcontrol-checkradio-borderradius: 1px;
  --formcontrol-radio-innersize: 14px;
  --formcontrol-check-shift: 3px;
  --formcontrol-radio-shift: 0px;
  --formcontrol-checkradio-checked-color: var(--formcontrols-themecolor);
  --formcontrol-checkradio-checked-bgcolor: #FFFFFF;
  --formcontrol-checkradio-checked-bordercolor: var(--formcontrols-themecolor);
  --formcontrol-checkradio-disabled-bgcolor: #F2F2F2;
}

input[type=radio] + * {
  --formcontrol-checkradio-checked-bgcolor: var(--formcontrols-themecolor);
}

:root {
  --checkbox-switch-borderwidth: 3px;
  --checkbox-switch-track-width: 51px;
  --checkbox-switch-track-height: 30px;
  --checkbox-switch-track-color: #A4A4A4;
  --checkbox-switch-track-color-disabled: #A4A4A4;
  --checkbox-switch-track-color-active: var(--formcontrols-themecolor);
  --checkbox-switch-thumb-size: 18px;
  --checkbox-switch-thumb-color: #FFFFFF;
}

input[type=checkbox].spc-toggle {
  position: absolute !important;
  width: 0;
  height: 0;
  overflow: hidden;
  -webkit-appearance: none;
  -moz-appearance: none;
  opacity: 0;
  margin: 0;
}

input[type=checkbox].spc-toggle + label + label {
  vertical-align: middle;
}

input[type=checkbox].spc-toggle + label {
  flex: none;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
  width: var(--checkbox-switch-track-width);
  min-width: var(--checkbox-switch-track-width);
  height: var(--checkbox-switch-track-height);
  border-radius: calc(var(--checkbox-switch-track-height) / 2);
  background-color: var(--checkbox-switch-track-color);
  border: var(--checkbox-switch-borderwidth) solid var(--checkbox-switch-track-color);
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

html[data-whatinput=keyboard] input[type=checkbox].spc-toggle:focus + label,
input[type=checkbox].spc-toggle:focus-visible + label {
  outline: 2px solid var(--checkbox-switch-track-color-active);
  outline: -webkit-focus-ring-color auto 5px;
  outline-offset: 4px;
}

input[type=checkbox].spc-toggle + label::before {
  content: "";
  position: absolute;
  height: var(--checkbox-switch-thumb-size);
  left: 1px;
  width: var(--checkbox-switch-thumb-size);
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-transition: left 0.2s, background-color 0.2s, border-color 0.2s; /* iOS 6 */
  transition: left 0.2s, background-color 0.2s, border-color 0.2s;
}

/* OFF */
input[type=checkbox].spc-toggle.spc-toggle + label:hover {
  border-color: var(--checkbox-switch-track-color);
}

/* ON */
input[type=checkbox].spc-toggle.spc-toggle:checked + label {
  background-color: var(--checkbox-switch-track-color-active);
  border-color: var(--checkbox-switch-track-color-active);
}

/* Disabled */
input[type=checkbox].spc-toggle.spc-toggle[disabled] + label {
  background-color: var(--checkbox-switch-track-color-disabled);
  border-color: var(--checkbox-switch-track-color-disabled);
}

input[type=checkbox].spc-toggle:checked + label::before {
  left: calc(100% - var(--checkbox-switch-thumb-size) - 1px);
}

input[type=checkbox].spc-toggle + label::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
  content: "\f00c";
  font-size: 11px;
  color: #FFFFFF;
  position: absolute;
  top: 0;
  left: 5px;
  line-height: calc(var(--checkbox-switch-track-height) - var(--checkbox-switch-borderwidth) * 2 + 1px);
}

.sharecomponent__buttonbar {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  row-gap: 18px;
}

.sharecomponent__buttonbar .wh-share {
  appearance: none;
  border: 0;
  margin: 0;
  background: none;
  width: 37px;
  height: 37px;
  width: var(--component-sharepage-button-size, 37px);
  height: var(--component-sharepage-button-size, 37px);
  background-color: #FFFFFF;
  background: var(--component-sharepage-button-background, #FFFFFF);
  color: var(--component-sharepage-button-color);
  border: 1px solid var(--component-sharepage-button-color);
  border-radius: 8px;
  cursor: pointer;
}

.sharecomponent__buttonbar > .wh-share + .wh-share {
  margin-left: var(--component-sharepage-button-spacing, 13px);
}

.sharecomponent__buttonbar .wh-share:hover {
  background-color: var(--component-sharepage-button-color);
  background-image: none;
  color: #FFFFFF;
}

.sharecomponent__buttonbar .wh-share[data-network=twitter]::before {
  content: "𝕏" !important;
  font-size: 110%;
  font-weight: 700;
  font-family: Arial-black;
}

.sharecomponent__buttonbar .wh-share[data-network=facebook]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-facebook-f);
}

.sharecomponent__buttonbar .wh-share[data-network=linkedin]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-linkedin-in);
}

.sharecomponent__buttonbar .wh-share[data-network=whatsapp]::before {
  font: var(--fontawesome-brands);
  content: var(--fa-whatsapp);
}

.sharecomponent__buttonbar .wh-share[data-network=email]::before,
.sharecomponent__buttonbar .wh-share.siteaction--emailpagelink::before {
  font: var(--fontawesome-regular);
  content: var(--fa-envelope);
}

.sharecomponent__buttonbar .wh-share[data-network=print]::before {
  font: var(--fontawesome-regular);
  content: var(--fa-print);
  font-size: 19px;
  font-size: var(--component-sharepage-button-symbolsize, 19px);
}

.sharecomponent__buttonbar .wh-share.wh-share::before {
  font-size: 20px;
  font-size: var(--component-sharepage-button-symbolsize, 20px);
}

.sharecomponent {
  margin-top: 60px;
  padding-top: 35px;
}

.contentwithin_wide .sharecomponent {
  width: max-content;
  margin-left: auto;
  margin-right: auto;
}
.contentwithin_wide .sharecomponent--bothactions {
  width: auto;
}
.contentwithin_wide .sharecomponent:not(.sharecomponent--bothactions) .sharecomponent__buttonbar {
  justify-content: center;
}

.content__contentcolumn .sharecomponent {
  padding-left: 0;
}

.sharecomponent__title {
  color: var(--component-sharepage-heading-color);
  font: var(--component-sharepage-heading-font);
  margin-bottom: 14px;
}

h2.sharecomponent__title {
  margin-top: 0;
}

a.shareactionsbar__downloadpdflink {
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  height: var(--button-height);
  padding: 5px 20px 5px 20px;
  font: var(--button-font);
  border-radius: var(--button-borderradius);
  cursor: pointer;
  background-color: #FFFFFF;
  border: 1px solid var(--button-color);
  color: var(--button-color);
  background: var(--component-sharepage-button-background, #FFFFFF);
}
a.shareactionsbar__downloadpdflink:hover {
  background-image: none;
  background-color: var(--button-color-hover);
  color: #FFFFFF;
}

a.shareactionsbar__downloadpdflink .far {
  font-size: 20px;
  margin-right: 15px;
}

.sharecomponent__buttonbar__filler {
  flex: 1 0 0px;
}

.wh-share[data-network=print] {
  margin-right: 30px;
}

.pagination_navbar {
  display: flex;
  align-items: center;
  margin-top: 22px;
  border-top: 1px solid #C5C5C5;
  padding-top: 25px;
}

.pagination_navbar__left,
.pagination_navbar__right {
  flex: 0 0 auto;
  min-width: 150px;
}

.pagination_navbar__right {
  text-align: right;
}

.pagination_navbar__items {
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
}

.pagination_navbar__items > a {
  display: block;
  width: 30px;
  height: 30px;
  color: var(--pagination-pagebutton-color);
  padding-right: 1px;
  padding-bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font: var(--pagination-font);
  text-decoration: none;
}

.pagination_navbar__items > a.selected,
.pagination_navbar__items > a:hover {
  text-decoration: none !important;
  background-color: var(--pagination-themecolor);
  border-radius: 50%;
  color: #FFFFFF;
}

.pagination_navbar__items > * + * {
  margin-left: 11px;
}

.pagination_navbar__previous,
.pagination_navbar__next {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  font: var(--pagination-font);
  text-decoration: none;
}

.pagination_navbar__previous {
  margin-right: 35px;
  color: var(--pagination-themecolor);
}

.pagination_navbar__previous__label {
  margin-left: 16px;
}

.pagination_navbar__next {
  margin-left: 35px;
  color: var(--pagination-themecolor);
}

.pagination_navbar__next__label {
  margin-right: 16px;
}

@media (max-width: 640px) {
  .pagination_navbar {
    justify-content: space-between;
  }
  .pagination_navbar__previous__label,
  .pagination_navbar__next__label {
    display: none;
  }
  .pagination_navbar__previous {
    margin-right: 15px;
  }
  .pagination_navbar__next {
    margin-left: 15px;
  }
}
button.skiplinks {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 100;
  opacity: 0;
  pointer-events: none;
}

button.skiplinks:focus {
  opacity: 1;
  pointer-events: auto;
  cursor: pointer;
}

/*
main
{
  scroll-margin-top: 15px; // keep space for fixed or sticky header floating over the content
}

main:focus
{
  outline: 0;
}
*/
.widget--widgetsgroup {
  position: relative;
  background-color: #e6e6e6;
  box-shadow: inset 0 0 7px rgba(0, 0, 0, 0.14);
  padding: var(--widgetsgrid-padding-v) var(--widgetsgrid-padding-h);
}
@media (max-width: 767px) {
  .widget--widgetsgroup .widget--news__items {
    display: block;
  }
  .widget--widgetsgroup .widget--news__firstitem .widget--news__item__image {
    display: none;
  }
  .widget--widgetsgroup .widget--news__moreitems {
    margin-top: 20px;
  }
}

@media (min-width: 768px) {
  .content__contentcolumn .widget--widgetsgroup--masonry .widgetsgrid {
    --widgetsgrid-maxcolumns: 2;
  }
}

/*
Note: We currently cannot use grid if there is content which need to span multiple columns.
      This is because we cannot set a column span without specifying a start position.

.widget--widgetsgroup--equalheight .widgetsgrid
{
  display: grid;
  grid-template-columns: minmax(0,1fr) minmax(0,1fr) minmax(0,1fr);
  grid-column-gap: var(--widgetsgrid-gutter-x);
  grid-row-gap: var(--widgetsgrid-gutter-y);
}

.widget--widgetsgroup--equalheight .widgetsgrid > *[data-cols="2"]
{
  column-span: 2;
}
*/
/*

FIXME: modernize using the --widgetsgrid-columns + --widgetsgrid--maxcolumns approach.
       This simplifies things and allows us for sites to override where there are different amount of max columns.

*/
.widgetsgrid {
  position: relative; /* needed for masonry item positioning, but also use for equalheight view to keep behaviour the same */
}

.widget--widgetsgroup--equalheight.widget--widgetsgroup--cols2 {
  --widgetsgrid-item-width: calc(50% - var(--widgetsgrid-gutter-x, 20px) / 2);
  --widgetsgrid-item-width-2col: 100%;
}

.widget--widgetsgroup--equalheight.widget--widgetsgroup--cols3 {
  --widgetsgrid-item-width: calc(33.33% - var(--widgetsgrid-gutter-x, 20px) * 2 / 3);
  --widgetsgrid-item-width-2col: calc(var(--widgetsgrid-item-width) * 2 + var(--widgetsgrid-gutter-x));
}

.widget--widgetsgroup--equalheight.widget--widgetsgroup--cols4 {
  --widgetsgrid-item-width: calc(25% - var(--widgetsgrid-gutter-x, 20px) * 3 / 4);
  --widgetsgrid-item-width-2col: calc(var(--widgetsgrid-item-width) * 2 + var(--widgetsgrid-gutter-x));
}

/*
$responsive-widgetgrid-1col:   375px;
$responsive-widgetgrid-2col:   768px; / * iPad portrait mode * /
$responsive-widgetgrid-3col:  1000px; / * iPad landscape mode with 12px padding outside page content, 30px padding inside centered content * /
$responsive-widgetgrid-4col:  1200px; / * desktop * /
*/
/* If not enough width for 4 columns:  downgrade 4 col grids to 3 */
@media (max-width: 1199px) {
  /* When we get below 4col, revert the 4col mode to 3col */
  .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols4 {
    --widgetsgrid-item-width: calc(33.33% - var(--widgetsgrid-gutter-x, 20px));
    --widgetsgrid-item-width-2col: calc(66.66% - var(--widgetsgrid-gutter-x, 20px));
  }
}
@media (max-width: 999px) {
  /* When we get below 4col, revert the 4col and 3col mode to 2col */
  .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols4,
  .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols3 {
    --widgetsgrid-item-width: calc(50% - var(--widgetsgrid-gutter-x, 20px));
    --widgetsgrid-item-width-2col: 100%;
  }
}
.site--ffw .content__contentcolumn .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols4,
.site--ffw .content__contentcolumn .widget--widgetsgroup--equalheight.widget--widgetsgroup--cols3 {
  --widgetsgrid-item-width: calc(50% - var(--widgetsgrid-gutter-x, 20px) / 2);
  --widgetsgrid-item-width-2col: 100%;
}

/* Single column, just stack items */
@media (max-width: 768px) {
  .widget--widgetsgroup--equalheight .widgetsgrid .widget + .widget {
    margin-top: var(--widgetsgrid-gutter-y, 20px);
  }
}
/* Grid */
@media (min-width: 768px) {
  .widget--widgetsgroup--equalheight .widgetsgrid {
    display: flex;
    flex-flow: row wrap;
    column-gap: var(--widgetsgrid-gutter-x);
    row-gap: var(--widgetsgrid-gutter-y);
    width: 100%;
  }
  .widget--widgetsgroup--equalheight .widgetsgrid > .widget {
    position: relative;
    flex-basis: auto;
    width: var(--widgetsgrid-item-width);
  }
  .widget--widgetsgroup--equalheight .widgetsgrid > .widget[data-cols="2"] {
    flex-basis: auto;
    width: var(--widgetsgrid-item-width-2col);
  }
}
/*

How it works:
- We have the preferred amount of columns in --widgetsgrid-columns
- The max amount of columns is set using media queries (and specific overrides for special situations/places)
- The amount of columns is set using "column-count"
  - this causes a fallback layout using CSS columns
  - as a progressive enhencement a Javascript Masonry component will do the masonry layouting (using the set column-count as amount of columns)
    (this allows the column layout as fallback if Javascript fails)

*/
.widget--widgetsgroup--masonry .widgetsgrid {
  column-count: min(var(--widgetsgrid-columns), var(--widgetsgrid-maxcolumns));
  --widgetsgrid-maxcolumns: 1;
  column-gap: 20px;
  row-gap: 20px;
}

.widget--widgetsgroup--masonry.widget--widgetsgroup--cols2 {
  --widgetsgrid-columns: 2;
}

.widget--widgetsgroup--masonry.widget--widgetsgroup--cols3 {
  --widgetsgrid-columns: 3;
}

.widget--widgetsgroup--masonry.widget--widgetsgroup--cols4 {
  --widgetsgrid-columns: 4;
}

.widget--widgetsgroup--masonry.widget--widgetsgroup--cols5 {
  --widgetsgrid-columns: 5;
}

@media (max-width: 767px) {
  .widget--widgetsgroup--masonry .widgetsgrid {
    display: flex;
    flex-wrap: wrap;
  }
  .widget--widgetsgroup--masonry .widgetsgrid > * {
    width: 100%;
  }
}
@media (min-width: 768px) {
  .widget--widgetsgroup--masonry .widgetsgrid {
    --widgetsgrid-maxcolumns: 2;
  }
}
@media (min-width: 1000px) {
  .widget--widgetsgroup--masonry .widgetsgrid {
    --widgetsgrid-maxcolumns: 4;
  }
}
@media (min-width: 1200px) {
  .widget--widgetsgroup--masonry .widgetsgrid {
    --widgetsgrid-maxcolumns: 5;
  }
}
@media (min-width: 768px) {
  .content__contentcolumn .widgetsgrid.widgetsgrid {
    column-count: 2;
  }
}
.widget--widgetsgroup--masonry {
  column-gap: 20px;
}

@media (min-width: 1000px) {
  .widget--widgetsgroup--cols3,
  .widget--widgetsgroup--cols4 {
    --button-padding: 4px 17px 4px 17px;
  }
}
@media (max-width: 1199px) {
  .widget--widgetsgroup--cols4 {
    --button-padding: 4px 17px 4px 17px;
  }
}
.widget-button--center {
  text-align: center;
}

.widget-button--right {
  text-align: right;
}

.textbutton {
  color: var(--button-color);
  font: var(--button-font);
  text-decoration: none;
  /* NOTE: Don't use white-space: nowrap; for textual buttons !
           These sometimes have long titles so especially on mobile they'll stretch the page beyond the viewport.
  */
  display: inline-flex;
  align-items: baseline;
}

.textbutton:hover {
  color: var(--button-color-hover);
  text-decoration: underline;
}

.defaultbutton,
.ctabutton,
.wh-poll__votebutton.wh-poll__votebutton {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  min-height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-borderradius);
  transition: background-color var(--button-transition-duration);
  cursor: pointer;
  outline-offset: var(--actionbutton-outline-offset);
}

.defaultbutton--solid,
.ctabutton,
.wh-poll__votebutton.wh-poll__votebutton {
  background-color: var(--button-color);
  color: #FFFFFF;
  border: 0;
}
.defaultbutton--solid:hover,
.ctabutton:hover,
.wh-poll__votebutton.wh-poll__votebutton:hover {
  background: var(--button-color-hover);
  color: #FFFFFF;
  text-decoration: none;
}
.defaultbutton--solid .wh-form__button--disabled,
.ctabutton .wh-form__button--disabled,
.wh-poll__votebutton.wh-poll__votebutton .wh-form__button--disabled {
  background: var(--button_disabled_backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.defaultbutton--outline {
  background: transparent;
  border: 1px solid var(--button-color);
  color: var(--button-color);
}
.defaultbutton--outline:hover {
  background: var(--button-color);
  color: #FFFFFF;
  text-decoration: none;
}
.defaultbutton--outline .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-poll__votebutton {
  justify-content: center;
}

.defaultbutton--search::after {
  font: var(--button-icon-font);
  content: var(--button-icon-search);
  margin-left: 35px;
  font-size: 18px;
  font-weight: normal;
}

.defaultbutton--backward::before {
  font: var(--button-icon-font);
  content: var(--button-icon-backward);
  margin-right: 15px;
}

.defaultbutton--forward::after {
  font: var(--button-icon-font);
  content: var(--button-icon-forward);
  vertical-align: middle;
  margin-left: 35px;
}

.textbutton--backward::before {
  font: var(--textbutton-icon-font);
  content: var(--textbutton-icon-backward);
  vertical-align: middle;
  margin-right: 15px;
}

.textbutton--forward::before {
  font: var(--textbutton-icon-font);
  content: var(--textbutton-icon-forward);
  vertical-align: middle;
  margin-right: 15px;
}

/*
  .wh-form__button--next
, .wh-form__button--submit
{
  justify-content: space-between;
}


form.wh-form--submitting .wh-form__button--submit
{
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}
*/
.widget--collapsedcontent {
  margin-top: var(--rtd-spacing-paragraph);
}

.widget--collapsedcontent__intro {
  position: relative;
}

.widget--collapsedcontent__intro ~ .widget--collapsedcontent__viewport {
  margin-top: var(--rtd-spacing-paragraph);
}

.widget--collapsedcontent__intro::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  opacity: 1;
  transition: height 0.2s ease-in-out 0.1s, opacity 0.2s ease-in-out 0s;
  pointer-events: none;
}

.widget--collapsedcontent__toggler {
  appearance: none;
  -webkit-appearance: none;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: var(--rtd-link-color);
  font: inherit;
  cursor: pointer;
}

.widget--collapsedcontent__toggler::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  margin-right: 8px;
}

.widget--collapsedcontent__viewport {
  display: none;
  opacity: 0;
  transition: opacity 2s;
}

.widget--collapsedcontent--expanded .widget--collapsedcontent__intro::after {
  display: none;
}
.widget--collapsedcontent--expanded .widget--collapsedcontent__toggler {
  display: none;
}
.widget--collapsedcontent--expanded .widget--collapsedcontent__viewport {
  display: block;
  opacity: 1;
}

.foldablecontent__foldcontainer {
  position: relative;
  overflow: hidden;
  height: 0;
  transition: height 0s;
}

.foldablecontent__foldcontainer::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 30px;
  width: 100%;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 100%);
  opacity: 1;
  transition: height 0.2s ease-in-out 0.1s, opacity 0.2s ease-in-out 0s;
  pointer-events: none;
}

.widget--foldablecontent--open .foldablecontent__foldcontainer::after {
  opacity: 0;
  transition: height 0.2s, opacity 0.2s ease-in-out 0.1s;
}

.foldablecontent.open > .foldablecontent__foldcontainer::after {
  height: 0;
}

.foldablecontent__contents {
  display: flow-root;
}

.foldablecontent__toggle {
  /* clear <button> styling */
  background: none;
  padding: 0;
  border: 0;
  -webkit-appearance: none;
  color: inherit;
  font: inherit;
  margin-top: 3px;
  display: block;
  color: var(--rtd-link-color);
  cursor: pointer;
}

.foldablecontent__toggle::before {
  display: none;
}

html.site--ffw .foldablecontent__toggle .more::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f107";
  font-size: 17px;
  margin-left: 8px;
}
html.site--ffw .foldablecontent__toggle .less::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f106";
  font-size: 17px;
  margin-left: 8px;
}

html.site--wig .foldablecontent__toggle,
html.site--geldlessen .foldablecontent__toggle,
html.site--wvhg .foldablecontent__toggle {
  text-transform: uppercase;
}
html.site--wig .foldablecontent__toggle .more::before,
html.site--geldlessen .foldablecontent__toggle .more::before,
html.site--wvhg .foldablecontent__toggle .more::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  margin-right: 8px;
}
html.site--wig .foldablecontent__toggle .less::before,
html.site--geldlessen .foldablecontent__toggle .less::before,
html.site--wvhg .foldablecontent__toggle .less::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f068";
  margin-right: 8px;
}

.foldablecontent__toggle > span {
  position: relative;
  display: inline-block;
}

.foldablecontent__toggle .fa {
  position: absolute;
  left: 0;
  font-size: 14px;
  top: 5px;
}

html.layout_mobile .foldablecontent--desktop .foldablecontent__foldcontainer::after,
html.layout_mobile .foldablecontent--desktop .foldablecontent__toggle,
html.layout_tablet .foldablecontent--desktop .foldablecontent__foldcontainer::after,
html.layout_tablet .foldablecontent--desktop .foldablecontent__toggle,
html.layout_desktop .foldablecontent--mobile .foldablecontent__foldcontainer::after,
html.layout_desktop .foldablecontent--mobile .foldablecontent__toggle {
  display: none;
}

html.layout_mobile .foldablecontent--desktop .foldablecontent__foldcontainer,
html.layout_tablet .foldablecontent--desktop .foldablecontent__foldcontainer,
html.layout_desktop .foldablecontent--mobile .foldablecontent__foldcontainer,
.wh-rtd-editor .foldablecontent .foldablecontent__foldcontainer {
  height: auto !important;
}

.foldablecontent__toggle > span.more,
.widget--foldablecontent--open > .foldablecontent__toggle > .less {
  display: block;
}

.foldablecontent__toggle > span.less,
.widget--foldablecontent--open > .foldablecontent__toggle > .more,
.foldablecontent.hasfit .foldablecontent__toggle,
.foldablecontent.hasfit .foldablecontent__foldcontainer::after {
  display: none;
}

.embeddedobject.widget--highlight {
  display: block;
  color: var(--widget-highlight-textcolor);
  background-color: var(--widget-highlight-background);
  padding: var(--widget-highlight-pad-v) var(--widget-highlight-pad-h) var(--widget-highlight-pad-v) var(--widget-highlight-pad-h);
  border: 2px solid var(--widget-highlight-border-color);
  border-radius: var(--widget-border-radius);
}

.widget--highlight__title.widget--highlight__title {
  display: flex;
  align-items: center;
  color: var(--widget-highlight-title-color);
  margin-top: 0;
  margin-bottom: 15px;
}

.widget--highlight__title::before {
  font: var(--fontawesome-regular);
  content: var(--fa-info-circle);
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-right: 10px;
}

/* was 769px */
/*******************************************************

Fonts

*******************************************************/
/*******************************************************

Layout

*******************************************************/
/* enough space for allowing content to be side-by-side */
/* description in search (site search, nieuws, pers) results */
.widget-image__image {
  display: block;
  width: 100%;
}

.widget-image--allowfullscreen .widget-image__image {
  cursor: pointer;
}

.widget-image__caption {
  margin-top: 8px;
}

.widget-image__caption > p.normal {
  color: #333333;
  font: 14px/19px Arial;
}

.widget-image__caption + .widget-image__copyright {
  margin-top: 5px;
}

.widget-image__copyright {
  color: #757575;
  font: 14px/19px Arial;
}

.widget-image__imagewrapper {
  position: relative;
  overflow: hidden;
}

.widget-image__fullscreen {
  width: 37px;
  height: 37px;
  line-height: 37px;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #D7D7D7;
  border-radius: 50%;
  position: absolute;
  right: 10px;
  bottom: 10px;
  transition: transform 0.25s, border-radius 0.25s;
}

.widget-image__fullscreen::before {
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f00e";
}

.widget-image__imagewrapper:hover .widget-image__fullscreen {
  transform: scale(1.5) translate(5px, 5px);
  border-radius: 50% 0 0 0;
}

/*
We need to use gap because we want content to be 2 column if there's enough space,
but automatically switch to a single column if it's not wide enough.
Since we want the widget to work in multiple sites (which may have different breakpoints
and amount of column in the widgetsgroup may differ, it's easyer to use a gap which
won't be used the first item and other items don't fit next to eachother)
*/
.widget--wiglibrary {
  background-color: #FFFFFF;
  padding-bottom: var(--widget-padding-v);
}

.widget--wiglibrary .widget__title {
  display: flex;
  align-items: center;
  height: 55px;
  color: #FFFFFF;
  background-color: #0A0;
  padding: 0 var(--widget-padding-h);
  margin-bottom: 0px;
}

.widget--wiglibrary__items {
  display: flex;
  margin-top: 25px;
  margin-bottom: var(--widget-padding-h);
  padding: 0 var(--widget-padding-h);
}

.widget--wiglibrary__showmorelink {
  display: flex;
  width: max-content;
  margin-left: auto;
  margin-right: var(--widget-padding-h);
}

a.widget--wiglibrary__item {
  text-decoration: none;
}

.widget--wiglibrary__item__title {
  color: var(--widget-listing-item-title-color);
  font: var(--widget-listing-item-title-font);
}

.widget--wiglibrary__item:hover .widget--wiglibrary__item__title {
  text-decoration: underline;
}

.widget--wiglibrary__item__meta {
  margin: var(--widget-listing-item-meta-margin);
  color: var(--widget-listing-item-meta-color);
  font: var(--widget-listing-item-meta-font);
}

@media (min-width: 768px) {
  .widget--wiglibrary__items {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .widget--wiglibrary__items .widget--wiglibrary__item {
    display: flex;
    align-items: baseline;
  }
  .widget--wiglibrary__items .widget--wiglibrary__item + .widget--wiglibrary__item {
    margin-top: var(--widget-listing-spacing-v);
  }
  .widget--wiglibrary__items .widget--wiglibrary__item::before {
    flex: none;
    color: var(--widget-listing-item-title-color);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f054";
    font-size: 16px;
    margin-right: 15px;
  }
  .widget--wiglibrary__items .widget--wiglibrary__moreitems__items {
    margin-bottom: auto;
  }
}
@media (max-width: 767px) {
  .widget--wiglibrary__items {
    display: block;
  }
  .widget--wiglibrary__item {
    display: flex;
    align-items: baseline;
    text-decoration: none;
  }
  .widget--wiglibrary__item::before {
    flex: none;
    color: var(--widget-listing-item-title-color);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    line-height: 1;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    content: "\f054";
    font-size: 16px;
    margin-right: 15px;
  }
  .widget--wiglibrary__item + .widget--wiglibrary__item {
    margin-top: var(--widget-listing-spacing-v);
  }
  .widget--wiglibrary__showmorelink {
    width: max-content;
    margin-left: auto;
  }
}
.widget--wiglibrary__showmorelink {
  align-self: end;
  display: flex;
  font: 500 16px/20px var(--rtd-heading-fontfamily);
  margin-top: 25px;
  text-decoration: none;
}

.widget--wiglibrary__showmorelink::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f138";
  font-size: 23px;
  margin-left: 10px;
}

.widget--wiglibrary__showmorelink:hover span {
  text-decoration: underline;
}
.widget--wiglibrary__showmorelink:hover::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

.widget-richcontent--left {
  text-align: left;
}

.widget-richcontent--right {
  text-align: right;
}

.widget-richcontent--center {
  text-align: center;
}

@media (max-width: 799px) {
  .widget-richcontent__column + .widget-richcontent__column {
    margin-top: 15px;
  }
  .widget-richcontent__column:first-child > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent__column:last-child > *:last-child {
    margin-bottom: 0;
  }
  .widget-richcontent--reverseonmobile .widget-richcontent__content {
    display: flex;
    flex-direction: column;
  }
  .widget-richcontent--reverseonmobile .widget-richcontent__column:first-child {
    margin-top: 15px;
  }
  .widget-richcontent--reverseonmobile .widget-richcontent__column:last-child {
    margin-top: 0;
    order: -1;
  }
}
@media (min-width: 800px) {
  .widget-richcontent__content {
    display: flex;
    column-gap: 30px;
  }
  .widget-richcontent__column > *:first-child {
    margin-top: 0;
  }
  .widget-richcontent__column > *:last-child {
    margin-bottom: 0;
  }
  .widget-richcontent__column {
    flex: 1 1 0px;
  }
}
/*
@media (min-width: $minwidth-pagecontent-twocolumn-centered)
{
  .widget-richcontent
  {
    .widget-richcontent__column:nth-child(1)
    {
      @include layout--default-left;
    }
    .widget-richcontent__column:nth-child(2)
    {
      @include layout--default-right;
    }
  }

  .widget-richcontent--equalheight .widget-richcontent__column
  {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
*/
.widget-quote {
  display: flex;
  align-items: center;
}

.widget-quote__image {
  width: var(--widget-quote-photo-size);
  height: var(--widget-quote-photo-size);
  border-radius: 50%;
  flex: none;
  margin-right: 20px;
}

.widget-quote__text {
  flex: 1 0 0px;
}

.widget-quote__quote.widget-quote__quote > p {
  color: var(--widget-quote-name-color);
  font: 26px/32px var(--rtd-heading-fontfamily);
}

.widget-quote__person {
  margin-top: 10px;
}

.widget-quote__person__name {
  display: inline;
  color: #333333;
  font: 14px var(--rtd-text-fontfamily);
}

.widget-quote__person__function {
  margin-left: 7px;
  display: inline;
  color: #656565;
  font: 14px var(--rtd-text-fontfamily);
}

/*
$listing-date-font: 15px/24px var(--rtd-heading-fontfamily); // 4TU?
--heading-color: $color-orange;
*/
.searchresults {
  margin-top: 15px;
}

.searchresult.searchresult.searchresult {
  text-decoration: none;
  color: #333333;
}

.searchresult__image,
.searchresult__imageplaceholder {
  width: 100%;
  padding-top: 75%;
}

.searchresult__image {
  background-size: cover;
}

.searchresult__imageplaceholder {
  background-color: #F0F0F0;
}

.searchresult:hover .searchresult__imageplaceholder {
  background-color: #E0E0E0;
}

a.searchresult .searchresult__content {
  padding: 15px 17px;
  border-radius: 10px;
  margin-left: -15px;
  margin-right: -15px;
}

a.searchresult .searchresult__content:hover {
  background-color: #F5F5F5;
}

.searchresult__metacol {
  flex: 1 1 0;
  min-width: 0;
}

.searchresult__date {
  color: #767676;
  font: 14px/24px Arial, "Sans-Serif";
}

.searchresult__title {
  margin: 0 0 1px 0; /* cancel h2 margins */
  color: var(--header-color);
  font: bold 20px/26px var(--rtd-heading-fontfamily);
}

.searchresult__url {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 3px;
  color: #333333;
  font-size: 13px;
  padding-top: 3px;
}

.searchresult__description {
  font: 15px/26px Arial, "Sans-Serif";
  /*
  This work on Edge 17+, Chrome 14+, Safari 5+, iOS 5+, Firefox 68
  Firefox issues:
  - https://bugzilla.mozilla.org/show_bug.cgi?id=866102
  - https://bugzilla.mozilla.org/show_bug.cgi?id=1232992
  - https://developer.microsoft.com/en-us/microsoft-edge/platform/usage/css/-webkit-line-clamp/
  */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

a.searchresult:hover .searchresult__content {
  background-color: #F1F1F1;
}

a.searchresult:hover .searchresult__title {
  text-decoration: underline;
}

.searchresult__readmore {
  margin-top: 5px;
  display: block;
  display: flex;
  align-items: center;
  white-space: nowrap;
  font: 500 15px var(--rtd-heading-fontfamily);
  text-transform: uppercase;
  color: #1E8527;
}

.searchresult__readmore::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
  content: "\f067";
  margin-right: 9px;
}

.searchresult__readmore--external::before {
  content: "\f35d";
}

.neotabs {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 19px;
  border-bottom: 1px solid #C5C5C5;
  font: 19px/24px var(--rtd-heading-fontfamily);
  margin-top: 30px;
}

.neotabs:first-child {
  margin-top: 0;
}

.neotabs + .searchresults {
  margin-top: 7px;
}

.neotabs a,
.neotabs span {
  font-weight: normal;
  color: #3F3F3F;
  padding: 5px 12px;
  text-decoration: none;
}

.neotabs * + * {
  margin-left: 11px;
}

.neotabs span {
  position: relative;
}

.neotabs span::after {
  content: "";
  position: absolute;
  left: 12px;
  right: 12px;
  bottom: 6px;
  border-bottom: 1px solid #3F3F3F;
}

.neotabs *:first-child {
  margin-left: -12px;
}

.neotabs a:hover {
  text-decoration: underline;
  color: var(--neotabs-themecolor);
}