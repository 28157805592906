@import url(/.publisher/sd/minfin_wig/shared/assets/fontawesome5/css/all.css);
@import url(/.minfin_wig/fonts/@typopro/web-open-sans/typopro-opensans.css);
@import url(/.minfin_wig/fonts/@typopro/web-dosis/typopro-dosis.css);

/*@import url(/.publisher/sd/minfin_wig/shared/fontawesome5-cssvars.css);*/
@import url("../../../shared/web/fontawesome5-cssvars.css");

/*
This stylesheet can NOT be a SASS stylesheet.
It must be useable within the richtext editor (which does not support SASS for includes stylesheets)
*/

html
{
  --site-transitions-duration: 0.2s;
  --button-transition-duration: 0.2s;


  --themecolor: var(--color-green);
  --themecolor-transparent: var(--wig-green-normal-transparent);

  --widget-title-color: var(--color-purple);


  --color-menugrey: #F9F9F9;
  --color-green-small-on-menugrey: #208328;


  --color-green-highcontrast: #0A8539; /* green to use as background color when there is white text smaller than about 18.66px over it */

  /* Footer */
  --footer-panel-background: var(--color-green-background-highcontrast);



  --widget-background: #FFFFFF;

  /* @media (min-width: $responsive-desktop) */
  --widget-padding-h: 21px;
  --widget-padding-v: 21px;
  --widget-padding-top: 25px;
  --widget-padding-bottom: 25px;

  --widgetsgrid-padding-h: 25px;
  --widgetsgrid-padding-v: 30px;
  --widgetsgrid-gutter-x:  30px;
  --widgetsgrid-gutter-y:  30px;




  --rtd-link-color: var(--color-green-small);

  --rtd-text-fontfamily:    "TypoPRO Open Sans", "OpenSans", Arial;
  --rtd-heading-fontfamily: "TypoPRO Dosis", "Dosis", Arial;



  --header-color: #641C76;

  --formcontrols-themecolor: var(--color-green);


  --pagination-themecolor:       #1E8527; /* green as themecolor */
  --pagination-pagebutton-color: #1E8527; /* #3B3B3B */;
  --pagination-font:             500 16px var(--rtd-heading-fontfamily);

  --neotabs-themecolor:          #1E8527;

  /* Page layout */
  --pagegrid-contentarea-width:  1150px; /* $pagegrid-contentarea-width */
  --sidepadding:                   15px;
  --page-sidepadding: 25px;


  --widget-icon-space:         43px; /* amount of space to reserve for icons (for widgets such as checklist, links & downloads to keep their icons & titles aligned) */
  --widget-sidebar-icon-space: 26px; /* amount of space to reserve for icons (for widgets such as checklist, links & downloads to keep their icons & titles aligned) */



  /* Accordion/FAQ widget */
  --widget-accordion-line-color:        #DADADA;
  --widget-accordion-items-title-color: var(--color-green);
  --widget-accordion-items-title-font:  500 20px/26px var(--rtd-heading-fontfamily);
  --widget-accordion-icon-color:        #641C76;

  /* Checklist widget */
  --widget-checklist-number-color:     #1E8527;
  --widget-checklist-number-font:      700 17px/20px "TypoPRO Dosis", "Dosis", Arial;
  --widget-checklist-item-color:       #641C76;
  --widget-checklist-item-font:        700 20px/26px "TypoPRO Dosis", "Dosis", Arial;

  /* Downloads widget embedded in the page content */
  --widget-download-item-padding-v:    6px;
  --widget-download-icon-color:        #1E8527;
  --widget-download-icon-fontsize:     22px;
  --widget-download-title-color:       #1E8527;
  --widget-download-title-font:        500 20px/26px "TypoPRO Dosis", "Dosis", Arial;
  --widget-download-description-color: #333333;
  --widget-download-description-font:  15px/26px Arial;

  /* Downloads widget in the sidebar */
  --widget-sidebar-download-item-padding-v: 7px;
  --widget-sidebar-download-title-font:     600 16px/20px "TypoPRO Dosis", "Dosis", Arial;

  /* "Table of contents" widget */
  --widget-tableofcontents-header-color:      var(--color-green-large-on-light-background);
  --widget-tableofcontents-header-font:       bold 18px var(--button-fontfamily);
  --widget-tableofcontents-item-bullet-color: var(--button-color); /* #25A22F */
  --widget-tableofcontents-item-bullet-size:  15px;
  --widget-tableofcontents-item-color:        #333333;
  --widget-tableofcontents-item-font:         500 15px/28px "TypoPRO Open Sans", "OpenSans";


  /************************************************************
  *
  *  Page components / Buttons
  */

  --button-borderradius:   0px;
  --button-color:          #25A22F;
  --button-color-hover:    #0B923E;
  /*--button-contrastcolor:     #FFFFFF;*/
  --button-fontfamily:     "TypoPRO Dosis Buttons", "Dosis", Arial;
  --button-font:           bold 20px/26px "TypoPRO Dosis Buttons", "TypoPRO Dosis", "Dosis", Arial;
  --button-padding:        5px 20px 5px 20px;
  --button-height:         48px;
  --button-disabled-backgroundcolor: #AAAAAA;
  --button-disabled-textcolor:       #666666;
  /*--button-text-alignment-tweak:  4px; / * because of Officina font */

  /* MinFin specific button additions */
  --button-icon-font:          900 13px "Font Awesome 5 Pro";
  --button-icon-backward:      var(--fa-chevron-left);
  --button-icon-forward:       var(--fa-chevron-right);
  --button-icon-search:        var(--fa-search);

  --textbutton-icon-font:      900 18px "Font Awesome 5 Pro";
  --textbutton-icon-backward:  var(--fa-chevron-left);
  --textbutton-icon-forward:   var(--fa-chevron-right);


  /************************************************************
   *
   *  Page components / Share bar
   */

  --component-sharepage-button-background:  #FFFFFF;
  --component-sharepage-button-color:       var(--color-green);
  --component-sharepage-heading-color:      var(--color-green);
  /*--component-sharepage-heading-font:       20px/26px var(--fontfamily-heading);*/
  --component-sharepage-heading-font:       bold 20px/26px "TypoPRO Dosis", "Dosis", Arial; /* #{$fontfamily-heading}; */
}


.widget--links .widget__title::before
{
  font: var(--fontawesome-solid);
  content: var(--fa-link);
  content: var(--fa-link) / "";
  font-size: 22px;
  margin-right: 10px;
}

.widget--padding
{
  padding-left: var(--widget-padding-h);
  padding-right: var(--widget-padding-h);
  padding-top: var(--widget-padding-v);
  padding-bottom: var(--widget-padding-v);
}






a
{
  color: var(--rtd-link-color);
  outline-offset: var(--actionbutton-outline-offset);
}

b
{
  font-weight: 600; /* semi-bold */
}

  ul.unordered li + li
, ol.ordered li + li
{
  margin-top: 15px;
}


/* NOTE: we cannot use
   - use css variables for this (because of IE and Edget)
   - use mixins (because of the preview in the richeditor which doesn't support SASS)
*/
.tc-green-fg  { color: #1c9f2c; }
.tc-gray-fg   { color: #808080; }
.tc-purple-fg { color: #641c76; }

.tc-green-bg  { background-color: #1c9f2c; }
.tc-gray-bg   { background-color: #808080; }
.tc-purple-bg { background-color: #641c76; }

.tc-green-bggradient
{
  background: #1c9f2c;
  background: linear-gradient(to bottom, #1c9f2c 0%, #178625 100%);
}
.tc-gray-bggradient
{
  background: #1c9f2c;
  background: linear-gradient(to bottom, #999999 0%, #7f7f7f 100%);
}


[data-popup]
{
  cursor: pointer;
}


  .richdoc h1
, .richdoc h2
, .richdoc .heading2noindex
, .richdoc h3
, .richdoc h4
, .site__printheader .fs_h1
, .wh-webpacksurvey__title
, .wh-webpacksurvey__pagetitle
, .wh-webpacksurvey__grouptitle
, .widget--quote__quote
{
  color: #642382;
}

/* 'Mijn Werknemer en Geldzaken' gebruik Dosis Semibold en heeft aangepaste font-sizes
    ten opzichte van de hoofd WIG site

   Volgens weg-infopagina-940px-desktop.psd
*/

  h1
, .fs_h1
{
  font: 600 48px "TypoPRO Dosis", "Dosis", Arial;
  margin-bottom: 15px;
}
h2
, .heading2noindex
, .wh-popup-body .title
, .wh-webpacksurvey__title
{
  font: 600 32px/36px "TypoPRO Dosis", "Dosis", Arial;
  margin-bottom: 5px;
}
h3
, .wh-webpacksurvey__pagetitle
{
  font: bold 21px/24px "TypoPRO Dosis Menu", "TypoPRO Dosis", "Dosis", Arial;
  margin-top: 25px;
  margin-bottom: 7px;
}

  h4
, .fs_h4
, .wh-webpacksurvey__pagetitle
{
  font: bold 18px/24px "TypoPRO Dosis Menu", "Dosis", Arial;
  margin-bottom: 5px;
}

p.intro
{
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 50px;
}

/* The following titles are the same for desktop, table and mobile ----------- */

  .widget__title
, .wh-poll__title
{
  font: 500 26px/30px "TypoPRO Dosis", "Dosis", Arial;
  color: #642382;
  margin-bottom: 10px;
}
h2.widget__title
{
  margin-top: 0;
}


  .widget--accordion__item__title
, .widget--tips__title
{
  font: 600 21px/24px "TypoPRO Dosis", "Dosis", Arial;
}

  .widget--question_answer__question
, .widget--quote_bubble__quote
{
  font: 600 21px "TypoPRO Dosis", "Dosis", Arial;
}

.wh-webpacksurvey__grouptitle /* FIXME: verify it's the same on mobile */
{
  font: 600 18px "TypoPRO Dosis", "Dosis", Arial;
}

/* No mobile design for the following ---------------------------------------- */

.dialog__title /* used for 'question answer widget' */
{
  font: 600 24px/28px "TypoPRO Dosis", "Dosis", Arial;
  color: #642382;
  margin-bottom: 15px;
}






@media (max-width: 400px) /* 375px */
{
  /* volgens weg-ervaringen_werknemers_detail-375px-mobile.psd */
    h1
  , .fs_h1
  {
    font: 600 36px "TypoPRO Dosis", "Dosis", Arial;
    margin-bottom: 15px;
  }
    h2
  , .heading2noindex
  , .wh-popup-body .title
  , .wh-webpacksurvey__title
  {
    font: 600 26px "TypoPRO Dosis", "Dosis", Arial;
    margin-bottom: 5px;
  }
  h3
  , .wh-webpacksurvey__pagetitle
  {
    /* niet gedefinieerd */
    font: 600 21px/24px "TypoPRO Dosis", "Dosis", Arial;
  }

  .dialog__title /* used for 'question answer widget' */
  {
    font: 600 24px/28px "TypoPRO Dosis", "Dosis", Arial;
    color: #642382;
    margin-bottom: 15px;
  }

  p.intro
  {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 30px;
  }
}




  p.normal
, ul.unordered
, ol.ordered
, blockquote
, .embeddedobject
, .wh-video
, .wh-webpacksurvey__pagedescription
{
  font-size: 15px;
  line-height: 28px;

  margin-top: 0;
  margin-bottom: 18px; /* 25px in design, but got too much whitespace */
}

  p.normal:last-child
, ul.unordered:last-child
, ol.ordered:last-child
, blockquote:last-child
, .embeddedobject:last-child
{
  margin-bottom: 0;
}


/* without the RTD editor use the margins on the embeddedobject editor's container instead of embeddedobject HTML itself */
.wh-rtd-editor .embeddedobject
{
  margin-bottom: 0;
}
.wh-rtd-editor div.wh-rtd-embeddedobject
{
  margin-bottom: 18px;
}


/*
.richdoc
.wbdvmtool-rtdcontent
*/


  .richdoc > p
, .richdoc > ul
, .richdoc > ol
, .richdoc > .embeddedobject.transparent
{
  margin-top: 15px;
  margin-bottom: 15px;
}

.richdoc > p:last-child
{
  margin-bottom: 0;
}

/* sort of collapse-margins because we use padding instead of margins */

  .richdoc > h1 + .widget--checklist
, .richdoc > h2 + .widget--checklist
, .richdoc > h3 + .widget--checklist
, .richdoc > h4 + .widget--checklist
{
  margin-top: 25px;
}

.richdoc > .embeddedobject + p
{
  margin-top: 15px; /* top so we can remove if needed in a + selector */
}

  .richdoc > h1 + p
, .richdoc > h2 + p
, .richdoc > .heading2noindex + p
, .richdoc > h3 + p
, .richdoc > h4 + p
{
  margin-top: 0;
}
.richdoc > *:first-child
{
  margin-top: 0;
}


ul.unordered
{
  padding-left: 17px;
}


img.wh-rtd-img
{
  max-width: 100%; /* in mobile/tablet mode content must scale to viewport */
  height: auto;
}

  .ctalink
, .backlink
{
  display: inline-flex;
  align-items: baseline;

  text-decoration: none;

  /* specify anchor color in case we're not used as an <a> element
     might happen when we are in a container which uses [data-popup].
  */
  color: #218729;
}

.ctalink > *
{
  text-decoration: underline;
}



.widget--downloads .ctalink
{
  display: flex;
}
.widget--downloads .ctalink > *
{
  text-decoration: none;
}



  .ctalink::before
, .backlink::before
{
  display: inline-block;
  text-decoration: none;

  font: normal normal normal 14px/1 'Font Awesome 5 Pro';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);

  font-size: 75%;
  margin-right: 5px;
}

.ctalink::before
{
  content: "\f054"; /* fa-chevron-right */
  content: "\f054" / ""; /* Chromium browsers allow alternative text for screenreaders */
}

.backlink::before
{
  content: "\f053"; /* fa-chevron-left */
  content: "\f053" / ""; /* Chromium browsers allow alternative text for screenreaders */
}





/* prevent image in columns or in fluid mobile layout
   to stick outside their content area
*/
img.wh-rtd__img
{
  max-width: 100%;
  height: auto;
}

  img.wh-rtd__img--floatleft
, img.wh-rtd__img--floatright
{
  max-width: 50%;
  margin: 0 20px 0 0;
}




/* prevent embeddedobject getting beside a floating image */
  .embeddedobject
, .wh-rtd-embeddedobject
{
  clear: both;
}

/* NOTE: this will give a problem when there are floats in another column
         (outside this element)
         (Unless .richdoc get's an overflow: auto/hidden/scroll; or float: left; so it'll make a new context)
         (essecially have some kind of layout context)
*/
.richdoc::after
{
  display: block;
  content: "";
  clear: both;
}
