/*

Button:
- link
- outline
- solid


Defining a button
    a.wh-form-button
    button.wh-form-button
    *.wh-form-button

Layout modifyers:
    .wh-form-button--outline - Overrides the default solid style of the button
    .wh-form-button--solid

Communicating intent (icons):
    .wh-form__button--backward
    .wh-form__button--forward

*/
a.simplebutton:focus,
.wh-form__button:focus {
  outline: 2px solid #13A538;
  outline-offset: 2px;
  -moz-outline-radius: 2px;
}

.wh-form__button,
.consentdialogbutton {
  border: 0 none;
  -webkit-appearance: none;
  text-decoration: none;
  box-shadow: none;
  font: var(--button-font);
  display: inline-flex;
  align-items: center;
  min-height: var(--button-height);
  padding: var(--button-padding);
  border-radius: var(--button-borderradius);
  transition: background-color var(--button-transition-duration);
  cursor: pointer;
}

.wh-form__button:not(.wh-form__button--solid),
.wh-form__button--outline,
.consentdialog__specifyconsentbutton,
.consentdialog__denyconsentbutton,
.consentdialog__acceptselectionbutton {
  background: transparent;
  border: 1px solid #13A538;
  color: #13A538;
}
.wh-form__button:not(.wh-form__button--solid):hover,
.wh-form__button--outline:hover,
.consentdialog__specifyconsentbutton:hover,
.consentdialog__denyconsentbutton:hover,
.consentdialog__acceptselectionbutton:hover {
  background: #13A538;
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__button:not(.wh-form__button--solid) .wh-form__button--disabled,
.wh-form__button--outline .wh-form__button--disabled,
.consentdialog__specifyconsentbutton .wh-form__button--disabled,
.consentdialog__denyconsentbutton .wh-form__button--disabled,
.consentdialog__acceptselectionbutton .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-form__buttongroup .wh-form__button {
  background: transparent;
  border: 1px solid #13A538;
  color: #13A538;
}
.wh-form__buttongroup .wh-form__button:hover {
  background: #13A538;
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__buttongroup .wh-form__button .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-form__buttongroup .wh-form__button--next,
.wh-form__buttongroup .wh-form__button--submit,
.wh-poll__votebutton.wh-poll__votebutton,
.filterform__submit {
  background-color: #13A538;
  color: #FFFFFF;
  border: 0;
}
.wh-form__buttongroup .wh-form__button--next:hover,
.wh-form__buttongroup .wh-form__button--submit:hover,
.wh-poll__votebutton.wh-poll__votebutton:hover,
.filterform__submit:hover {
  background: #0B923E;
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__buttongroup .wh-form__button--next .wh-form__button--disabled,
.wh-form__buttongroup .wh-form__button--submit .wh-form__button--disabled,
.wh-poll__votebutton.wh-poll__votebutton .wh-form__button--disabled,
.filterform__submit .wh-form__button--disabled {
  background: var(--button_disabled_backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-form__button.wh-form__button--solid,
.wh-form__button.wh-form__uploadfieldselect,
.consentdialog__consenttoallbutton {
  background-color: #13A538;
  color: #FFFFFF;
  border: 0;
}
.wh-form__button.wh-form__button--solid:hover,
.wh-form__button.wh-form__uploadfieldselect:hover,
.consentdialog__consenttoallbutton:hover {
  background: #0B923E;
  color: #FFFFFF;
  text-decoration: none;
}
.wh-form__button.wh-form__button--solid .wh-form__button--disabled,
.wh-form__button.wh-form__uploadfieldselect .wh-form__button--disabled,
.consentdialog__consenttoallbutton .wh-form__button--disabled {
  background: var(--button_disabled_backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.wh-form .wh-form__button {
  height: 44px;
}
@media (max-width: 480px) {
  .wh-form .wh-form__button {
    height: 44px;
  }
}

.wh-form__navbuttons .wh-form__button {
  height: var(--button-height);
}

.wh-form__button--arrow::before,
.spc-textbutton--arrow::before {
  font: var(--fontawesome-solid);
  content: var(--fa-chevron-right);
  font-size: 13px;
  margin-right: 10px;
}

.wh-form__button--backward::before,
.wh-form__button--previous::before,
.spc-textbutton--backward::before {
  font: var(--fontawesome-solid);
  content: var(--fa-arrow-left);
  font-size: 13px;
}

.wh-form__button--backward::before,
.spc-textbutton--backward::before {
  margin-right: 15px;
}

.wh-form__button--previous::before {
  margin-right: 15px;
}

.spc-textbutton .wh-form__button--backward::before {
  margin-right: 15px;
}

.wh-form__button--forward::after,
.wh-form__button--next::after,
.wh-form__button--submit::after {
  font: var(--fontawesome-solid);
  content: var(--fa-chevron-right);
  font-size: 13px;
  vertical-align: middle;
  margin-left: 35px;
}

.spc-textbutton--forward::before,
.widget-news__morelink::before {
  font: var(--fontawesome-solid);
  content: var(--fa-chevron-right);
  font-size: 13px;
  vertical-align: middle;
  margin-right: 15px;
}

.widget-news__morelink::before {
  font-size: 17px;
}

.spc-textbutton .wh-form__button--forward::after {
  margin-left: 15px;
}

.widget-cta .wh-form__button--forward::after {
  margin-left: 70px;
}

.wh-form__button--next,
.wh-form__button--submit {
  justify-content: space-between;
}

form.wh-form--submitting .wh-form__button--submit {
  opacity: 0.5;
  transition: none;
  pointer-events: none;
}

.spc-textbutton {
  color: #13A538;
  text-decoration: underline;
}

.spc-textbutton:hover {
  color: #0B923E;
  text-decoration: none;
}

/*
.widget-cta
.widget-richcontent
*/
.widget--background .wh-form__button.wh-form__button,
.widget--withdarkbg .wh-form__button.wh-form__button {
  background: transparent;
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.widget--background .wh-form__button.wh-form__button:hover,
.widget--withdarkbg .wh-form__button.wh-form__button:hover {
  background: #FFFFFF;
  color: #FFFFFF;
  text-decoration: none;
}
.widget--background .wh-form__button.wh-form__button .wh-form__button--disabled,
.widget--withdarkbg .wh-form__button.wh-form__button .wh-form__button--disabled {
  border: 1px solid var(--button-disabled-backgroundcolor);
  color: var(--button-disabled-textcolor);
  cursor: default;
}

.widget--background .wh-form__button.wh-form__button:hover,
.widget--withdarkbg .wh-form__button.wh-form__button:hover {
  background-color: rgba(0, 0, 0, 0.2);
}

.widget--background .spc-textbutton,
.widget--withdarkbg .spc-textbutton {
  color: #FFFFFF;
}