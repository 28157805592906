html
{
  --pageheader-filterbar-background: #F5F5F5;


  /************************************************************
   *
   *  Page components / Tabstrip
   */

  --color-theme: var(--color-green);

  --component-tabstrip-background:   transparent;
  --component-tabstrip-height:       44px;
  --component-tabstrip-spacing:      25px;
/*  --component-tabstrip-font:         18px var(--rtd-heading-fontfamily);*/

  --component-tabstrip-font:               bold 18px var(--rtd-heading-fontfamily);
  --component-tabstrip-item-selected-font: bold 18px var(--rtd-heading-fontfamily);
/*"TypoPRO Dosis Buttons", "Dosis", Arial;*/

/* NOTE: Wijzer in Geldzaken gebruikt geen Dosis font */
/*  --component-tabstrip-item-selected-font: 500 18px var(--rtd-heading-fontfamily);*/



  --component-tabstrip-item-padleft:     0px;
  --component-tabstrip-item-padright:   17px;

  --component-tabstrip-item-color:      #333333;
  --component-tabstrip-item-background: transparent;
  --component-tabstrip-item-border:     none;

  --component-tabstrip-item-hover-color:          var(--color-theme);
  --component-tabstrip-item-hover-background:     transparent;
  --component-tabstrip-item-hover-border:         none;

  --component-tabstrip-item-selected-bordercolor: var(--color-theme);
  --component-tabstrip-item-selected-color:       var(--color-theme);

  --component-tabstrip-item-selected-underlining-color: var(--color-theme);
  --component-tabstrip-item-selected-underlining-height: 2px;
}

.spc-tabstrip__content
{
  border-bottom: 2px solid #F0F0F0;
}

.spc-tabstrip .tabstrip__tab
{
  margin-bottom: -2px;
}
