/* NOTE: sectionmenu currently only used in "Wijzer in geldzaken" */
#sectionmenu-mobile {
  width: 100%;
}

@media (max-width: 784px) {
  .widget--tableofcontents-sectionmenu {
    display: none;
  }
}
@media (min-width: 784px) {
  .sectionmenu-mobile-wrapper {
    display: none;
  }
}
.widget--tableofcontents {
  list-style: none;
  border-radius: var(--widget-border-radius);
}

/* override specificy of .widgets_column .widget */
.widget--tableofcontents-page {
  background-color: var(--widget-tableofcontents-background);
  padding: 20px;
  box-shadow: none;
}

.widget--tableofcontents-sectionmenu {
  --widget-tableofcontents-header-color: var(--color-purple);
  padding: 0;
}
.widget--tableofcontents-sectionmenu .widget__title {
  min-height: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #DADADA;
  display: flex;
  align-items: center;
  padding: 7px var(--widget-padding-h);
  margin: 0;
}
.widget--tableofcontents-sectionmenu .tableofcontents {
  padding: 10px var(--widget-padding-h) var(--widget-padding-v) var(--widget-padding-h);
}

.widget--tableofcontents h2 {
  color: var(--widget-tableofcontents-header-color, #25A22F);
  font: var(--widget-tableofcontents-header-font);
  border: 0;
  padding: 0;
  margin: 0 0 20px 0;
}

.widget--tableofcontents ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.widget--tableofcontents li {
  display: flex;
  align-items: baseline;
}

.widget--tableofcontents li::before {
  color: var(--widget-tableofcontents-item-bullet-color);
  font: var(--fontawesome-regular);
  content: var(--fa-chevron-right);
  content: var(--fa-chevron-right)/"";
  font-size: var(--widget-tableofcontents-item-bullet-size);
  flex: none;
  margin-right: 13px;
}

.widget--tableofcontents a {
  display: inline-block;
  flex: 1 1 auto;
  text-wrap: pretty;
  text-wrap: balance;
  color: #333333;
  color: var(--widget-tableofcontents-item-color, #333333);
  font: var(--widget-tableofcontents-item-font);
  text-decoration: none;
  text-underline-offset: 2px;
  padding-top: var(--widget-tableofcontents-item-padding-v);
  padding-bottom: var(--widget-tableofcontents-item-padding-v);
}

.widget--tableofcontents a:hover {
  text-decoration: underline;
}

.widget--tableofcontents .tableofcontents a[aria-current=page] {
  color: var(--color-green);
  text-decoration: underline;
}