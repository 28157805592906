/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width the menu/header goes into mobile mode */
/* below this width the content goes into mobile mode */
/* iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/*
#28A532 - Groen uit de logo, niet in gebruik (meer) in de site
#25A22F - Groen
#218729 - Groen AA - Gebruikt voor elementen met kleine fontsize (zoals links in de tekst en links in widgets)
*/
.page__intro {
  margin-top: 20px;
  border: 1px solid #1ca02c;
  display: flex;
  flex: 1;
  align-items: center;
}

.page__intro .ctabutton {
  height: 53px;
  line-height: 53px;
}

@media (max-width: 767px) {
  .homecontent {
    display: flex;
    flex-direction: column;
  }
  .homecontent--intro {
    order: 2;
  }
  .homecontent--col2 {
    order: 1;
  }
  .homecontent--col2 .content__widgetscolumn__contentshift {
    position: relative;
    margin-top: -27px;
  }
}
/*

Use flex instead of line-height to vertically align menuitems.
(this way long items can wrap if we want)

*/
/* equal height, allow child content to grow */
html.page--home .content__contentcolumn,
html.page--home .content__widgetscolumn {
  display: flex;
  flex-direction: column;
  padding-left: var(--page-sidepadding);
  padding-right: var(--page-sidepadding);
}

@media (min-width: 768px) {
  html.page--home .content__contentcolumn {
    padding-right: 0;
    margin-right: 20px;
  }
  html.page--home .content__widgetscolumn {
    padding-left: 0;
  }
  html.page--home .content__widgetscolumn {
    width: 45%; /* 410px */
  }
}
.widget--ctamenu {
  position: relative;
  z-index: 1;
}

.ctamenu {
  margin: 0;
  padding: 0;
  font: normal 21px "TypoPRO Dosis", "Dosis";
}

.ctamenu__title,
.ctamenu__item {
  display: flex;
  align-items: center;
  font-size: 21px;
  padding-left: 16px;
  padding-right: 16px;
  min-height: 54px;
  text-decoration: none;
}

.ctamenu__title {
  position: relative;
  font: 600 26px "TypoPRO Dosis", "Dosis";
  background-color: #5c1a6c;
  color: #FFFFFF;
}

.ctamenu__title::before {
  /* [IEFIX] IE11 doesn't honor our min-height, we place a placeholder block of the min-height we need */
  display: block;
  content: "";
  height: 54px;
}

.ctamenu__title h2 {
  margin: 0;
}

.ctamenu__item {
  background-color: var(--button-color);
  font: var(--button-font);
  color: #FFFFFF;
  border-top: 1px solid #FFFFFF;
}

.ctamenu__item:hover {
  background-color: var(--button-color-hover);
}

.ctamenu__item__icon {
  display: inline-block; /* [IEFIX] width is ignored on inline flex elements by IE10 */
  width: 35px;
  flex-shrink: 0;
  margin-right: 17px;
}

.ctamenu__item__icon img {
  display: block;
  max-width: 100%;
}

.ctamenu__item__title {
  display: inline-block; /* [IEFIX] width is ignored on inline flex elements by IE10 */
  flex-grow: 1;
  overflow: hidden; /* allow cutting off instead of flex growing */
  text-overflow: ellipsis;
  padding: 3px 0; /* padding to prevent browser cutting of text which slightly overflows */
}

/*
The grey CTA button on the home
*/
.widget--homecta {
  /* eat excess space */
  flex: 1;
  align-items: center;
  display: flex;
  background: #df601a;
  cursor: pointer;
  text-decoration: none;
}

.widget--homecta__symbol {
  margin-right: 20px;
}

.widget--homecta__symbol > img {
  width: 51px;
}

.widget--homecta .widget__title {
  color: #FFFFFF;
}

.widget--homecta .ctalink {
  font: bold 19px/28px "TypoPRO Dosis Buttons";
  color: #FFFFFF;
}

.fgw__readspeaker--homepage .rsbtn {
  position: absolute;
  right: 10px;
  top: 10px;
  justify-content: end;
}
@media (min-width: 768px) and (max-width: 1075px) {
  .fgw__readspeaker--homepage .rsbtn {
    right: 0;
    top: -50px;
  }
}