/*@import "@mod-minfin_wig/webdesigns/weekvanhetgeld/shared/config.scss";*/
/*@import "@mod-minfin_wig/webdesigns/shared/web/assets/fontawesome5/mixins.scss";*/

/*
We need to use gap because we want content to be 2 column if there's enough space,
but automatically switch to a single column if it's not wide enough.
Since we want the widget to work in multiple sites (which may have different breakpoints
and amount of column in the widgetsgroup may differ, it's easyer to use a gap which
won't be used the first item and other items don't fit next to eachother)
*/

/* $widget-news-minwidth-sidebyside: 768px; iPad portrait mode */


/*
@mixin content-arrow
{
  flex: none;

  color: var(--widget-listing-item-title-color);
  @include fontawesome-solid;
  content: var(--fa-chevron-right);
  font-size: 16px;
  //font-size: var(--widget-tableofcontents-item-bulletsize, 18px);

  width: 23px;
}
*/


.widget--news
{
  background: #FFFFFF;
}

/* Inline (not in widgetsgrid or widgets column) */
.richdoc > .widget--news
{
  background: #F2F2F2;
}

/* News widgets embedded in the richdocument */
.site--ffw .richdoc .widget--news
{
  border: 1px solid #BBB;
  box-shadow: 0 0.5px 4px rgba(0, 0, 0, 0.35);
  border-radius: var(--widget-border-radius);
}



.widget--news .minfinListItem
{
  display: flex;
  flex-direction: column;
}


/*
// !!!! link at the bottom
.widget--news .widget__showmorelink
{
  align-self: end;

  display: flex;

  color: var(--widget-listing-item-title-color);
  // font: 500 16px/20px var(--rtd-heading-fontfamily);
  font: 500 15px/20px var(--rtd-heading-fontfamily);
  // margin-top: 25px;
  text-decoration: none;
}
*/


a.widget--news__item
{
  text-decoration: none;
}

.widget--news__item__image
{
  display: block;
  width: 100%;

  border-radius: 8px;
}

/* Placeholder in case the item doesn't have an image and we lack a fallback image */
div.widget--news__item__image
{
  aspect-ratio: var(--widget-itemslist-large-firstitem-image-aspectratio);
  background: rgb(0,0,0,0.1);
}



/*
More items link at the bottom

.widget--news
{
  display: flex;
  flex-direction: column;
}
.widget--news__items
{
  flex: 1 0 auto;
  //height: 100%;
}

.widget--news__moreitems
{
  display: flex;
  flex-direction: column;
}

.widget--news__moreitems__items
{
  margin-bottom: auto;
}
*/



/* Side by side (2-column) layout */
@media (min-width: 768px) /* $widget-news-minwidth-sidebyside) */
{
  /* Grote weergave nieuws widget als gebruikt in contentvlak */
    .richdoc--toplevel > .widget--news /* WVHG/GL. WIG */
  , .wh-rtd__body .widget--news /* in RTD editor */
/*  :where(html.wh-widgetpreview, .widgetsgrid--transparent, .body__widgetsbottom, .content__widgetscolumn)*/
  {
    container-name: newsWidgetLarge;
    container-type: inline-size;

    --widget-title-font: var(--widget-large-title-font);
    --widget-itemslist-item-title-font: bold 16px var(--rtd-heading-fontfamily);

/*    --pagegrid-col-fullwidth*/
    max-width: none;
    background: #F2F2F2;

    padding-top: 32px;
    padding-bottom: 32px;

    .widget__header
    {
      margin-bottom: 22px;
    }

      .widget__header
    , .widget--news__items
    {
      /* @include layout--default; */
      width:         100%;
      max-width:     calc(var(--pagegrid-contentarea-width) + var(--sidepadding) * 2);
      padding-left:  var(--sidepadding);
      padding-right: var(--sidepadding);
      margin-left:   auto;
      margin-right:  auto;
    }

    .widget__header .widget__title
    {
      min-height: auto;
    }
  }
}


/* Stacked */
@media (max-width: 767px) /* $widget-news-minwidth-sidebyside - 1px) */
{
  .widget--news
  {
    container-name: newsWidgetAsList;
    container-type: inline-size;

    padding-top: 12px;
  }

  .widget--news__firstitem
  {
    display: contents;
  }
}



/* Always a list view when used in the widgets column */
:where( .widgetsgrid            /* WVHG/GL, FFW, WIG */
      , .content__widgetscolumn /* WVHG/GL, FFW */
      , .widgets_column         /* WIG */
      , html.wh-widgetpreview   /* widgetpreview is always a file widget meant to use in a widgetsgroup */
      )
{
  .widget--news
  {
    container-name: newsWidgetAsList;
    container-type: inline-size;
    background: var(--widget-background);
  }

  .widget--news__firstitem
  {
    display: contents;
  }
}


/*

*/
@container newsWidgetLarge (width > 0)
{
  .widget--news
  {
    padding: var(--widget-padding-v) var(--widget-padding-h);
  }

  .widget--news__items
  {
    display: flex;
    column-gap: 30px;
  }

  html.site--ffw
  {
    .widget--news__items
    {
      display: flex;
      gap: calc(2 * var(--widget-padding-h) + var(--widgetsgrid-gutter-x));
    }
  }

  .widget--news__items > *
  {
    flex: 1 1 0px;
  }

    html.site--wvhg .widget--news__items > .widget--news__firstitem
  , html.site--geldlessen .widget--news__items > .widget--news__firstitem
  {
    flex: 2 1 0px;
  }


  .widget--news__firstitem
  {
    .minfinListItem
    {
      /* when hovering we need whitespace around the content
         for our background color. But because the background is
         transparent when not hovered, we need the content to stay aligned to other content. */
      margin: -10px;
      padding: 10px;
      border-radius: 8px;

      border: 2px solid transparent;
    }

    .minfinListItem:hover
    {
      background: rgba(0,0,0,0.07);
      border-color: #D0D0D0;
    }

    .widget--news__item__image
    {
      order: 1;
      margin-bottom: 12px;
    }

    .minfinItems__item__when
    {
      order: 2;
      display: flex;
      align-items: center;
    }

    .minfinItems__item__title
    {
      order: 3;
      margin-top: 3px;
      font: var(--widget-itemslist-item-first-title-font);
    }


    .widget--news__item--isexternal .widget--news__item__when::after
    {
      margin-left: 8px;

      @include fontawesome-solid;
      content: $fa-external-link-alt;
/*      content: var(--fa-external-link-alt);*/
      font-size: 13px;
    }
  }


/*  FIXME: find better way*/
  .minfinListItem
  {
    margin-left: -20px;
    margin-right: -20px;
  }

  .widget--news__moreitems
  {
    container-name: minfinLargeListing;
    container-type: inline-size;




    /*
    // vertically stack links but have the hitarea be only the text
    // (unless it wraps, in which case the browser always uses 100% width)

    // We use flex to:
    // - push the "more news" to the bottom
    */
    display: flex;
    flex-direction: column;
    align-items: start; /* shrinkwrap, so the whitespace isn't part of the hitarea of each link */

    .widget--news__item
    {
      display: flex;
      align-items: baseline;
    }

    .minfinListItem
    {
      border-radius: 8px;
    }

    .widget--news__item + .widget--news__item
    {
      margin-top: var(--widget-listing-spacing-v);
    }
/*
    .widget--news__item::before
    {
      @include content-arrow;
    }
*/
    .widget--news__item--isexternal.widget--news__item--isexternal::before
    {
      content: $fa-external-link-alt;
      font-size: 13px;
    }
  }
}



@container newsWidgetAsList (width > 0)
{
  .widget--news__items
  {
/*     --widget-itemslist-title-font: 17px var(--rtd-heading-fontfamily);*/

    display: flex;
    flex-direction: column;
  }

  .widget--news__items
  {
    container-name: minfinLargeListing minfinLargeListingLine;
    container-type: inline-size;
  }

  .widget--news__firstitem .minfinItems__item__title
  {
    font: var(--widget-itemslist-item-first-title-font);
  }
/*
  .widget--news__item--isexternal.widget--news__item--isexternal::before
  {
    content: $fa-external-link-alt;
    font-size: 13px;
  }
*/
  .widget--news__item__image
  {
    order: -10; /* always on top */
    margin-bottom: 12px;
  }

  .minfinListItem:hover .widget--news__item__image
  {
    outline: 1px solid rgba(255, 255, 255, 0.25);
  }

/*
  // .widget--news__showmorelink
  .widget__showmorelink
  {
    width: max-content;
    margin-left: auto;
  }
*/
}
