/*******************************************************************************
 * CONFIGURATION
 */
/* Breakpoints for specific site/page TRAITS */
/* below this width there will never be a widgetcolumn to the right  */
/* < desktop width we get the padding as defined in the tablet design */
/* twocolumn as seen in iPad portrait mode */
/* iPad landscape mode with 12px padding outside page content, 30px padding inside centered content */
/* desktop */
/* smaller fonts, hiding less relevant stuff */
/*
@mixin shadowpanel-hover
{
  border-color: #858585;
  background: linear-gradient(to bottom, #F1F1F1, #FFFFFF);
}
*/
/*@import "@mod-minfin_wig/webdesigns/shared/web/assets/fontawesome5/mixins.scss";*/
/*
We need to use gap because we want content to be 2 column if there's enough space,
but automatically switch to a single column if it's not wide enough.
Since we want the widget to work in multiple sites (which may have different breakpoints
and amount of column in the widgetsgroup may differ, it's easyer to use a gap which
won't be used the first item and other items don't fit next to eachother)
*/
/* $widget-news-minwidth-sidebyside: 768px; / * iPad portrait mode */
.widget-events__content {
  border: 1px solid var(--widget-highlight-border-color);
  background: #F2F2F2;
  background: var(--widget-highlight-background);
  border-radius: var(--widget-border-radius);
}

/* Side by side (2-column) layout */
/* @media (min-width: $widget-news-minwidth-sidebyside)*/
@media (min-width: 768px) {
  /* Grote weergave agenda widget als gebruikt in contentvlak*/
  .richdoc--toplevel > .widget-events,
  .wh-rtd__body .widget-events {
    container-name: eventsWidgetLarge;
    container-type: inline-size;
    --widget-title-font: var(--widget-large-title-font);
    --widget-itemslist-item-title-font: bold 16px var(--rtd-heading-fontfamily);
    /*     --pagegrid-col-fullwidth*/
    max-width: none;
  }
  .site--wig .richdoc--toplevel > .widget-events .widget-events__content,
  .site--wig .wh-rtd__body .widget-events .widget-events__content {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
  }
  .site--wvhg .richdoc--toplevel > .widget-events .widget-events__content, .site--geldlessen .richdoc--toplevel > .widget-events .widget-events__content,
  .site--wvhg .wh-rtd__body .widget-events .widget-events__content,
  .site--geldlessen .wh-rtd__body .widget-events .widget-events__content {
    width: 100%;
    max-width: min(100% - var(--sidepadding) * 2, var(--pagegrid-contentarea-width));
    margin-left: auto;
    margin-right: auto;
  }
  .richdoc--toplevel > .widget-events .widget-events__content,
  .wh-rtd__body .widget-events .widget-events__content {
    padding: 0 var(--widget-padding-h) var(--widget-padding-bottom) var(--widget-padding-h);
    /*      @include layout-default-insidepadding;*/
  }
  .richdoc--toplevel > .widget-events .widget__header,
  .wh-rtd__body .widget-events .widget__header {
    padding-left: 0;
    padding-right: 0;
  }
}
/* Stacked */
/* @media (max-width: $widget-news-minwidth-sidebyside - 1px) */
@media (max-width: 767px) {
  .widget-events {
    width: 100%;
    max-width: var(--pagegrid-contentarea-width-full);
    margin-left: auto;
    margin-right: auto;
    padding-left: var(--sidepadding);
    padding-right: var(--sidepadding);
    container-name: eventsWidgetAsList;
    container-type: inline-size;
  }
}
/* Always a list view when used in the widgets column */
:where(.widgetsgrid,
.content__widgetscolumn,
.widgets_column,
html.wh-widgetpreview) .widget-events {
  container-name: eventsWidgetAsList;
  container-type: inline-size;
}

@container eventsWidgetLarge (width > 0) {
  .widget__header {
    border-bottom: 1px dashed var(--widget-highlight-border-color);
    margin-bottom: 0px;
  }
  .widget-events__content {
    padding-bottom: var(--widget-padding-v);
  }
  .minfinListItems {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-template-rows: min-content min-content;
  }
  .minfinListItems > * {
    flex: 1 1 0px;
  }
  .minfinListItem-wrapper {
    grid-row: 1/-1;
    display: grid;
    grid-template-rows: subgrid;
    padding: 20px 20px 0 20px;
    border-right: 1px dashed var(--widget-highlight-border-color);
  }
  .minfinListItem-wrapper:first-child {
    padding-left: 0;
  }
  .minfinListItem-wrapper:last-child {
    padding-right: 0;
    border-right: none;
  }
  .minfinListItem.minfinListItem {
    /*
    // Use flex so we can change visual ordering
    // while still having the __when and __location below the header in the DOM
    // for screenreader users. (visual users get drawn to the large/bold title first and scan upwards, but screenreaders users don't scan upwards)
    display: flex;
    flex-direction: column;
    */
    grid-row: 1/-1;
    display: grid;
    grid-template-rows: subgrid;
    text-decoration: none;
    padding: 10px;
    margin: -10px;
    border-radius: 8px;
  }
  .minfinListItem:hover {
    background: rgba(0, 0, 0, 0.04);
  }
}
@container eventsWidgetAsList (width > 0) {
  .widget-events__content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: calc(var(--widget-padding-bottom) - 13px); /* FIXME: replace hardcoded listitem padding-bottom of 13px */
  }
  .minfinListItems-wrapper {
    container-name: minfinLargeListing minfinLargeListingLine;
    container-type: inline-size;
  }
  .minfinListItem-wrapper {
    border-bottom: 1px dashed var(--widget-highlight-border-color);
  }
  .minfinListItem-wrapper:last-child {
    border-bottom: none;
  }
  .minfinListItem {
    display: flex;
    flex-direction: column; /* override default, we don't have an icon next to us */
  }
  /* FIXME: have a better way... maybe a CSS var to override */
  .minfinListItem.minfinListItem::after {
    left: 0;
    right: 0;
  }
}