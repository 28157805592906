.embeddedobject--privacysettings {
  margin-top: 30px;
}

.privacysettings__option {
  background: var(--widget-highlight-background);
  border: 2px solid var(--widget-highlight-border-color);
  border-radius: 7px;
  padding: 22px 20px 20px 20px;
}

.privacysettings__option + .privacysettings__option {
  margin-top: 16px;
}

.privacysettings__option__labelwrapper {
  display: flex;
}

.privacysettings__option__label {
  margin-left: 14px;
  font: bold 24px/24px "TypoPRO Dosis";
  color: var(--widget-highlight-title-color);
  display: table; /* shrinkwrap */
}

.privacysettings__option__labelwrapper + .privacysettings__option__description {
  margin-top: 15px;
}

.embeddedobject--privacysettings .privacysettings--savebutton {
  display: table;
  margin-left: auto;
  margin-top: 25px;
}

.embeddedobject--privacysettings:not(.submitted) .privacysettings__page--submitted {
  display: none;
}

.embeddedobject--privacysettings.submitted .privacysettings__page--settings {
  display: none;
}